
.section-heading4 {
  position: relative;
  letter-spacing: -0.4px;
  line-height: 22px;
  font-weight: 500;
}
.section-heading5 {
  margin: 0;
  position: relative;
  font-size: var(--font-size-26xl);
  letter-spacing: -0.2px;
  line-height: 56px;
  font-weight: 700;
  font-family: var(--font-libre-baskerville);
  color: var(--color-darkslateblue-500);
}
.legal-headers-with-list-items6 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
  max-width: 100%;
}
.legal-headers-with-list-items5 {
  flex-direction: row;
  padding: 0 var(--padding-3xs);
}
.scroll-container {
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px; /* Adjust padding as needed */
  background-color: navy;
  border-radius: 5px;
}
.housewif{
  font-size: 16px!important;
    font-weight: 500;
}
/* Added  by*/
.divelementskit-single-testimo {
  flex-shrink: 0; /* Prevent testimonials from shrinking */
  width: 300px; /* Adjust width as needed */
  padding: 20px; /* Adjust padding as needed */
  margin-right: 10px; /* Adjust margin as needed */
  background-color: #f7f7f7;
  border-radius: 5px;
  flex-wrap: nowrap;
}
.divekit-main-swiper::-webkit-scrollbar {
  height: 10px; /* Adjust scrollbar height */
}

/* Style for the scrollbar thumb */
.divekit-main-swiper::-webkit-scrollbar-thumb {
  background-color: navy; 
  border-radius: 5px; 
}
.divekit-main-swiper {
  display: flex;
  overflow-x: auto;
  /* Ensure the scrollbar appears only when necessary */
  scrollbar-width: thin;
  scrollbar-color: navy #f0f0f0; /* Define the color of the scrollbar */
}


.divekit-main-swiper,
.legal-headers-with-list-items4,
.legal-headers-with-list-items5 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
  flex-shrink: 0;
}
.divekit-main-swiper {
  overflow: hidden;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 0 var(--padding-61xl);
  gap: var(--gap-base);
  text-align: center;
  color: var(--color-slategray-200);
  padding-bottom: 105px;
}
.legal-headers-with-list-items4 {
  flex-direction: column;
  padding: 0 var(--padding-xl) var(--padding-67xl);
  gap: var(--gap-mini);
  text-align: left;
  font-size: var(--font-size-lg);
  color: var(--color-sandybrown);
  font-family: var(--font-rubik);
}
@media screen and (max-width: 800px) {
  .section-heading5 {
    font-size: var(--font-size-17xl);
    line-height: 45px;
  }
  .divekit-main-swiper {
    padding-bottom: var(--padding-33xl);
    box-sizing: border-box;
  }
  .legal-headers-with-list-items4 {
    padding-bottom: var(--padding-37xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .section-heading5 {
    font-size: var(--font-size-8xl);
    line-height: 34px;
  }
}
