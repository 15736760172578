@import url("https://fonts.googleapis.com/css2?family=Font+Awesome+5+Free:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,400;0,500;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Font+Awesome+5+Brands:wght@400&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-rubik: Rubik;
  --font-roboto: Roboto;
  --font-font-awesome-5-free: "Font Awesome 5 Free";
  --font-libre-baskerville: "Libre Baskerville";
  --font-font-awesome-5-brands: "Font Awesome 5 Brands";
  --font-merriweather: Merriweather;
  --font-inter: Inter;

  /* font sizes */
  --font-size-base: 16px;
  --font-size-7xs: 6px;
  --font-size-mini: 15px;
  --font-size-sm: 14px;
  --font-size-2xl-8: 21.8px;
  --font-size-mid: 17px;
  --font-size-lg: 18px;
  --font-size-3xl: 22px;
  --font-size-6xs: 7px;
  --font-size-10xl-9: 29.9px;
  --font-size-5xl: 24px;
  --font-size-xs: 12px;
  --font-size-26xl: 45px;
  --font-size-8xl: 27px;
  --font-size-17xl: 36px;
  --font-size-smi: 13px;
  --font-size-4xl: 23px;
  --font-size-xl: 20px;
  --font-size-lgi: 19px;
  --font-size-7xl: 26px;
  --font-size-2xl: 21px;
  --font-size-31xl: 50px;
  --font-size-11xl: 30px;
  --font-size-21xl: 40px;
  --font-size-13xl: 32px;
  --font-size-45xl: 64px;
  --font-size-19xl: 38px;
  --font-size-32xl: 51px;
  --font-size-10xl: 29px;
  --font-size-23xl: 42px;
  --font-size-6xl: 25px;
  --font-size-15xl: 34px;
  --font-size-3xs: 10px;

  /* Colors */
  --color-white: #fff;
  --color-darkslateblue-100: #264f8a;
  --color-darkslateblue-200: #394777;
  --color-darkslateblue-300: #0b4494;
  --color-darkslateblue-400: #04367d;
  --color-darkslateblue-500: #0b2051;
  --color-darkslateblue-600: rgba(4, 54, 125, 0.9);
  --color-sandybrown: #e86500;
  --color-gray-100: #fcfbfb;
  --color-gray-200: #808080;
  --color-gray-300: #252525;
  --color-gray-400: rgba(255, 255, 255, 0.18);
  --color-gray-500: rgba(255, 255, 255, 0.3);
  --color-gray-600: rgba(255, 255, 255, 0);
  --color-gray-700: rgba(255, 255, 255, 0.8);
  --color-whitesmoke-100: #f9f9f9;
  --color-whitesmoke-200: #eee;
  --color-whitesmoke-300: #ededed;
  --color-whitesmoke-400: #eaeaea;
  --color-whitesmoke-500: rgba(245, 245, 245, 0.7);
  --color-lightsteelblue-100: #c7cede;
  --color-lightsteelblue-200: #bdc3d4;
  --color-lightsteelblue-300: #a4adbf;
  --color-darkkhaki: #b79d5b;
  --color-lightgray-100: #d6d7d9;
  --color-lightgray-200: #ced4da;
  --color-lightgray-300: #ccd0d9;
  --color-lightgray-400: #ccc;
  --color-slategray-100: #6c757d;
  --color-slategray-200: #646e84;
  --color-slategray-300: rgba(100, 110, 132, 0.3);
  --color-slategray-400: rgba(100, 110, 132, 0.35);
  --color-skyblue: #6ec1e4;
  --color-lightslategray: #9ba3ba;
  --color-darkgray-100: #adb4c2;
  --color-darkgray-200: #a9a9a9;
  --color-darkgray-300: #999;
  --color-darkslategray: #01476a;
  --color-ghostwhite: #f4f6fb;
  --color-lightblue: #bbd5e1;
  --color-dimgray-100: #666;
  --color-dimgray-200: #5b5b5b;
  --color-dimgray-300: #5a5a5a;
  --color-gainsboro-100: #e5e3df;
  --color-gainsboro-200: #d5d8e1;
  --color-steelblue: #0e4f72;
  --color-black: #000;
  --color-royalblue: #1a73e8;

  /* Gaps */
  --gap-xl: 20px;
  --gap-88xl: 107px;
  --gap-16xl: 35px;
  --gap-sm: 14px;
  --gap-83xl: 102px;
  --gap-2xl: 21px;
  --gap-lg: 18px;
  --gap-base: 16px;
  --gap-11xl: 30px;
  --gap-lgi: 19px;
  --gap-9xl: 28px;
  --gap-3xl: 22px;
  --gap-xs: 12px;
  --gap-29xl: 48px;
  --gap-mini: 15px;
  --gap-45xl: 64px;
  --gap-3xs: 10px;
  --gap-20xl: 39px;
  --gap-5xl: 24px;
  --gap-6xl: 25px;
  --gap-4xs: 9px;
  --gap-21xl: 40px;
  --gap-81xl: 100px;
  --gap-8xs: 5px;
  --gap-2xs: 11px;
  --gap-12xs: 1px;
  --gap-25xl: 44px;
  --gap-mid: 17px;
  --gap-7xl: 26px;
  --gap-12xl: 31px;
  --gap-85xl: 104px;
  --gap-smi: 13px;
  --gap-4xl: 23px;
  --gap-109xl: 128px;
  --gap-31xl: 50px;
  --gap-38xl: 57px;
  --gap-41xl: 60px;
  --gap-22xl: 41px;
  --gap-79xl: 98px;
  --gap-26xl: 45px;
  --gap-34xl: 53px;
  --gap-10xl: 29px;
  --gap-9xs: 4px;
  --gap-64xl: 83px;
  --gap-5xs: 8px;
  --gap-57xl: 76px;
  --gap-101xl: 120px;
  --gap-27xl: 46px;
  --gap-18xl: 37px;
  --gap-87xl: 106px;
  --gap-15xl: 34px;
  --gap-52xl: 71px;
  --gap-11xs: 2px;
  --gap-28xl: 47px;
  --gap-13xl: 32px;
  --gap-7xs: 6px;
  --gap-14xl: 33px;
  --gap-8xl: 27px;
  --gap-17xl: 36px;

  /* Paddings */
  --padding-xl: 20px;
  --padding-145xl: 164px;
  --padding-141xl: 160px;
  --padding-61xl: 80px;
  --padding-63xl: 82px;
  --padding-56xl: 75px;
  --padding-146xl: 165px;
  --padding-30xl: 49px;
  --padding-12xs: 1px;
  --padding-mini: 15px;
  --padding-lgi: 19px;
  --padding-mid: 17px;
  --padding-base: 16px;
  --padding-9xs: 4px;
  --padding-8xs: 5px;
  --padding-81xl: 100px;
  --padding-46xl: 65px;
  --padding-7xl: 26px;
  --padding-11xs: 2px;
  --padding-71xl: 90px;
  --padding-39xl: 58px;
  --padding-131xl: 150px;
  --padding-33xl: 52px;
  --padding-lg: 18px;
  --padding-2xl: 21px;
  --padding-3xs: 10px;
  --padding-10xs: 3px;
  --padding-54xl: 73px;
  --padding-52xl: 71px;
  --padding-19xl: 38px;
  --padding-31xl: 50px;
  --padding-13xl: 32px;
  --padding-4xs: 9px;
  --padding-67xl: 86px;
  --padding-37xl: 56px;
  --padding-32xl: 51px;
  --padding-76xl: 95px;
  --padding-21xl: 40px;
  --padding-43xl: 62px;
  --padding-16xl: 35px;
  --padding-23xl: 42px;
  --padding-17xl: 36px;
  --padding-22xl: 41px;
  --padding-138xl: 157px;
  --padding-59xl: 78px;
  --padding-6xs: 7px;
  --padding-smi: 13px;
  --padding-7xs: 6px;
  --padding-11xl: 30px;
  --padding-115xl: 134px;
  --padding-116xl: 135px;
  --padding-14xl: 33px;
  --padding-48xl: 67px;
  --padding-6xl: 25px;
  --padding-10xl: 29px;
  --padding-55xl: 74px;
  --padding-18xl: 37px;
  --padding-60xl: 79px;
  --padding-20xl: 39px;
  --padding-94xl: 113px;
  --padding-276xl: 295px;
  --padding-173xl: 192px;
  --padding-75xl: 94px;
  --padding-sm: 14px;
  --padding-161xl: 180px;
  --padding-83xl: 102px;
  --padding-47xl: 66px;
  --padding-91xl: 110px;
  --padding-4xl: 23px;
  --padding-9xl: 28px;
  --padding-5xs: 8px;
  --padding-3xl: 22px;
  --padding-xs: 12px;
  --padding-184xl: 203px;
  --padding-185xl: 204px;
  --padding-113xl: 132px;
  --padding-114xl: 133px;
  --padding-148xl: 167px;
  --padding-64xl: 83px;
  --padding-163xl: 182px;
  --padding-72xl: 91px;
  --padding-5xl: 24px;
  --padding-2xs: 11px;
  --padding-15xl: 34px;
  --padding-24xl: 43px;
  --padding-28xl: 47px;
  --padding-12xl: 31px;
  --padding-8xl: 27px;
  --padding-84xl: 103px;
  --padding-25xl: 44px;
  --padding-38xl: 57px;

  /* Border radiuses */
  --br-mid-5: 17.5px;
  --br-11xs: 2px;
  --br-8xs: 5px;
  --br-16xl: 35px;
  --br-10xs: 3px;
  --br-6xs: 7px;
  --br-31xl: 50px;
  --br-8xs-5: 4.5px;
}
