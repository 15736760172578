.divelementskit-infobox2 {
  position: relative;
  display: inline-block;
}


.image-10-icon {
  width: 100%;
  height: auto;
  transition: opacity 0.3s ease;
}

.text-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 1s ease;
  background-color: rgba(255, 255, 255, 0.8); 
  padding: 20px;
  box-sizing: border-box;
}
.image-11-icon,.image-10-icon,.image-8-icon {
  opacity: 0;
  transition: opacity 0.5s ease;
}

.divelementskit-infobox2:hover .image-10-icon {
  opacity: 1; 
}

.divelementskit-infobox2:hover .text-overlay {
  opacity: 0;
}
.divelementskit-infobox3:hover .image-11-icon {
  opacity: 1; 
}
.divelementskit-infobox3:hover .text-overlay {
  opacity: 0;
}
.divelementskit-infobox1:hover .image-8-icon {
  opacity: 1; 
}
.divelementskit-infobox1:hover .text-overlay {
  opacity: 0;
}


.heading-37 {
  margin: 0;
  align-self: stretch;
  height: 64px;
  position: relative;
  font-size: inherit;
  line-height: 30px;
  font-weight: 700;
  font-family: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.fighting-traffic-tickets,
.wrote-multiple-books {
  margin: 0;
}
.wrote-multiple-books-container {
  position: relative;
  font-size: var(--font-size-base);
  line-height: 24px;
  font-family: var(--font-rubik);
  color: var(--color-darkgray-300);
}
.divelementskit-infobox {
  flex: 1;
  border-radius: var(--br-8xs);
  background-color: var(--color-white);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xl) var(--padding-mini) var(--padding-94xl)
    var(--padding-base);
  gap: var(--gap-3xs);
}
.divelementor-widget-wrap5 {
  flex: 1;
  background-color: var(--color-gray-600);
  box-shadow: 0 15px 30px rgba(4, 54, 125, 0.05);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-12xs) 0 0;
  box-sizing: border-box;
  min-width: 230px;
  max-width: 250px;
}
.image-8-icon {
  position: absolute;
  top: 43.5px;
  left: 0;
  width: 250px;
  height: 167px;
  object-fit: cover;
  z-index: 1;
}
.divelementskit-infobox1 {
  height: 253px;
  flex: 1;
  position: relative;
  border-radius: var(--br-8xs);
  background-color: var(--color-white);
  overflow: hidden;
}
.divelementor-widget-container {
  flex: 1;
  background-color: var(--color-gray-600);
  box-shadow: 0 15px 30px rgba(4, 54, 125, 0.05);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 230px;
  max-width: 250px;
}
.image-10-icon {
  position: absolute;
  top: 43.5px;
  left: 0;
  width: 250px;
  height: 167px;
  object-fit: cover;
  z-index: 1;
}
.divelementskit-infobox2 {
  height: 253px;
  flex: 1;
  position: relative;
  border-radius: var(--br-8xs);
  background-color: var(--color-white);
  overflow: hidden;
}
.divelementor-widget-container1 {
  flex: 1;
  background-color: var(--color-gray-600);
  box-shadow: 0 15px 30px rgba(4, 54, 125, 0.05);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 230px;
  max-width: 250px;
}
.image-11-icon {
  position: absolute;
  top: 43.5px;
  left: 0;
  width: 250px;
  height: 167px;
  object-fit: cover;
  z-index: 1;
}
.divelementskit-infobox3 {
  height: 253px;
  flex: 1;
  position: relative;
  border-radius: var(--br-8xs);
  background-color: var(--color-white);
  overflow: hidden;
}
.divelementor-widget-container2,
.sub-section-frame {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.divelementor-widget-container2 {
  flex: 1;
  background-color: var(--color-gray-600);
  box-shadow: 0 15px 30px rgba(4, 54, 125, 0.05);
  min-width: 230px;
  max-width: 250px;
}
.sub-section-frame {
  width: 1120px;
  flex-wrap: wrap;
  gap: var(--gap-21xl);
  max-width: 100%;
}
.top-rated-lawyers {
  position: relative;
  line-height: 22px;
  text-transform: capitalize;
  font-weight: 500;
  color: #e86500;
}
.attorney,
.new-york-city,
.personal-injury {
  margin: 0;
}
.new-york-city{
  line-height: normal;
  font-size: 33px;
}
.new-york-city-container {
  margin: 0;
  height: 176px;
  position: relative;
  font-size: var(--font-size-26xl);
  letter-spacing: -0.2px;
  line-height: 60px;
  font-weight: 700;
  font-family: var(--font-libre-baskerville);
  color: var(--color-darkslateblue-500);
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.tax-consultancy-frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /* gap: var(--gap-xl); */
  min-width: 365px;
  max-width: 100%;
}
.privsa-law-offices1 {
  margin: 0;
  height: 29px;
  position: relative;
  font-size: var(--font-size-5xl);
  letter-spacing: -0.4px;
  line-height: 35px;
  font-weight: 700;
  font-family: var(--font-libre-baskerville);
  color: var(--color-darkslateblue-500);
  display: flex;
  align-items: center;
  flex-shrink: 0;
  max-width: 100%;
}
.combined-legal-experience,
.hile-its-true1 {
  margin: 0;
}

p.hile-its-true1 {
  margin-top: 100px;
  color: var(--color-darkslateblue-500);
}
.hile-its-true-container1 {
  align-self: stretch;
  position: relative;
  line-height: 26px;
  display: flex;
  align-items: center;
}
.company-link {
  align-self: stretch;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
}
.we-have-won {
  height: 22px;
  position: relative;
  line-height: 26px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  max-width: 100%;
}
.practice-areas-list {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
  max-width: 100%;
}
.for-most-of1,
.happen-to-them,
.they-want-someone {
  margin: 0;
}
.for-most-of-container1 {
  margin-top: 52px;
  height: 74px;
  position: relative;
  letter-spacing: -0.36px;
  line-height: 26px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  max-width: 100%;
}
.intellectual-property-frame {
  width: 648px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-lgi);
  min-width: 648px;
  max-width: 100%;
  color: var(--color-slategray-200);
}
.banking-finance-frame {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--gap-xl);
  max-width: 100%;
}
.symbol113 {
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 24px;
  font-weight: 400;
  font-family: inherit;
}
.list-item97 {
  position: relative;
  font-size: var(--font-size-13xl);
  letter-spacing: -0.2px;
  line-height: 38px;
  font-weight: 500;
  font-family: var(--font-rubik);
  color: var(--color-white);
}
.section-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xl);
  max-width: 100%;
}
.list-item98,
.symbol114 {
  margin: 0;
  position: relative;
}
.symbol114 {
  font-size: inherit;
  line-height: 24px;
  font-weight: 400;
  font-family: inherit;
}
.list-item98 {
  font-size: var(--font-size-13xl);
  letter-spacing: -0.2px;
  line-height: 38px;
  font-weight: 500;
  font-family: var(--font-rubik);
  color: var(--color-white);
  white-space: nowrap;
}
.section-title1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-10xl);
}
.section16 {
  background-color: var(--color-darkslateblue-400);
  border-bottom: 4px solid var(--color-sandybrown);
  box-sizing: border-box;
  flex-direction: row;
  align-items: flex-start;
  padding: var(--padding-10xl) var(--padding-55xl) var(--padding-18xl)
    var(--padding-60xl);
  gap: var(--gap-34xl);
  text-align: center;
  font-size: var(--font-size-5xl);
  font-family: var(--font-font-awesome-5-free);
  transition: all 0.5s ease-out;
  background:linear-gradient(to top, rgb(255, 255, 255) 50%,var(--color-darkslateblue-400) 50%);
  background-size: 100% 200%;
  background-position: top;
}

.section16:hover{
  background-position: bottom;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}
.section16:hover .list-item97,
.section16:hover .list-item98 {
  color: var(--color-darkslateblue-500);;
  border-top:10px;
}

.bankruptcy-frame,
.bankruptcy1,
.section15,
.section16 {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.bankruptcy-frame {
  width: 1100px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  text-align: left;
  font-size: var(--font-size-lg);
  color: var(--color-sandybrown);
  font-family: var(--font-rubik);
}
.bankruptcy1,
.section15 {
  box-sizing: border-box;
}
.section15 {
  flex: 1;
  background-color: #c2c2c2;
  flex-direction: column;
  align-items: center;
  padding: var(--padding-81xl) var(--padding-xl);
  gap: var(--gap-79xl);
}
.bankruptcy1 {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 0 var(--padding-81xl);
  flex-shrink: 0;
  text-align: center;
  font-size: var(--font-size-4xl);
  color: var(--color-darkslateblue-500);
  font-family: var(--font-libre-baskerville);
}
@media screen and (max-width: 1325px) {
  .section15 {
    gap: var(--gap-79xl);
  }
}
@media screen and (max-width: 1125px) {
  .intellectual-property-frame,
  .tax-consultancy-frame {
    flex: 1;
  }
  .banking-finance-frame {
    flex-wrap: wrap;
    justify-content: center;
  }
  .section16 {
    flex-wrap: wrap;
    padding-left: var(--padding-20xl);
    padding-right: var(--padding-18xl);
    box-sizing: border-box;
  }
  .section15 {
    padding-top: var(--padding-46xl);
  }
  .bankruptcy1,
  .section15 {
    padding-bottom: var(--padding-46xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 800px) {
  .sub-section-frame {
    gap: var(--gap-21xl);
  }
  .new-york-city-container {
    font-size: var(--font-size-17xl);
    line-height: 48px;
  }
  .intellectual-property-frame {
    min-width: 100%;
  }
  .list-item97,
  .list-item98 {
    font-size: var(--font-size-7xl);
    line-height: 30px;
  }
  .bankruptcy-frame {
    gap: var(--gap-26xl);
  }
  .section15 {
    gap: var(--gap-79xl);
  }
  .bankruptcy1 {
    padding-bottom: var(--padding-23xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .heading-37 {
    font-size: var(--font-size-lg);
    line-height: 28px;
  }
  .new-york-city-container {
    font-size: var(--font-size-8xl);
    line-height: 36px;
  }
  .tax-consultancy-frame {
    min-width: 100%;
  }
  .privsa-law-offices1,
  .symbol113 {
    font-size: var(--font-size-lgi);
    line-height: 28px;
    margin-bottom: 60px;
  }
  .symbol113 {
    line-height: 19px;
  }
  .list-item97,
  .list-item98,
  .symbol114 {
    font-size: var(--font-size-lgi);
    line-height: 23px;
  }
  .list-item98,
  .symbol114 {
    line-height: 19px;
  }
  .list-item98 {
    line-height: 23px;
  }
  .section16 {
    gap: var(--gap-34xl);
  }
  .section15 {
    padding-top: var(--padding-23xl);
    padding-bottom: var(--padding-23xl);
    box-sizing: border-box;
  }
}
