::-webkit-scrollbar {
  height: 10px; 
  width: 10px;
}

::-webkit-scrollbar-track {
  background: rgb(235, 235, 235);
}

::-webkit-scrollbar-thumb {
  background: #e86500;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: #fb6d01;
}

/* @keyframes pageTurn {
  0% {
    transform: perspective(1000px) rotateY(0);
    opacity:0.5;
  }
  100% {
    transform: perspective(1000px) rotateY(360deg);
    opacity: 1; 
  }
}

.page-turn {
  animation: pageTurn 3s forwards;
  transform-style: preserve-3d;
} */