.heading-320 {
  position: relative;
  letter-spacing: -0.4px;
  line-height: 22px;
  font-weight: 500;
}
.heading-28,
.symbol128,
.view-all {
  position: relative;
  letter-spacing: -0.2px;
}
.heading-28 {
  margin: 0;
  font-size: inherit;
  line-height: 56px;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  max-width: 100%;
}
.symbol128,
.view-all {
  text-transform: uppercase;
}
.view-all {
  line-height: 19px;
  font-weight: 500;
}
.symbol128 {
  font-size: var(--font-size-xs);
  line-height: 12px;
  font-family: var(--font-font-awesome-5-free);
}
.heading4,
.symbol127 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.symbol127 {
  gap: var(--gap-3xs);
}
.heading4 {
  padding: 0 var(--padding-xl) 0 var(--padding-7xl);
}
.after1 {
  align-self: stretch;
  height: 2.4px;
  position: relative;
  background-color: var(--color-sandybrown);
}
.link7,
.list8 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.link7 {
  align-self: stretch;
  background-color: var(--color-darkslateblue-400);
  overflow: hidden;
  align-items: center;
  padding: var(--padding-xl) 0 var(--padding-12xs);
  gap: var(--gap-mid);
}
.list8 {
  width: 165px;
  align-items: flex-start;
  padding: 0 0 var(--padding-lgi);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--font-size-base);
  color: var(--color-white);
  font-family: var(--font-rubik);
}
.list7 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  gap: var(--gap-xl);
  max-width: 100%;
  margin-top: -2px;
  font-size: var(--font-size-26xl);
  color: var(--color-darkslateblue-500);
  font-family: var(--font-libre-baskerville);
}
.list6 {
  width: 1120px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.list6,
.section24,
.section25 {
  justify-content: flex-start;
  max-width: 100%;
}
.section25 {
  width: 1122px;
  display: grid;
  flex-direction: row;
  align-items: flex-start;
  gap: var(--gap-12xs);
  grid-template-columns: repeat(3, minmax(280px, 1fr));
  font-size: var(--font-size-4xl);
  color: var(--color-darkslateblue-400);
  font-family: var(--font-libre-baskerville);
}
.section24 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: var(--padding-71xl) var(--padding-xl); */
  box-sizing: border-box;
  gap: var(--gap-21xl);
  background-image: url(/public/section8@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  flex-shrink: 0;
  text-align: left;
  font-size: var(--font-size-lg);
  color: var(--color-sandybrown);
  font-family: var(--font-rubik);
}
@media screen and (max-width: 1125px) {
  .section25 {
    justify-content: center;
    grid-template-columns: repeat(2, minmax(280px, 485px));
  }
  .section24 {
    padding-top: var(--padding-39xl);
    padding-bottom: var(--padding-39xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 800px) {
  .heading-28 {
    font-size: var(--font-size-17xl);
    line-height: 45px;
  }
  .list7 {
    flex-wrap: wrap;
  }
  .section25 {
    grid-template-columns: minmax(280px, 1fr);
  }
  .section24 {
    gap: var(--gap-21xl);
    padding-top: var(--padding-19xl);
    padding-bottom: var(--padding-19xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .heading-28 {
    font-size: var(--font-size-8xl);
    line-height: 34px;
  }
}
