.form-section-section-but{
  border-radius: 5px !important;
  background-color: #e86500 !important;
}
.blog-link{
  text-decoration: none;
  color:rgb(23, 20, 20);
}
.blog-link:hover{
  color: var(--color-darkslateblue-500);
}
.search {
  position: relative;
}
.main-complementary-form {
  flex: 1;
  background-color: var(--color-white);
  border: 1px solid var(--color-whitesmoke-200);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-lg) var(--padding-16xl);
  min-width: 46px;
  min-height: 55px;
}
.symbol206 {
  position: relative;
  line-height: 18px;
}
.main-complementary-form1 {
  width: 58px;
  background-color: var(--color-sandybrown);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-lgi) var(--padding-lg) var(--padding-lg);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--font-size-lg);
  color: var(--color-white);
  font-family: var(--font-font-awesome-5-free);
}
.input-search-main-frame {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  row-gap: 20px;
  font-size: var(--font-size-sm);
  color: var(--color-slategray-100);
  font-family: var(--font-rubik);
}
.heading-5 {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.4px;
  line-height: 28.8px;
  font-weight: 700;
  font-family: inherit;
}
.heading-51 {
  width: 50px;
  height: 2px;
  position: relative;
  background-color: var(--color-sandybrown);
}
.heading-5-recent-posts-parent,
.heading-recent-posts {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.heading-5-recent-posts-parent {
  flex-direction: column;
  gap: var(--gap-sm);
}
.heading-recent-posts {
  align-self: stretch;
  flex-direction: row;
}
.associated-with-tinnitus1,
.defective-3m-earplugs2 {
  margin: 0;
}
.link-defective-container {
  align-self: stretch;
  position: relative;
  line-height: 28px;
  display: flex;
  align-items: center;
}
.list-item161 {
  align-self: stretch;
  flex: 1;
  border-bottom: 1px dashed var(--color-whitesmoke-200);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-10xs) 0 var(--padding-sm);
}
.depositions-reveal-no1,
.warned-military-of {
  margin: 0;
}
.link-depositions-container {
  align-self: stretch;
  position: relative;
  line-height: 28px;
  display: flex;
  align-items: center;
}
.list-item162 {
  align-self: stretch;
  height: 67px;
  border-bottom: 1px dashed var(--color-whitesmoke-200);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-10xs) var(--padding-xs) var(--padding-sm) 0;
}
.aenean-sollicitudin1,
.proin-gravida-nibh1 {
  margin: 0;
}
.link-proin-container {
  align-self: stretch;
  position: relative;
  line-height: 28px;
  display: flex;
  align-items: center;

}
.list-item163 {
  align-self: stretch;
  height: 67px;
  border-bottom: 1px dashed var(--color-whitesmoke-200);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-10xs) 0 var(--padding-sm);
}
.list-item164 {
  position: relative;
  line-height: 28px;
}
.capital-markets-link,
.main-complementary1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.capital-markets-link {
  flex: 1;
  align-items: flex-start;
  gap: var(--gap-3xs);
  font-size: var(--font-size-lg);
  color: var(--color-dimgray-300);
  font-family: var(--font-rubik);
}
.main-complementary1 {
  height: 415px;
  /* border: 1px solid var(--color-whitesmoke-200); */
  box-sizing: border-box;
  align-items: center;
  gap: var(--gap-11xl);
}
.heading-52 {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.4px;
  line-height: 28.8px;
  font-weight: 700;
  font-family: inherit;
}
.heading-53 {
  width: 50px;
  height: 2px;
  position: relative;
  background-color: var(--color-sandybrown);
}
.heading-about-us,
.social-media-links {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.social-media-links {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-sm);
}
.heading-about-us {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-9xs) 0 0;
}
.on {
  color: var(--color-darkslategray);
}
.a-wordpress-commenter-on,
.hello-world {
  margin: 0;
}
.list-item-container8 {
  flex: 1;
  position: relative;
  font-size: var(--font-size-lg);
  line-height: 28px;
  font-family: var(--font-rubik);
  display: flex;
  align-items: center;
  color: var(--color-dimgray-300);
}
.input-search-main-frame-parent,
.main-complementary2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.main-complementary2 {
  align-self: stretch;
  height: 212px;
  border: 1px solid var(--color-whitesmoke-200);
  box-sizing: border-box;
  align-items: center;
  padding: var(--padding-21xl) var(--padding-55xl) var(--padding-24xl)
    var(--padding-22xl);
  gap: var(--gap-14xl);
}
.input-search-main-frame-parent {
  width: 350px;
  align-items: flex-end;
  gap: var(--gap-21xl);
  min-width: 350px;
  max-width: 100%;
  text-align: left;
  padding: 40px;
  font-size: var(--font-size-5xl);
  color: var(--color-darkslateblue-500);
  font-family: var(--font-libre-baskerville);
}
@media screen and (max-width: 1125px) {
  .input-search-main-frame-parent {
    flex: 1;
  }
}
@media (min-width: 370px) and (max-width: 450px){
  .center-the-component{
    width: 100%;
    margin-left: -50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .section3 {
    margin-left: 50px !important;
    margin-right: -50px !important;
  }
}
@media screen and (max-width: 450px) {
  .input-search-main-frame {
    flex-wrap: wrap;
  }
  .heading-5 {
    font-size: var(--font-size-lgi);
    line-height: 23px;
  }
  .main-complementary1 {
    gap: var(--gap-11xl);
    box-sizing: border-box;
    margin-left: 100px !important;
    padding-top: 50px !important;
  }
  .heading-52 {
    font-size: var(--font-size-lgi);
    line-height: 23px;
  }
  .main-complementary2 {
    gap: var(--gap-14xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
  .input-search-main-frame-parent {
    gap: var(--gap-21xl);
    min-width: 100%;
    padding: 0px !important;
  }
}
