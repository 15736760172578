.section-heading2 {
  position: relative;
  letter-spacing: -0.4px;
  line-height: 22px;
  font-weight: 500;
}
.section-heading3 {
  margin: 0;
  position: relative;
  font-size: var(--font-size-26xl);
  letter-spacing: -0.2px;
  line-height: 78px;
  font-weight: 700;
  font-family: var(--font-libre-baskerville);
  color: var(--color-darkslateblue-500);
}
.section-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-4xs);
  max-width: 100%;
}
.award-logo1png-icon1 {
  width: 90px;
  height: 90px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.symbol118 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-mini) 0 0;
}
.award-logo2png-icon1,
.award-logo3png-icon1 {
  position: relative;
  overflow: hidden;
  object-fit: cover;
}
.award-logo2png-icon1 {
  height: 90px;
  width: 175px;
  flex-shrink: 0;
  min-height: 90px;
}
.award-logo3png-icon1 {
  width: 118px;
  flex: 1;
  max-height: 100%;
}
.symbol119 {
  height: 91px;
  width: 161px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.award-logo4png-icon1 {
  height: 90px;
  width: 90px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  min-height: 90px;
}
.section-button-next-slide,
.section-button-previous-sl {
  height: 20px;
  width: 20px;
  position: absolute;
  margin: 0 !important;
  bottom: 24.5px;
  overflow: hidden;
  flex-shrink: 0;
}
.section-button-previous-sl {
  left: 0;
}
.section-button-next-slide {
  right: 1px;
}
.symbol-parent14 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 var(--padding-115xl) 0 var(--padding-116xl);
  position: relative;
  gap: var(--gap-xl);
}
.infoprivsacom-link3 {
  width: 1250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-xl) var(--padding-81xl);
  box-sizing: border-box;
  gap: var(--gap-21xl);
  max-width: 100%;
  flex-shrink: 0;
  text-align: center;
  font-size: var(--font-size-lg);
  color: var(--color-sandybrown);
  font-family: var(--font-rubik);
}
@media screen and (max-width: 1325px) {
  .symbol-parent14 {
    padding-left: var(--padding-48xl);
    padding-right: var(--padding-48xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 1125px) {
  .symbol-parent14 {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 800px) {
  .section-heading3 {
    font-size: var(--font-size-17xl);
    line-height: 62px;
  }
  .symbol-parent14 {
    padding-left: var(--padding-14xl);
    padding-right: var(--padding-14xl);
    box-sizing: border-box;
  }
  .infoprivsacom-link3 {
    gap: var(--gap-21xl);
  }
}
@media screen and (max-width: 450px) {
  .section-heading3 {
    font-size: var(--font-size-8xl);
    line-height: 47px;
  }
}
