.main-section-section-group,
.main-section-section-parent {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xl);
  max-width: 100%;
}
.main-section-section-parent {
  display: flex;
  flex-wrap: wrap;
  font-size: var(--font-size-31xl);
  color: var(--color-sandybrown);
  font-family: var(--font-inter);
}
.main-section-section-group {
  display: grid;
  padding: 0 var(--padding-12xs) 0 0;
  box-sizing: border-box;
  grid-template-columns: repeat(3, minmax(260px, 1fr));
}
.before10 {
  align-self: stretch;
  height: 6px;
  position: relative;
  background-color: var(--color-darkslateblue-600);
}
.heading-331 {
  position: relative;
  letter-spacing: -0.1px;
  line-height: 32px;
}
.premier-trial-lawyers2,
.state-in-the1 {
  margin: 0;
}
.premier-trial-lawyers-container2 {
  flex: 1;
  position: relative;
  font-size: var(--font-size-base);
  line-height: 26px;
  font-family: var(--font-rubik);
  display: flex;
  align-items: center;
}
.heading-3-link-banking-f-group {
  height: 90px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-smi);
}
.link-icon12 {
  width: 34px;
  height: 34px;
  position: absolute;
  margin: 0 !important;
  bottom: 0;
  left: 10px;
  overflow: hidden;
  flex-shrink: 0;
}
.frame-parent13,
.symbol-list-parent {
  display: flex;
  justify-content: flex-start;
}
.frame-parent13 {
  align-self: stretch;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.symbol-list-parent {
  flex: 1;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 var(--padding-17xl) 0 0;
}
.divservice-content6,
.main-section-section2 {
  align-self: stretch;
  flex: 1;
  display: flex;
  align-items: center;
  max-width: 100%;
}
.divservice-content6 {
  background-color: var(--color-white);
  box-shadow: 0 10px 20px rgba(4, 54, 125, 0.05);
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 0 var(--padding-17xl);
  box-sizing: border-box;
  gap: var(--gap-22xl);
}
.main-section-section2 {
  flex-direction: row;
  justify-content: center;
  background-image: url(/public/main--section--section2@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.before11 {
  align-self: stretch;
  height: 6px;
  position: relative;
  background-color: var(--color-darkslateblue-600);
}
.heading-332 {
  position: relative;
  letter-spacing: -0.1px;
  line-height: 32px;
}
.premier-trial-lawyers3,
.state-in-the2 {
  margin: 0;
}
.premier-trial-lawyers-container3 {
  flex: 1;
  position: relative;
  font-size: var(--font-size-base);
  line-height: 26px;
  font-family: var(--font-rubik);
  display: flex;
  align-items: center;
}
.heading-3-link-banking-f-container {
  height: 90px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-smi);
}
.link-icon13 {
  width: 34px;
  height: 34px;
  position: absolute;
  margin: 0 !important;
  bottom: 0;
  left: 10px;
  overflow: hidden;
  flex-shrink: 0;
}
.frame-parent14 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}
.divservice-content-inner3 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-17xl) 0 0;
}
.divservice-content7,
.main-section-section3 {
  align-self: stretch;
  flex: 1;
  display: flex;
  align-items: center;
  max-width: 100%;
}
.divservice-content7 {
  background-color: var(--color-white);
  box-shadow: 0 10px 20px rgba(4, 54, 125, 0.05);
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 0 var(--padding-17xl);
  box-sizing: border-box;
  gap: var(--gap-22xl);
}
.main-section-section3 {
  flex-direction: row;
  justify-content: center;
  background-image: url(/public/main--section--section2@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.main-section-section-container {
  height: 502px;
  width: 347px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xl);
  min-width: 347px;
  max-width: 100%;
}
.before12 {
  align-self: stretch;
  height: 6px;
  position: relative;
  background-color: var(--color-darkslateblue-600);
}
.heading-333 {
  position: relative;
  letter-spacing: -0.1px;
  line-height: 32px;
}
.premier-trial-lawyers4,
.state-in-the3 {
  margin: 0;
}
.premier-trial-lawyers-container4 {
  flex: 1;
  position: relative;
  font-size: var(--font-size-base);
  line-height: 26px;
  font-family: var(--font-rubik);
  display: flex;
  align-items: center;
}
.frame-child-location {
  height: 90px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-smi);
}
.link-icon14 {
  width: 34px;
  height: 34px;
  position: absolute;
  margin: 0 !important;
  bottom: 0;
  left: 9.7px;
  overflow: hidden;
  flex-shrink: 0;
}
.frame-child-location-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}
.heading-footer-subscribe {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-17xl) 0 0;
}
.divservice-content8,
.main-section-section4 {
  align-self: stretch;
  display: flex;
  align-items: center;
  max-width: 100%;
}
.divservice-content8 {
  flex: 1;
  background-color: var(--color-white);
  box-shadow: 0 10px 20px rgba(4, 54, 125, 0.05);
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 0 var(--padding-17xl);
  box-sizing: border-box;
  gap: var(--gap-22xl);
}
.main-section-section4 {
  height: 236px;
  flex-direction: row;
  justify-content: center;
  background-image: url(/public/main--section--section3@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.before13 {
  align-self: stretch;
  height: 6px;
  position: relative;
  background-color: var(--color-darkslateblue-600);
}
.heading-334 {
  position: relative;
  letter-spacing: -0.1px;
  line-height: 32px;
}
.premier-trial-lawyers5,
.state-in-the4 {
  margin: 0;
}
.premier-trial-lawyers-container5 {
  flex: 1;
  position: relative;
  font-size: var(--font-size-base);
  line-height: 26px;
  font-family: var(--font-rubik);
  display: flex;
  align-items: center;
}
.heading-3-link-tax-consult-parent {
  height: 90px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-smi);
}
.link-icon15 {
  width: 34px;
  height: 34px;
  position: absolute;
  margin: 0 !important;
  bottom: 0;
  left: 9.7px;
  overflow: hidden;
  flex-shrink: 0;
}
.frame-parent15 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}
.divservice-content-inner4 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-17xl) 0 0;
}
.divservice-content9,
.main-section-section5 {
  align-self: stretch;
  display: flex;
  align-items: center;
  max-width: 100%;
}
.divservice-content9 {
  flex: 1;
  background-color: var(--color-white);
  box-shadow: 0 10px 20px rgba(4, 54, 125, 0.05);
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 0 var(--padding-17xl);
  box-sizing: border-box;
  gap: var(--gap-22xl);
}
.main-section-section5 {
  height: 236px;
  flex-direction: row;
  justify-content: center;
  background-image: url(/public/main--section--section3@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.frame-child-button,
.frame-child-input,
.frame-parent11,
.frame-parent12 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xl);
  max-width: 100%;
}
.frame-child-button {
  flex: 1;
  flex-direction: column;
  min-width: 226px;
}
.frame-child-input,
.frame-parent11,
.frame-parent12 {
  flex-direction: row;
}
.frame-child-input {
  flex: 1;
  min-width: 471px;
}
.frame-parent11,
.frame-parent12 {
  align-self: stretch;
}
.frame-parent11 {
  flex-direction: column;
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--color-darkslategray);
  font-family: var(--font-libre-baskerville);
}
@media screen and (max-width: 1125px) {
  .main-section-section-group {
    justify-content: center;
    grid-template-columns: repeat(2, minmax(260px, 451px));
  }
  .divservice-content6,
  .main-section-section-container {
    flex: 1;
  }
  .frame-parent12 {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 800px) {
  .main-section-section-group {
    grid-template-columns: minmax(260px, 1fr);
  }
  .divservice-content8 {
    flex: 1;
  }
  .frame-child-input {
    flex-wrap: wrap;
    min-width: 100%;
  }
}
@media screen and (max-width: 450px) {
  .heading-331 {
    font-size: var(--font-size-base);
    line-height: 26px;
  }
  .divservice-content6 {
    gap: var(--gap-22xl);
  }
  .heading-332 {
    font-size: var(--font-size-base);
    line-height: 26px;
  }
  .divservice-content7 {
    gap: var(--gap-22xl);
  }
  .main-section-section-container {
    min-width: 100%;
  }
  .heading-333 {
    font-size: var(--font-size-base);
    line-height: 26px;
  }
  .divservice-content8 {
    gap: var(--gap-22xl);
  }
  .heading-334 {
    font-size: var(--font-size-base);
    line-height: 26px;
  }
  .divservice-content9 {
    gap: var(--gap-22xl);
  }
}
