.section-heading {
  position: relative;
  letter-spacing: -0.4px;
  line-height: 22px;
  font-weight: 500;
  color: #e86500;
}
.section-heading1 {
  margin: 0;
  position: relative;
  font-size: var(--font-size-26xl);
  letter-spacing: -0.2px;
  line-height: 78px;
  text-transform: capitalize;
  font-weight: 700;
  font-family: var(--font-libre-baskerville);
  color: var(--color-darkslateblue-500);
}
.our-services-link {
  text-decoration: none;
  color: white;
}

.bankruptcy-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-4xs);
  max-width: 100%;
}
.before2 {
  align-self: stretch;
  height: 6px;
  position: relative;
  background-color: var(--color-darkslateblue-600);
}
.heading-39,
.symbol115 {
  margin: 0;
  position: relative;
  font-size: inherit;
  font-family: inherit;
}
.symbol115 {
  height: 50px;
  width: 50.2px;
  line-height: 50px;
  font-weight: 400;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.heading-39 {
  letter-spacing: -0.1px;
  line-height: 32px;
  font-weight: 700;
}
.technology-is-only,
.the-demand-and5 {
  margin: 0;
}
@media (min-width: 740px) and (max-width: 1090px) {
  p.the-demand-and5,
  p.the-national-trial {
    width: 100% !important;
  }
  p.the-national-trial,
  img.link-icon4,
  h2.heading-310 {
    margin-left: 20px !important;
  }
}
.the-demand-and5 {
  font-size: 14px;
  margin-top: 25px;
}
.the-demand-and-container5 {
  height: 45px;
  position: relative;
  font-size: var(--font-size-base);
  line-height: 26px;
  font-family: var(--font-rubik);
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.infoprivsacom-link2 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-smi);
  height: 191px;
}
.link-icon3 {
  width: 34px;
  height: 34px;
  position: absolute;
  margin: 0 !important;
  bottom: 0;
  left: 4.8px;
  overflow: hidden;
  flex-shrink: 0;
}
.terms-of-conditions {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  min-width: 209px;
  min-height: 153px;
  max-width: 100%;
  font-size: var(--font-size-7xl);
  color: var(--color-darkslategray);
  font-family: var(--font-libre-baskerville);
}
.privacy-policy-list,
.subscription-button {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.privacy-policy-list {
  flex: 1;
  gap: var(--gap-21xl);
}
.subscription-button {
  width: 492px;
  padding: 0 var(--padding-21xl);
  box-sizing: border-box;
}
.divservice-content1,
.section18 {
  flex: 1;
  display: flex;
  max-width: 100%;
}
.divservice-content1 {
  background-color: var(--color-white);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-17xl);
  box-sizing: border-box;
  gap: var(--gap-20xl);
  height: 272px;
  transition: all 0.5s ease-out;
  background: linear-gradient(to bottom, #04367dee 50%, rgb(255, 255, 255) 50%);
  background-size: 100% 200%;
  background-position: bottom;
  margin: -1px;
}
.divservice-content1:hover {
  background-position: top;
}
.divservice-content1:hover * {
  color: white;
}
.divservice-content1:hover h2 {
  color: #e86500;
}

.divservice-content1:hover .link-icon3 {
  filter: brightness(100);
}

.section18 {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-image: url(/public/section@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  min-width: 354px;
}
.before3 {
  align-self: stretch;
  height: 6px;
  position: relative;
  background-color: var(--color-darkslateblue-600);
}
.heading-310,
.symbol116 {
  margin: 0;
  position: relative;
  font-size: inherit;
  font-family: inherit;
}
.symbol116 {
  height: 50px;
  width: 46.5px;
  line-height: 50px;
  font-weight: 400;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.heading-310 {
  letter-spacing: -0.1px;
  line-height: 32px;
  font-weight: 700;
}
.an-invitation,
.the-national-trial {
  margin: 0;
}

p.the-national-trial {
  margin-left: 5px;

  margin-top: 27px;
  font-size: 14px;
}
.the-national-trial-container {
  height: 45px;
  position: relative;
  line-height: 26px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.link-icon4 {
  width: 34px;
  height: 34px;
  position: absolute;
  margin: 0 !important;
  bottom: 0;
  left: 9px;
  overflow: hidden;
  flex-shrink: 0;
}
.the-national-trial-lawyers-to-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  min-height: 108px;
  font-size: var(--font-size-base);
  font-family: var(--font-rubik);
}

@media (min-width: 740px) and (max-width: 1090px) {
  .heading-3-link-bankruptcy-parent {
    margin-left: 20px !important;
  }
}
.heading-3-link-bankruptcy-parent,
.symbol-parent13 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.heading-3-link-bankruptcy-parent {
  flex-direction: column;
  gap: var(--gap-smi);
  min-width: 236px;
  font-size: var(--font-size-7xl);
  color: var(--color-darkslategray);
  font-family: var(--font-libre-baskerville);
}
.symbol-parent13 {
  flex-direction: row;
  gap: var(--gap-22xl);
}
.divservice-content-inner,
.divservice-content2 {
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}
.divservice-content-inner {
  width: 467px;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 var(--padding-base) 0 0;
}
.divservice-content2 {
  flex: 1;
  background-color: var(--color-white);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  flex-direction: column;
  align-items: center;
  padding: 0 0 var(--padding-17xl);
  gap: var(--gap-20xl);
  transition: all 0.5s ease-out;
  background: linear-gradient(to bottom, #04367dee 50%, rgb(255, 255, 255) 50%);
  background-size: 100% 200%;
  background-position: bottom;
  margin: -1px;
}
.divservice-content2:hover {
  background-position: top;
}

.divservice-content2:hover * {
  color: white;
}
.divservice-content2:hover h2 {
  color: #e86500;
}

.divservice-content2:hover .link-icon4 {
  filter: brightness(100);
}

.energy-renewables-link,
.section19 {
  display: flex;
  flex-direction: row;
  max-width: 100%;
}
.section19 {
  flex: 1;
  align-items: center;
  justify-content: center;
  background-image: url(/public/section1@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  min-width: 354px;
}
.energy-renewables-link {
  align-self: stretch;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xl);
}
.before4 {
  align-self: stretch;
  height: 6px;
  position: relative;
  background-color: var(--color-darkslateblue-600);
}
.heading-311 {
  position: relative;
  letter-spacing: -0.1px;
  line-height: 32px;
}
.experience-in-all,
.he-applies-his {
  margin: 0;
}
.he-applies-his-container {
  flex: 1;
  position: relative;
  font-size: var(--font-size-base);
  line-height: 26px;
  font-family: var(--font-rubik);
  display: flex;
  align-items: center;
}
.heading-3-link-medical-neg-parent {
  height: 90px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-smi);
}
.link-icon5 {
  width: 34px;
  height: 34px;
  position: absolute;
  margin: 0 !important;
  bottom: 0;
  left: 9.7px;
  overflow: hidden;
  flex-shrink: 0;
}
.frame-parent6 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}
.divservice-content-child,
.divservice-content3 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-6xl) 0 0;
}
.divservice-content3 {
  align-self: stretch;
  background-color: var(--color-white);
  box-shadow: 0 10px 20px rgba(4, 54, 125, 0.05);
  flex-direction: column;
  align-items: center;
  padding: 0 0 var(--padding-17xl);
  box-sizing: border-box;
  gap: var(--gap-22xl);
  max-width: 100%;
}
.section20,
.text-symbol {
  flex-direction: row;
  max-width: 100%;
}
.section20 {
  height: 236px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(/public/section4@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.text-symbol {
  align-self: stretch;
  display: grid;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-12xl);
  grid-template-columns: repeat(3, minmax(265px, 1fr));
  font-size: var(--font-size-xl);
  color: var(--color-darkslategray);
  font-family: var(--font-libre-baskerville);
}
.capital-markets-practice,
.medical-negligence-link {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100%;
}
.medical-negligence-link {
  align-items: flex-start;
  gap: var(--gap-11xl);
  text-align: left;
  font-size: var(--font-size-31xl);
  font-family: var(--font-inter);
}
.capital-markets-practice {
  align-items: center;
  gap: var(--gap-21xl);
}
.symbol117,
.view-all-practice {
  position: relative;
  letter-spacing: -0.2px;
  text-transform: uppercase;
}
.view-all-practice {
  line-height: 19px;
  font-weight: 500;
}

.symbol117 {
  font-size: var(--font-size-xs);
  line-height: 12px;
  font-family: var(--font-font-awesome-5-free);
}
.intellectual-property-symbol,
.useful-links-frame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.useful-links-frame {
  gap: var(--gap-3xs);
}
.intellectual-property-symbol {
  padding: 0 var(--padding-xl) 0 var(--padding-7xl);
}
.after {
  align-self: stretch;
  height: 2.4px;
  position: relative;
  background-color: var(--color-sandybrown);
}
.section-link,
.social-media-links-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
}
.section-link {
  width: 290.2px;
  background-color: var(--color-darkslateblue-400);
  overflow: hidden;
  padding: var(--padding-xl) 0 0;
  gap: var(--gap-lg);
  font-size: var(--font-size-base);
  color: var(--color-white);
  transition: all 0.5s ease-out;
  background: linear-gradient(
    to top,
    #e86500 50%,
    var(--color-darkslateblue-400) 50%
  );
  background-size: 100% 200%;
  background-position: top;
  transition: all 0.5s ease-out;
}
.section-link:hover {
  background-position: bottom;
}
.social-media-links-list {
  width: 1160px;
  padding: 0 var(--padding-xl) var(--padding-81xl);
  gap: var(--gap-41xl);
  max-width: 100%;
  flex-shrink: 0;
  text-align: center;
  font-size: var(--font-size-lg);
  color: var(--color-sandybrown);
  font-family: var(--font-rubik);
}
@media screen and (max-width: 1325px) {
  .social-media-links-list {
    gap: var(--gap-41xl);
  }
}
@media screen and (max-width: 1125px) {
  .text-symbol {
    justify-content: center;
    grid-template-columns: repeat(2, minmax(265px, 459px));
  }
  .social-media-links-list {
    padding-bottom: var(--padding-46xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 800px) {
  .section-heading1 {
    font-size: var(--font-size-17xl);
    line-height: 62px;
  }
  .symbol115 {
    font-size: var(--font-size-21xl);
    line-height: 40px;
  }
  .divservice-content1 {
    gap: var(--gap-20xl);
  }
  .symbol116 {
    font-size: var(--font-size-21xl);
    line-height: 40px;
  }
  .divservice-content2 {
    gap: var(--gap-20xl);
  }
  .text-symbol {
    gap: var(--gap-12xl);
    grid-template-columns: minmax(265px, 1fr);
  }
  .capital-markets-practice {
    gap: var(--gap-21xl);
  }
}
@media screen and (max-width: 450px) {
  .section-heading1 {
    font-size: var(--font-size-8xl);
    line-height: 47px;
  }
  .symbol115 {
    font-size: var(--font-size-11xl);
    line-height: 30px;
  }
  .heading-39 {
    font-size: var(--font-size-2xl);
    line-height: 26px;
  }
  .privacy-policy-list {
    flex-wrap: wrap;
    gap: var(--gap-21xl);
  }
  .section18 {
    min-width: 100%;
  }
  .symbol116 {
    font-size: var(--font-size-11xl);
    line-height: 30px;
  }
  .heading-310 {
    font-size: var(--font-size-2xl);
    line-height: 26px;
  }
  .symbol-parent13 {
    flex-wrap: wrap;
    gap: var(--gap-22xl);
  }
  .section19 {
    min-width: 100%;
  }
  .heading-311 {
    font-size: var(--font-size-base);
    line-height: 26px;
  }
  .divservice-content3 {
    gap: var(--gap-22xl);
  }
  .social-media-links-list {
    padding-bottom: var(--padding-23xl);
    box-sizing: border-box;
  }
}
