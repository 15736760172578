input[type="textarea"],input[type="text"], input[type="email"], input[type="tel"] {
  font-family: 'Rubik'!important;
  font-size: 17px !important;
  /* width:400px ; */
 }


@media screen and (min-width: 380px) and (max-width: 550px) {
  form {
    max-width: 100%; 
  }

  iframe {
    width: 350px !important;
    height: 300px !important;
  }

  .textarea1 {
    box-sizing: border-box;
    width: 100% !important; 
    padding: 10px; 
    margin-top: 10px; 
  }

  .divelementskit-infobox11{
    width: 100% !important;
  }
  /* textarea {
    margin-left: -9px !important;
  }
  
   */
   input[type="textarea"],input[type="text"], input[type="email"], input[type="tel"] {
    font-family: 'Rubik'!important;
    font-size: 17px !important;
    width:200px ;
   }

  button {
    width: 20px;
    padding: 10px; 
    font-size: 10px; 
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    text-align: center; 
    font-size: 15px !important;
  }

  
  button img {
    margin-top: 5px;
  }

  .fullname {
    padding-left: 5px;
  }

  .level-7-151-container {
    padding-right: 15px;
  }
  .textarea-container,
  .email-wrapper,
  .phone-number-wrapper {
    width: 150px;
    margin-left: -15px;
  }

  .divelementor-widget-container13 {
    margin-left: -3px;
  }

  .input-container {
    margin-left: -10px;
  }




  
}


.heading-338,
.symbol213 {
  position: relative;
  line-height: 20px;
}
.error-message,.success-message {
  font-size: 20px;
}
.get-free-quote1{
  border: none;
  background-color: #eb934f;
  color: white;
}
button.get-free-quote1:hover{
  background-color: #bd7239;
}
.textarea2:focus {
  outline: none; 
  resize: none;
  text-transform: none;
    padding-left: 48px!important;
    /* padding-bottom: 2px; */
    height: 88px;
}
.textarea2::placeholder{
  font-family: var(--font-roboto);
  font-size: 16px;
}

.textarea1{
  padding-bottom: 0;
  width: 650px;

}
textarea.textarea2{
width: 100%;
border:none;
margin-bottom: -500px !important;
}
.heading-338 {
  margin: 0;
  font-size: inherit;
  letter-spacing: -0.4px;
  line-height: 28.8px;
  font-weight: 400;
  font-family: inherit;
}
.level-7-151,
.new-south-wales {
  margin: 0;
}
.level-7-151-container {
  flex: 1;
  width: 100%;
  position: relative;
  font-size: var(--font-size-lg);
  line-height: 28px;
  font-family: var(--font-rubik);
  display: flex;
  align-items: center;
}
.divelementskit-infobox11,
.map {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.map {
  align-self: stretch;
  flex-direction: column;
  gap: var(--gap-3xl);
  text-align: left;
  font-size: var(--font-size-5xl);
  color: var(--color-darkslategray);
  font-family: var(--font-libre-baskerville);
}
.divelementskit-infobox11 {
  flex: 1;
  border-radius: var(--br-8xs);
  background-color: var(--color-white);
  overflow: hidden;
  flex-direction: row;
  padding: 0 var(--padding-9xl) var(--padding-xs) 0;
  gap: var(--gap-6xl);
  width: 400px;
}
.heading-339,
.symbol214 {
  position: relative;
  line-height: 20px;
}
.heading-339 {
  margin: 0;
  font-size: inherit;
  letter-spacing: -0.4px;
  line-height: 28.8px;
  font-weight: 400;
  font-family: inherit;
}
.div {
  position: relative;
  font-size: var(--font-size-lg);
  line-height: 28px;
  font-family: var(--font-rubik);
}
.divelementskit-infobox12,
.heading-3-phone-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.heading-3-phone-parent {
  flex-direction: column;
  gap: var(--gap-lgi);
  text-align: left;
  font-size: var(--font-size-5xl);
  color: var(--color-darkslategray);
  font-family: var(--font-libre-baskerville);
}
.divelementskit-infobox12 {
  align-self: stretch;
  border-radius: var(--br-8xs);
  background-color: var(--color-white);
  overflow: hidden;
  flex-direction: row;
  padding: 0 0 var(--padding-4xs);
  gap: var(--gap-6xl);
}
.heading-340,
.symbol215 {
  position: relative;
  line-height: 20px;
}
.heading-340 {
  margin: 0;
  font-size: inherit;
  letter-spacing: -0.4px;
  line-height: 28.8px;
  font-weight: 400;
  font-family: inherit;
}
.examplewebsitecom {
  position: relative;
  font-size: var(--font-size-lg);
  line-height: 28px;
  font-family: var(--font-rubik);
  white-space: nowrap;
}
.heading-3-email-parent {
  flex-direction: column;
  gap: var(--gap-lgi);
  text-align: left;
  font-size: var(--font-size-5xl);
  color: var(--color-darkslategray);
  font-family: var(--font-libre-baskerville);
}
.divelementskit-infobox13,
.fullname,
.heading-3-email-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.divelementskit-infobox13 {
  align-self: stretch;
  border-radius: var(--br-8xs);
  background-color: var(--color-white);
  overflow: hidden;
  flex-direction: row;
  padding: 0 0 var(--padding-4xs);
  gap: var(--gap-6xl);
}
.fullname {
  width: 370px;
  height: 334px;
  flex-direction: column;
  gap: var(--gap-7xl);
  max-width: 100%;
}

.vt-icon {
  top: -207px;
  right: -40px;
}
.vt-icon,
.vt-icon1,
.vt-icon2,
.vt-icon3 {
  height: 256px;
  width: 256px;
  position: absolute;
  margin: 0 !important;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.vt-icon1 {
  top: -207px;
  left: -170px;
}
.vt-icon2,
.vt-icon3 {
  bottom: -190px;
}
.vt-icon2 {
  right: -40px;
}
.vt-icon3 {
  left: -170px;
}
.lastminutecom-london-eye {
  position: relative;
  font-size: var(--font-size-sm);
  font-weight: 500;
}
.london-se1-7pb,
.riverside-building-county {
  position: relative;
}
.riverside-building-county-hal-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.div3 {
  position: relative;
  font-weight: 500;
}
.img-rated-45-out-of-5,
.img-rated-45-out-of-51,
.img-rated-45-out-of-52,
.img-rated-45-out-of-53,
.img-rated-45-out-of-54 {
  height: 11px;
  width: 11px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-10xs) 0 0;
}
.link-169773 {
  position: relative;
  font-size: var(--font-size-xs);
  color: var(--color-royalblue);
}
.frame-container3,
.frame-parent24 {
  display: flex;
  justify-content: flex-start;
}
.frame-parent24 {
  flex-direction: row;
  align-items: flex-end;
  gap: var(--gap-3xs);
  font-size: var(--font-size-sm);
}
.frame-container3 {
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-8xs);
  color: var(--color-dimgray-200);
}
.link-view {
  position: relative;
  color: var(--color-royalblue);
}
.frame-container2 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.link-get-directions-to-this {
  width: 22px;
  height: 22px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.directions {
  position: relative;
}
.frame-container4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-8xs);
  text-align: center;
  color: var(--color-royalblue);
}
.div2 {
  flex: 1;
  border-radius: var(--br-11xs);
  background-color: var(--color-white);
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-xl) var(--padding-3xs)
    var(--padding-xs);
  gap: var(--gap-17xl);
  z-index: 1;
}
.button-frame,
.div2,
.frame-container1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.button-frame {
  align-self: stretch;
  flex-direction: row;
  position: relative;
}
.frame-container1 {
  flex: 1;
  flex-direction: column;
  padding: var(--padding-mid) 0 0;
  box-sizing: border-box;
  min-width: 196px;
}
.vt-icon4,
.vt-icon5 {
  width: 256px;
  height: 256px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.frame-container5 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 256px;
}
.divmfselectcontrol,
.symbol-frame {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.divmfselectcontrol {
  width: 598px;
  align-items: center;
  gap: var(--gap-21xl);
  max-width: 115%;
  flex-shrink: 0;
}
.symbol-frame {
  align-self: stretch;
  height: 315px;
  align-items: flex-start;
  padding: 0;
  box-sizing: border-box;
  max-width: 100%;
}
.region-map,
.region-map1,
.vt-icon6 {
  position: absolute;
  overflow: hidden;
  object-fit: cover;
}
.vt-icon6 {
  height: 100%;
  width: 100%;
  margin: 0 !important;
  top: 0.4px;
  right: 0;
  bottom: -0.4px;
  left: 0;
  max-width: 100%;
  max-height: 100%;
}
.region-map,
.region-map1 {
  top: -125.4px;
  left: calc(50% - 245px);
  width: 256px;
  height: 256px;
}
.region-map1 {
  left: calc(50% + 11px);
}
.button-show-satellite-imager,
.div4 {
  background-color: var(--color-gainsboro-100);
  overflow: hidden;
}
.div4 {
  position: absolute;
  height: 90.48%;
  width: 90.48%;
  top: 5.71%;
  right: 4.76%;
  bottom: 3.81%;
  left: 4.76%;
  display: none;
}
.button-show-satellite-imager {
  height: 42px;
  width: 42px;
  position: relative;
  border-radius: var(--br-10xs);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  border: 1px solid var(--color-white);
  box-sizing: border-box;
  flex-shrink: 0;
  z-index: 1;
}
.link-frame {
  height: 256px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: var(--padding-lg) var(--padding-25xl);
  box-sizing: border-box;
  position: relative;
}
.div5,
.image-icon,
.vt-icon7 {
  position: absolute;
  margin: 0 !important;
}
.vt-icon7 {
  width: 100%;
  height: 100%;
  top: 0.4px;
  right: 0;
  bottom: -0.4px;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.div5,
.image-icon {
  z-index: 1;
}
.image-icon {
  width: 52px;
  height: 21.8px;
  top: 34.6px;
  right: 61px;
  overflow: hidden;
  flex-shrink: 0;
}
.div5 {
  height: 100%;
  width: 100%;
  top: 0.4px;
  right: -0.1px;
  bottom: -0.4px;
  left: 0.4px;
  background-color: var(--color-whitesmoke-500);
}
.button-keyboard {
  position: relative;
  line-height: 14px;
  z-index: 2;
}
.div-frame {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 var(--padding-12xs) 0 var(--padding-8xs);
  position: relative;
  white-space: nowrap;
}
.div6 {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0.4px;
  right: 1.5px;
  bottom: -0.4px;
  left: -0.4px;
  background-color: var(--color-whitesmoke-500);
  z-index: 2;
}
.map-data-2024 {
  position: relative;
  line-height: 14px;
  z-index: 3;
}
.link-frame1,
.map-data {
  display: flex;
  align-items: center;
}
.map-data {
  margin: 0 !important;
  position: absolute;
  top: 56.6px;
  right: -44px;
  flex-direction: row;
  justify-content: center;
  padding: var(--padding-12xs) var(--padding-10xs) var(--padding-11xs)
    var(--padding-8xs);
  white-space: nowrap;
  text-align: right;
}
.link-frame1 {
  height: 256px;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-38xl) var(--padding-59xl) var(--padding-38xl)
    var(--padding-60xl);
  box-sizing: border-box;
  position: relative;
}
.vt-icon8 {
  height: 100%;
  width: 100%;
  top: 0.4px;
  right: 0;
  bottom: -0.4px;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.div-icon,
.div7,
.vt-icon8 {
  position: absolute;
  margin: 0 !important;
}
.div-icon {
  height: 81px;
  width: 40px;
  top: -34px;
  right: 88px;
  border-radius: var(--br-11xs);
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.div7 {
  height: 100%;
  width: calc(100% - 0.9px);
  top: 0.4px;
  right: 1.4px;
  bottom: -0.4px;
  left: -0.5px;
  background-color: var(--color-whitesmoke-500);
  z-index: 3;
}
.link-terms {
  position: relative;
  line-height: 14px;
  z-index: 4;
}
.div-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-12xs) var(--padding-6xs) var(--padding-11xs)
    var(--padding-8xs);
  position: relative;
}
.div8 {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0.4px;
  right: 1px;
  bottom: -0.4px;
  left: -0.4px;
  background-color: var(--color-whitesmoke-500);
  z-index: 1;
}
.link-report {
  position: relative;
  line-height: 14px;
  z-index: 2;
}
.div-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-12xs) var(--padding-11xs) var(--padding-11xs)
    var(--padding-8xs);
  position: relative;
  white-space: nowrap;
}
.link-frame-parent,
.link-frame2 {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.link-frame2 {
  height: 256px;
  display: flex;
  padding: var(--padding-38xl) var(--padding-59xl) var(--padding-38xl)
    var(--padding-24xl);
  box-sizing: border-box;
  position: relative;
  text-align: right;
}
.link-frame-parent {
  width: 768px;
  display: grid;
  max-width: 111%;
  flex-shrink: 0;
  grid-template-columns: repeat(3, minmax(192px, 1fr));
}
.map-parent,
.section-container1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  box-sizing: border-box;
}
.map-parent {
  width: 690px;
  justify-content: flex-start;
  max-width: 130%;
  flex-shrink: 0;
}
.section-container1 {
  align-self: stretch;
  height: 71px;
  justify-content: flex-end;
  max-width: 100%;
  text-align: center;
  font-size: var(--font-size-3xs);
}
.div1,
.iframe-london-eye-london-u {
  overflow: hidden;
  display: flex;
  max-width: 100%;
}
.div1 {
  flex: 1;
  background-color: var(--color-gainsboro-100);
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}
.iframe-london-eye-london-u {
  align-self: stretch;
  background-color: var(--color-gray-200);
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: left;
  font-size: var(--font-size-xs);
  color: var(--color-black);
  font-family: var(--font-roboto);
}
.divelementorwidgetcontainer {
  width: 530px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-12xl);
  min-width: 530px;
  max-width: 100%;
}
@media screen and (max-width:550px) {
  .divelementorwidgetcontainer {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
}
@media screen and (max-width:1220px) {
  .divelementorwidgetcontainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .divelementor-widget-wrap9{
    width:380px ;
  }
}
.get-in-touch1,
.our-experts1 {
  margin: 0;
}
.heading-2-container6 {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  letter-spacing: -1.44px;
  line-height: 59px;
  font-weight: 700;
  font-family: inherit;
  display: flex;
  align-items: center;
}
.heading12 {
  align-self: stretch;
  height: 111px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-18xl) 0 0;
  box-sizing: border-box;
  text-align: left;
  font-family: var(--font-libre-baskerville);
}
.symbol216 {
  position: relative;
  line-height: 20px;
}
.divelementor-widget-container10 {
  width: 50px;
  background-color: var(--color-gainsboro-200);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-smi) var(--padding-smi) var(--padding-lg);
  box-sizing: border-box;
  z-index: 1;
}
.full-name2 {
  position: relative;
  letter-spacing: -0.15px;
  text-transform: uppercase;
}
.input10,
.textarea-container {
  display: flex;
  justify-content: flex-start;
}
.textarea-container {
  flex-direction: column;
  align-items: flex-start;
  padding: var(--padding-10xs) 0 0;
  text-align: left;
  font-size: var(--font-size-mini);
  font-family: var(--font-rubik);
  width: 10%;
}
.input10 {
  align-self: stretch;
  background-color: var(--color-white);
  flex-direction: row;
  align-items: center;
  padding: 0 0 var(--padding-12xs);
  gap: var(--gap-2xl);
}
.symbol217 {
  position: relative;
  line-height: 20px;
}
.divelementor-widget-container11 {
  width: 50px;
  background-color: var(--color-gainsboro-200);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-smi) var(--padding-smi) var(--padding-lg);
  box-sizing: border-box;
  z-index: 1;
}
.email9 {
  position: relative;
  letter-spacing: -0.15px;
  text-transform: uppercase;
}
.email-wrapper,
.input11 {
  display: flex;
  justify-content: flex-start;
}
.email-wrapper {
  flex-direction: column;
  align-items: flex-start;
  padding: var(--padding-10xs) 0 0;
  text-align: left;
  font-size: var(--font-size-mini);
  font-family: var(--font-rubik);
  
}
.input11 {
  align-self: stretch;
  background-color: var(--color-white);
  flex-direction: row;
  align-items: center;
  padding: 0 0 var(--padding-12xs);
  gap: var(--gap-2xl);
}
.symbol218 {
  position: relative;
  line-height: 20px;
}
.divelementor-widget-container12 {
  width: 50px;
  background-color: var(--color-gainsboro-200);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-smi) var(--padding-smi) var(--padding-lg);
  box-sizing: border-box;
  z-index: 1;
}
.phone-number1 {
  position: relative;
  letter-spacing: -0.15px;
  text-transform: uppercase;
}
.input12,
.phone-number-wrapper {
  display: flex;
  justify-content: flex-start;
}
.phone-number-wrapper {
  flex-direction: column;
  align-items: flex-start;
  padding: var(--padding-10xs) 0 0;
  text-align: left;
  font-size: var(--font-size-mini);
  font-family: var(--font-rubik);
}
.input12 {
  align-self: stretch;
  background-color: var(--color-white);
  flex-direction: row;
  align-items: center;
  padding: 0 0 var(--padding-12xs);
  gap: var(--gap-2xl);
}
.symbol219 {
  position: relative;
  line-height: 20px;
}
.divelementor-widget-container13 {
  width: 50px;
  margin-top: -1px;
  margin-bottom: -1px;
  background-color: var(--color-gainsboro-200);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-2xs) var(--padding-2xs) var(--padding-lg);
  box-sizing: border-box;
  z-index: 1;
}
.select2 {
  position: relative;
  line-height: 21px;
  text-transform: uppercase;
}
.input-container {
  flex-direction: column;
  align-items: flex-start;
  padding: var(--padding-10xs) 0 0;
  text-align: left;
  font-size: var(--font-size-mini);
  font-family: var(--font-rubik);
}
.divmf-select-indicator1 {
  position: absolute;
  top: calc(50% - 2.5px);
  left: 0;
  border-top: 5px solid var(--color-lightgray-400);
  border-right: 4px solid var(--color-lightgray-400);
  border-left: 4px solid var(--color-lightgray-400);
  box-sizing: border-box;
  width: 8px;
  height: 5px;
}
.divmf-select-indicators {
  height: 21px;
  width: 8px;
  position: relative;
  display: none;
}
.divmf-input1,
.divmf-select-control {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
}
.divmf-select-control {
  flex: 1;
  border: 1px solid var(--color-steelblue);
  box-sizing: border-box;
  justify-content: flex-start;
  padding: var(--padding-12xs) var(--padding-10xs) var(--padding-11xs) 0;
  gap: var(--gap-base);
}
.divmf-input1 {
  align-self: stretch;
  background-color: var(--color-white);
  justify-content: center;
}
.symbol220 {
  position: relative;
  line-height: 20px;
}
.divelementor-widget-container14 {
  width: 50px;
  background-color: var(--color-gainsboro-200);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-sm) var(--padding-sm) var(--padding-lg);
  box-sizing: border-box;
  z-index: 1;
}
.heading13,
.textarea1 {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.textarea1 {
  background-color: var(--color-white);
  overflow: hidden;
  flex-direction: row;
  padding: 0 0 42px;
  width: auto;
}
.heading13 {
  flex-direction: column;
  gap: var(--gap-3xs);
  max-width: 100%;
  font-size: var(--font-size-xl);
  color: var(--color-slategray-200);
}
.get-free-quote1,
.symbol221 {
  position: relative;
  line-height: 15px;
  text-transform: uppercase;
}
.get-free-quote1 {
  line-height: 18px;
  font-weight: 500;
  font-family: var(--font-rubik);
}
.section-button {
  align-self: stretch;
  background-color: var(--color-sandybrown);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-base) var(--padding-xl);
  gap: var(--gap-11xs);
  font-size: var(--font-size-mini);
}
.divelementor-widget-wrap9,
.divmfinput,
.select1 {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.divelementor-widget-wrap9 {
  width: 675px;
  flex: 1;
  background-color: var(--color-darkslateblue-500);
  flex-direction: column;
  align-items: center;
  padding: var(--padding-84xl) var(--padding-61xl) var(--padding-61xl);
  box-sizing: border-box;
  gap: var(--gap-13xl);
  min-width: 361px;
  font-size: var(--font-size-23xl);
  color: var(--color-white);
}
.divmfinput,
.select1 {
  flex-direction: row;
  align-items: flex-start;
}
.divmfinput {
  flex: 1;
  gap: var(--gap-21xl);
  justify-content: center;
}
.select1 {
  width: 1180px;
  padding: 0 var(--padding-16xl) 0 var(--padding-xl);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--font-size-xl);
  color: var(--color-sandybrown);
  font-family: var(--font-font-awesome-5-free);
}
@media screen and (max-width: 1125px) {
  .divelementorwidgetcontainer {
    flex: 1;
  }
  .divmfinput {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 800px) {
  .frame-container5 {
    flex: 1;
  }
  .divmfselectcontrol {
    flex-wrap: wrap;
    gap: var(--gap-21xl);
  }
  .symbol-frame {
    height: auto;
  }
  .link-frame-parent {
    grid-row-gap: 20px;
    justify-content: center;
    grid-template-columns: repeat(2, minmax(192px, 333px));
  }
  .divelementorwidgetcontainer {
    gap: var(--gap-12xl);
    min-width: 100%;
  }
  .heading-2-container6 {
    font-size: var(--font-size-15xl);
    line-height: 47px;
  }
  .divelementor-widget-wrap9 {
    gap: var(--gap-13xl);
    padding: var(--padding-48xl) var(--padding-21xl) var(--padding-33xl);
    box-sizing: border-box;
  }
  .divmfinput {
    gap: var(--gap-21xl);
  }
}
@media screen and (max-width: 450px) {
  .symbol213 {
    font-size: var(--font-size-base);
    line-height: 16px;
  }
  .heading-338 {
    font-size: var(--font-size-lgi);
    line-height: 23px;
  }
  .symbol214 {
    font-size: var(--font-size-base);
    line-height: 16px;
  }
  .heading-339 {
    font-size: var(--font-size-lgi);
    line-height: 23px;
  }
  .symbol215 {
    font-size: var(--font-size-base);
    line-height: 16px;
  }
  .heading-340 {
    font-size: var(--font-size-lgi);
    line-height: 23px;
  }
  .div2 {
    gap: var(--gap-17xl);
  }
  .link-frame1,
  .link-frame2 {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
  .link-frame-parent {
    grid-template-columns: minmax(192px, 1fr);
  }
  .heading-2-container6 {
    font-size: var(--font-size-6xl);
    line-height: 35px;
  }
  .symbol216 {
    font-size: var(--font-size-base);
    line-height: 16px;
  }
  .input10 {
    flex-wrap: wrap;
  }
  .symbol217 {
    font-size: var(--font-size-base);
    line-height: 16px;
  }
  .input11 {
    flex-wrap: wrap;
  }
  .symbol218 {
    font-size: var(--font-size-base);
    line-height: 16px;
  }
  .input12 {
    flex-wrap: wrap;
  }
  .symbol219 {
    font-size: var(--font-size-base);
    line-height: 16px;
  }
  .divmf-select-control {
    /* flex-wrap: wrap; */
    padding-left: var(--padding-10xs);
    box-sizing: border-box;
  }
  .symbol220 {
    font-size: var(--font-size-base);
    line-height: 16px;
  }
  .divelementor-widget-wrap9 {
    padding-top: var(--padding-25xl);
    padding-bottom: var(--padding-15xl);
    box-sizing: border-box;
    min-width: 100%;
  }
}
@media screen and (min-width: 1300px) {

.divelementor-widget-wrap9 {
  width: 675px;
}
}
@media screen and (min-width: 1125px) and (max-width: 1300px) {

  .divelementor-widget-wrap9 {
    width: 554px;
  }
  }