.heading-23 {
  position: relative;
  letter-spacing: -0.4px;
  line-height: 36px;
}
.improvements-in-technology1,
.only-driven-by1,
.practitioners-increasingly1,
.the-demand-and1 {
  margin: 0;
}
.the-demand-and-container1 {
  flex: 1;
  position: relative;
  line-height: 24px;
  display: flex;
  align-items: center;
}
.item-link-facebook1,
.item-link-instagram1,
.item-link-linkedin1,
.item-link-twitter1 {
  height: 35px;
  width: 35px;
  position: relative;
  border-radius: var(--br-mid-5);
  min-height: 35px;
}
.capital-markets-list {
  flex-direction: row;
  padding: 0 var(--padding-12xs) 0 0;
  gap: var(--gap-sm);
}
.capital-markets-header,
.capital-markets-list,
.footer-logo-png,
.team-section {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.capital-markets-header {
  flex-direction: row;
  padding: 0 var(--padding-8xs);
}
.footer-logo-png,
.team-section {
  flex-direction: column;
}
.team-section {
  flex: 1;
  gap: var(--gap-29xl);
  font-size: var(--font-size-base);
  color: var(--color-lightsteelblue-100);
  font-family: var(--font-rubik);
}
.footer-logo-png {
  height: 232px;
  gap: var(--gap-3xl);
  font-size: var(--font-size-10xl-9);
}
.practice-area2 {
  position: relative;
  letter-spacing: -0.4px;
  line-height: 30px;
  text-transform: capitalize;
}
.list-item18,
.symbol31 {
  position: relative;
  line-height: 10.89px;
}
.list-item18 {
  line-height: 19px;
}
.energy-renewables-label,
.useful-links-header {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.useful-links-header {
  flex-direction: row;
  padding: 0 0 0 var(--padding-xl);
  margin-top: -5px;
  font-size: var(--font-size-base);
  color: var(--color-gray-700);
  font-family: var(--font-rubik);
}
.energy-renewables-label {
  flex-direction: column;
}
.list-item19,
.symbol32 {
  position: relative;
  line-height: 10.89px;
}
.list-item19 {
  line-height: 19px;
}
.list-item-link-bankruptc-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 var(--padding-xl);
  margin-top: -5px;
  font-size: var(--font-size-base);
  color: var(--color-gray-700);
  font-family: var(--font-rubik);
}
.energy-renewables-label1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.list-item20,
.symbol33 {
  position: relative;
  line-height: 10.89px;
}
.list-item20 {
  line-height: 19px;
}
.energy-renewables-label2,
.list-item-link-banking-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.list-item-link-banking-container {
  flex-direction: row;
  padding: 0 0 0 var(--padding-xl);
  margin-top: -5px;
  font-size: var(--font-size-base);
  color: var(--color-gray-700);
  font-family: var(--font-rubik);
}
.energy-renewables-label2 {
  flex-direction: column;
}
.list-item21,
.symbol34 {
  position: relative;
  line-height: 10.89px;
}
.list-item21 {
  line-height: 19px;
}
.list-item-link-tax-consu-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 var(--padding-xl);
  margin-top: -5px;
  font-size: var(--font-size-base);
  color: var(--color-gray-700);
  font-family: var(--font-rubik);
}
.energy-renewables-label3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.list-item22,
.symbol35 {
  position: relative;
  line-height: 10.89px;
}
.list-item22 {
  line-height: 19px;
}
.list-item-link-medical-n-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 var(--padding-xl);
  margin-top: -5px;
  font-size: var(--font-size-base);
  color: var(--color-gray-700);
  font-family: var(--font-rubik);
}
.energy-renewables-label4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.list-item23,
.symbol36 {
  position: relative;
  line-height: 10.89px;
}
.list-item23 {
  line-height: 19px;
}
.energy-renewables-label5,
.list-item-link-energy-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.list-item-link-energy-container {
  flex-direction: row;
  padding: 0 0 0 var(--padding-xl);
  margin-top: -5px;
  font-size: var(--font-size-base);
  color: var(--color-gray-700);
  font-family: var(--font-rubik);
}
.energy-renewables-label5 {
  flex-direction: column;
}
.list-item24,
.symbol37 {
  position: relative;
  line-height: 10.89px;
}
.list-item24 {
  line-height: 19px;
}
.list-item-link-intellect-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 var(--padding-xl);
  margin-top: -5px;
  font-size: var(--font-size-base);
  color: var(--color-gray-700);
  font-family: var(--font-rubik);
}
.banking-finance-label,
.energy-renewables-label6,
.medical-negligence-label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.medical-negligence-label {
  gap: var(--gap-sm);
  font-size: var(--font-size-6xs);
  color: var(--color-darkkhaki);
  font-family: var(--font-font-awesome-5-free);
}
.banking-finance-label {
  height: 301px;
  padding: 0 var(--padding-9xs) 0 0;
  box-sizing: border-box;
  gap: var(--gap-3xl);
}
.heading-43 {
  position: relative;
  letter-spacing: -0.4px;
  line-height: 30px;
  text-transform: capitalize;
}
.symbol38,
.symbol39,
.symbol40,
.symbol41,
.symbol42,
.symbol43 {
  position: relative;
  line-height: 6px;
}
.contact-us-label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xl);
}
.list-item25,
.list-item26,
.list-item27 {
  position: relative;
  line-height: 34px;
}
.list-item26,
.list-item27 {
  z-index: 1;
}
.list-item27 {
  z-index: 2;
}
.list-item28,
.list-item29,
.list-item30 {
  position: relative;
  line-height: 34px;
  z-index: 3;
}
.list-item29,
.list-item30 {
  z-index: 4;
}
.list-item30 {
  z-index: 5;
}
.infoprivsacom-link {
  flex-direction: column;
  align-items: flex-start;
  font-size: var(--font-size-lg);
  color: var(--color-lightsteelblue-100);
  font-family: var(--font-roboto);
}
.head-office-logo,
.infoprivsacom-link,
.legal-info-link2 {
  display: flex;
  justify-content: flex-start;
}
.head-office-logo {
  flex-direction: row;
  align-items: center;
  padding: 0 var(--padding-12xs) 0 0;
  gap: var(--gap-smi);
  font-size: var(--font-size-7xs);
  color: var(--color-sandybrown);
  font-family: var(--font-font-awesome-5-free);
}
.legal-info-link2 {
  flex-direction: column;
  align-items: flex-start;
  padding: 0 var(--padding-5xl) 0 0;
  gap: var(--gap-xl);
}
.heading-44 {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.4px;
  line-height: 30px;
  text-transform: capitalize;
  font-weight: 700;
  font-family: inherit;
}
.symbol44,
.symbol45,
.symbol46 {
  position: relative;
  line-height: 18px;
}
.footer-logo,
.head-office-location {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-lgi);
}
.head-office-location {
  gap: var(--gap-11xl);
}
.little-william-st1,
.vic-3000-australia1 {
  margin: 0;
}
.list-item-container2,
.list-item31,
.list-item32 {
  position: relative;
  letter-spacing: -0.2px;
  line-height: 22px;
}
.list-item32 {
  white-space: nowrap;
}
.header-logo,
.legal-info-link3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  font-size: var(--font-size-base);
  color: var(--color-white);
  font-family: var(--font-roboto);
}
.header-logo {
  flex-direction: row;
  align-items: flex-end;
  padding: 0 var(--padding-12xs) var(--padding-9xs) 0;
  gap: var(--gap-mid);
  font-size: var(--font-size-lg);
  color: var(--color-sandybrown);
  font-family: var(--font-font-awesome-5-free);
}
.heading-45 {
  font-size: var(--font-size-2xl-8);
  letter-spacing: -0.4px;
  line-height: 30px;
  text-transform: capitalize;
}
.email2,
.heading-45 {
  position: relative;
}
.input1 {
  position: absolute;
  top: -0.3px;
  left: 0;
  background-color: var(--color-gray-100);
  border: 1px solid var(--color-whitesmoke-400);
  box-sizing: border-box;
  width: 226px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-base) var(--padding-base) var(--padding-mini);
  white-space: nowrap;
}
.symbol47 {
  position: relative;
  line-height: 15px;
}
.button3 {
  position: absolute;
  top: 0.3px;
  left: 190px;
  background-color: var(--color-sandybrown);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-mini) var(--padding-lgi) var(--padding-mid)
    var(--padding-xl);
  z-index: 1;
  text-align: center;
  font-size: var(--font-size-mini);
  color: var(--color-white);
  font-family: var(--font-font-awesome-5-free);
}
.email-symbol {
  align-self: stretch;
  height: 47px;
  position: relative;
  font-size: var(--font-size-sm);
  color: var(--color-lightsteelblue-300);
  font-family: var(--font-roboto);
}
.subscribe-button {
  width: 239px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-2xl);
  font-size: var(--font-size-3xl);
}
.our-team,
.section6,
.subscribe-button {
  display: flex;
  justify-content: flex-start;
}
.section6 {
  overflow: hidden;
  flex-direction: row;
  align-items: flex-start;
  padding: var(--padding-56xl) var(--padding-163xl) var(--padding-83xl)
    var(--padding-146xl);
  gap: var(--gap-57xl);
  background-image: url(/public/section17@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: left;
  font-size: var(--font-size-2xl-8);
  color: var(--color-white);
  font-family: var(--font-libre-baskerville);
}
.our-team {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  flex-direction: column;
  align-items: center;
  padding: 0;
  box-sizing: border-box;
  letter-spacing: normal;
}
@media screen and (max-width: 1350px) {
  .section6 {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 800px) {
  .section6 {
    gap: var(--gap-57xl);
    padding: var(--padding-30xl) var(--padding-72xl) var(--padding-47xl)
      var(--padding-63xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .heading-23 {
    font-size: var(--font-size-5xl);
    line-height: 29px;
  }
  .team-section {
    gap: var(--gap-29xl);
  }
  .heading-43,
  .practice-area2 {
    font-size: var(--font-size-mid);
    line-height: 24px;
  }
  .heading-44,
  .heading-45 {
    font-size: var(--font-size-lg);
    line-height: 24px;
  }
  .heading-45 {
    font-size: var(--font-size-mid);
  }
  .section6 {
    gap: var(--gap-57xl);
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
}

.section {
  align-self: stretch;
  overflow: hidden;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  /* padding: var(--padding-56xl) 179px var(--padding-56xl) var(--padding-146xl); */
  box-sizing: border-box;
  gap: var(--gap-83xl);
  background-image: url(/public/section10@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  min-height: 479px;
  max-width: 100%;
  flex-shrink: 0;
}