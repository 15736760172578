.september-15-2020 {
  position: relative;
  letter-spacing: -0.2px;
  line-height: 17px;
  text-transform: uppercase;
  font-weight: 500;
}
.section27 {
  border-right: 1px solid var(--color-lightgray-100);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-11xs) var(--padding-mid) 0 0;
}
.link-litigation {
  position: relative;
  letter-spacing: -0.2px;
  line-height: 17px;
  text-transform: uppercase;
  font-weight: 500;
}
.link-litigation-wrapper {
  height: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
  box-sizing: border-box;
}
.section28,
.symbol132 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.section28 {
  align-self: stretch;
  border-right: 1px solid var(--color-lightgray-100);
  align-items: center;
  padding: var(--padding-11xs) var(--padding-xl) 0 0;
}
.symbol132 {
  height: 19px;
  align-items: flex-start;
  padding: 0 var(--padding-12xs) 0 0;
  box-sizing: border-box;
  gap: var(--gap-5xl);
}
.associated-with-tinnitus,
.defective-3m-earplugs {
  margin: 0;
}
.section-heading-container {
  margin: 0;
  position: relative;
  font-size: var(--font-size-3xl);
  letter-spacing: -0.2px;
  line-height: 30px;
  font-weight: 700;
  font-family: var(--font-libre-baskerville);
  color: var(--color-darkslateblue-500);
}
.section-link1 {
  position: relative;
  letter-spacing: -0.2px;
  line-height: 17px;
  text-transform: uppercase;
  font-weight: 500;
  color: #e86500;
}
.section-link2 {
  height: 12px;
  width: 11.8px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.list-frame,
.section-link-read-more-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  color: var(--color-sandybrown);
}
.list-frame {
  /* min-width: 224px; */
    flex: 1 ;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-xl);
  min-width: 287px;
  text-align: left;
  font-size: var(--font-size-sm);
  color: var(--color-slategray-200);
  font-family: var(--font-rubik);
}
@media screen and (max-width: 1125px) {
  .list-frame {
    flex: 1;
  }
}
@media screen and (max-width: 450px) {
  .section-heading-container {
    font-size: var(--font-size-lg);
    line-height: 24px;
  }
}
