.feature-icon-3png1 {
  height: 53px;
  width: 55px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.list1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-9xs) 0 0;
}
.heading-312 {
  position: relative;
  letter-spacing: -0.2px;
  line-height: 36px;
}
.list2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-9xs) 0 0;
}
.qualified-lawyers2 {
  position: relative;
  font-size: var(--font-size-base);
  line-height: 24px;
  font-family: var(--font-rubik);
  color: var(--color-darkgray-300);
}
.divelementskit-infobox4 {
  flex: 1;
  border-right: 1px solid var(--color-slategray-400);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-2xl) 0 var(--padding-7xl);
  gap: var(--gap-2xs);
}
.divelementor-widget-container3 {
  flex: 1;
  background-color: var(--color-gray-600);
  box-shadow: 0 15px 30px rgba(4, 54, 125, 0.05);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 137px;
  text-align: center;
  font-size: var(--font-size-4xl);
  color: var(--color-darkslateblue-500);
  font-family: var(--font-libre-baskerville);
}
@media screen and (max-width: 450px) {
  .heading-312 {
    font-size: var(--font-size-lg);
    line-height: 29px;
  }
}
