.home-page-1,
.section {
  display: flex;
  justify-content: flex-start;
}
.section {
  align-self: stretch;
  overflow: hidden;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: var(--padding-56xl) 179px var(--padding-56xl) var(--padding-146xl);
  box-sizing: border-box;
  gap: var(--gap-83xl);
  background-image: url(/public/section10@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  min-height: 479px;
  max-width: 100%;
  flex-shrink: 0;
}
.home-page-1 {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  flex-direction: column;
  align-items: center;
  letter-spacing: normal;
  cursor: pointer;
}
@media screen and (max-width: 800px) {
  .section {
    gap: var(--gap-83xl);
    padding-left: var(--padding-63xl);
    padding-right: 89px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .section {
    gap: var(--gap-83xl);
    padding: var(--padding-30xl) var(--padding-xl);
    box-sizing: border-box;
  }
}
@media  screen and (min-width:780px) and (max-width:1000px) {
  footer.section {
    padding: 30px!important;
  }

}
@media  screen and (min-width:550px) and (max-width:780px) {

  .subscribe-input-button {
    margin-left: 150px;
  }
  .container{
    padding-left: 0;
  }
}