@media screen and (max-width: 450px) {i
  .form-section-section-but {
    height: 40px !important;
  }

  
}
.case-1jpg-icon {
  height: 400px;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
}
.symbol1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-3xs) var(--padding-4xs);
  box-sizing: border-box;
  max-width: 100%;
}
.irms-culture-of,
.nurture-and-develop,
.respect-as-we {
  margin: 0;
}
.irms-culture-of-container {
  align-self: stretch;
  position: relative;
  line-height: 28px;
  display: flex;
  align-items: center;
}
.section1 {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
}
.securities-law-practice {
  height: 22px;
  position: relative;
  line-height: 28px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.heading2 {
  align-self: stretch;
  height: 106px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-7xs);
  font-family: var(--font-rubik);
}
.anybody-who-suspects,
.obligation-to-report {
  margin: 0;
}
.anybody-who-suspects-container {
  align-self: stretch;
  position: relative;
  line-height: 32px;
  display: flex;
  align-items: center;
}
.button1 {
  align-self: stretch;
  height: 63px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl) var(--padding-6xs);
  box-sizing: border-box;
  font-size: var(--font-size-xl);
  color: var(--color-sandybrown);
}
.how-a-data,
.you-get-charges {
  margin: 0;
}
.heading-2-container {
  height: 63px;
  position: relative;
  font-size: var(--font-size-5xl);
  letter-spacing: -0.24px;
  line-height: 34px;
  color: var(--color-darkslateblue-500);
  display: flex;
  align-items: center;
  flex-shrink: 0;
  max-width: 100%;
}
.assisting-a-client,
.at-our-law,
.business-owners-and {
  margin: 0;
}
.at-our-law-container {
  height: 77px;
  position: relative;
  letter-spacing: -0.36px;
  line-height: 28px;
  font-family: var(--font-roboto);
  display: flex;
  align-items: center;
  flex-shrink: 0;
  max-width: 100%;
}
.links-container1,
.symbol2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.symbol2 {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-12xl);
}
.links-container1 {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-6xs) 0 0;
  box-sizing: border-box;
}
.divelementor-background-overl {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(11, 32, 81, 0.6);
}
.link-video-popup {
  height: 60px;
  width: 60px;
  position: relative;
  border-radius: 30px;
  z-index: 1;
}
.divelementor-widget-wrap3 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-67xl);
  box-sizing: border-box;
  position: relative;
  background-image: url(/public/divelementorwidgetwrap1@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  min-width: 214px;
  max-width: 100%;
}
.symbol3,
.symbol4,
.symbol5,
.symbol6,
.symbol7 {
  position: relative;
  line-height: 14px;
}
.q-a {
  align-self: stretch;
  gap: var(--gap-11xl);
}
.careers,
.practice-areas,
.q-a {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.careers {
  align-self: stretch;
  /* gap: var(--gap-25xl); */
}
.practice-areas {
  width: 13px;
  padding: 0 0 var(--padding-6xs);
  box-sizing: border-box;
}
.personal-data,
.registration-of-records {
  margin: 0;
}
.item-registration-container {
  align-self: stretch;
  position: relative;
  line-height: 28px;
  display: flex;
  align-items: center;
}
.list-item-link {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-12xs) var(--padding-10xs) 0;
}
.item-data,
.item-data1,
.item-general,
.item-video {
  position: relative;
  line-height: 28px;
}
.parent-useful-links {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  font-size: var(--font-size-lg);
  color: var(--color-slategray-200);
  font-family: var(--font-rubik);
}
.attorneys-list,
.frame-teampng {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  max-width: 100%;
}
.attorneys-list {
  height: 229px;
  padding: 0 var(--padding-12xs) 0 0;
  box-sizing: border-box;
  gap: var(--gap-mid);
  min-width: 337px;
}
.frame-teampng {
  width: 707px;
  gap: var(--gap-21xl);
  flex-shrink: 0;
}
.able-to-leverage,
.clients-to-grow,
.opportunities-and-relationship {
  margin: 0;
}
.opportunities-and-relationship-container {
  height: 77px;
  position: relative;
  letter-spacing: -0.36px;
  line-height: 28px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.heading-21 {
  position: relative;
  font-size: var(--font-size-5xl);
  letter-spacing: -0.4px;
  line-height: 34px;
  color: var(--color-darkslateblue-500);
  display: inline-block;
  max-width: 100%;
}
.team-4png-icon {
  width: 216.6px;
  height: 216.6px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.heading-35,
.litigation-associate {
  position: relative;
  letter-spacing: -0.2px;
  line-height: 28px;
}
.litigation-associate {
  font-size: var(--font-size-sm);
  line-height: 26px;
  font-family: var(--font-rubik);
  color: var(--color-darkslategray);
}
.heading-3-link-nathan-morr-parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.divelementor-widget-wrap4,
.frame-nathan-morris {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;
}
.divelementor-widget-wrap4 {
  align-self: stretch;
  align-items: center;
  gap: var(--gap-2xl);
}
.frame-nathan-morris {
  align-items: flex-start;
}
.associate-senior,
.input-email,
.section2 {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
}
.section2 {
  display: grid;
  flex-direction: row;
  padding: 0 var(--padding-12xs) 0 0;
  grid-template-columns: repeat(3, minmax(185px, 1fr));
  text-align: center;
  color: var(--color-darkslateblue-400);
  font-family: var(--font-libre-baskerville);
}
.associate-senior,
.input-email {
  display: flex;
  flex-direction: column;
  max-width: 100%;
}
.input-email {
  gap: var(--gap-10xl);
  flex-shrink: 0;
  font-size: var(--font-size-lg);
  color: var(--color-slategray-200);
  font-family: var(--font-roboto);
}
.associate-senior {
  padding: 0 0 var(--padding-9xs);
  box-sizing: border-box;
  gap: var(--gap-22xl);
  font-size: var(--font-size-sm);
  color: var(--color-sandybrown);
  font-family: var(--font-font-awesome-5-free);
}
.divekit-accordion-icon-left-g {
  height: 16px;
  width: 42px;
  position: relative;
}
.how-many-applications {
  position: relative;
  line-height: 25px;
  display: inline-block;
  max-width: 100%;
}
.link {
  align-self: stretch;
  background-color: #e3e3e3;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-2xs) var(--padding-base) var(--padding-lg);
  box-sizing: border-box;
  row-gap: 20px;
  max-width: 100%;
  text-decoration: none;
}
.our-securities-lawyers,
.securities-attorneys-understan,
.that-any-disclosure {
  margin: 0;
}
.our-securities-lawyers-container {
  align-self: stretch;
  position: relative;
  line-height: 24px;
  display: flex;
  align-items: center;
}
.divelementskit-card,
.our-securities-lawyers-can-bet-wrapper {
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.our-securities-lawyers-can-bet-wrapper {
  height: 67px;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 var(--padding-20xl) 0 0;
  font-size: var(--font-size-base);
  font-family: var(--font-rubik);
}
.divelementskit-card {
  align-self: stretch;
  border-bottom: 1px solid var(--color-whitesmoke-300);
  flex-direction: column;
  align-items: center;
  padding: 0 0 var(--padding-15xl);
  gap: var(--gap-13xl);
}
.link-icon {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.link-when {
  position: relative;
  line-height: 25px;
}
.divelementskit-card1 {
  align-self: stretch;
  border-bottom: 1px solid var(--color-whitesmoke-300);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-2xs) var(--padding-base) var(--padding-lg);
  gap: var(--gap-7xl);
}
.link-icon1 {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.link-what {
  position: relative;
  line-height: 25px;
}
.divelementskit-card2 {
  align-self: stretch;
  border-bottom: 1px solid var(--color-whitesmoke-300);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-2xs) var(--padding-base) var(--padding-lg);
  gap: var(--gap-7xl);
}
.divelementskit-card-parent {
  align-self: stretch;
  align-items: flex-start;
  gap: var(--gap-3xs);
  font-size: var(--font-size-xl);
  color: var(--color-darkslategray);
}
.button,
.divelementskit-card-parent,
.heading1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100%;
}
.button {
  align-self: stretch;
  align-items: center;
  gap: var(--gap-28xl);
}
.heading1 {
  flex: 1;
  align-items: flex-start;
  padding: var(--padding-xl) 0 0;
  box-sizing: border-box;
}
.practice-area {
  position: relative;
  letter-spacing: -0.4px;
  line-height: 33px;
}
.practice-area-list {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-21xl) 0 0;
}
.banking-finance,
.bankruptcy,
.capital-markets,
.energy-renewables,
.intellectual-property,
.medical-negligence,
.tax-consultancy {
  margin: 0;
}
.list-item-container {
  flex: 1;
  position: relative;
  font-size: var(--font-size-lg);
  line-height: 28px;
  font-family: var(--font-rubik);
  color: var(--color-sandybrown);
  display: flex;
  align-items: center;
}
.contact-us-form {
  height: 246px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.divelementor-background-overl1 {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0.1px;
  bottom: 0;
  left: -0.1px;
  background-color: rgba(11, 32, 81, 0.8);
}
.legal-issues,
.need-help-with {
  margin: 0;
}
.heading-2-container1 {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.4px;
  line-height: 36px;
  display: flex;
  align-items: center;
  z-index: 1;
}
.heading-2-need-help-with-any-wrapper {
  height: 84px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-7xl) var(--padding-lgi) 0;
  box-sizing: border-box;
  text-align: left;
  font-size: 23.8px;
  font-family: var(--font-libre-baskerville);
}
.symbol8 {
  position: absolute;
  top: 13px;
  left: calc(50% - 10px);
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20.3px;
  height: 20px;
}
.form-section-section {
  width: 52px;
  height: 51px;
  position: relative;
  background-color: var(--color-darkslateblue-100);
  display: none;
  z-index: 2;
  color: var(--color-skyblue);
}
.symbol9 {
  position: relative;
  line-height: 20px;
}
.form-section-section1 {
  background-color: var(--color-darkslateblue-100);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-smi) var(--padding-base) var(--padding-lg)
    var(--padding-mid);
  z-index: 2;
}
.full-name {
  position: relative;
  text-transform: uppercase;
}
.full-name-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-9xs) 0 0;
  text-align: left;
  font-size: var(--font-size-sm);
  color: var(--color-lightblue);
  font-family: var(--font-rubik);
}
.form-section-section-inp {
  align-self: stretch;
  background-color: var(--color-darkslateblue-400);
  border: 1px solid var(--color-darkslateblue-400);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-12xs) var(--padding-12xs);
  gap: var(--gap-mid);
  z-index: 1;
}
.email {
  position: relative;
  text-transform: uppercase;
}
.form-section-section-inp1 {
  align-self: stretch;
  background-color: var(--color-darkslateblue-400);
  border: 1px solid var(--color-darkslateblue-400);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-lgi) var(--padding-52xl) var(--padding-base);
  white-space: nowrap;
  z-index: 2;
  text-align: left;
  font-size: var(--font-size-sm);
  color: var(--color-lightblue);
  font-family: var(--font-rubik);
}
.form-section-section-inp-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-12xs);
  gap: var(--gap-3xs);
  color: var(--color-skyblue);
}
.contact-us,
.symbol10 {
  position: relative;
  line-height: 15px;
  text-transform: uppercase;
}
.contact-us {
  line-height: 18px;
  font-weight: 500;
  font-family: var(--font-rubik);
}
.form-section-section-but,
.input-form-section,
.section3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.form-section-section-but {
  background-color: var(--color-sandybrown);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.16);
  padding: var(--padding-base) var(--padding-14xl) var(--padding-base)
    var(--padding-18xl);
  gap: var(--gap-11xs);
  z-index: 1;
}


.input-form-section,
.section3 {
  align-self: stretch;
  font-size: var(--font-size-mini);
}
.section3 {
  flex-direction: column;
  align-items: center;
  padding: var(--padding-30xl) var(--padding-30xl) var(--padding-31xl);
  position: relative;
  gap: var(--gap-xl);
  background-image: url(/public/section15@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: center;
  font-size: var(--font-size-xl);
  color: var(--color-white);
  font-family: var(--font-font-awesome-5-free);
}
.team-card,
.team-card-wrapper,
.when-can-expect-hear-frame {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.when-can-expect-hear-frame {
  width: 360px;
  flex-direction: column;
  gap: var(--gap-52xl);
  min-width: 360px;
  font-size: 25.9px;
  color: var(--color-darkslateblue-500);
}
.team-card,
.team-card-wrapper {
  flex-direction: row;
  box-sizing: border-box;
}
.team-card {
  flex: 1;
  padding: 0 var(--padding-12xs) 0 0;
  gap: var(--gap-10xl);
}
.team-card-wrapper {
  width: 1180px;
  padding: 0 var(--padding-11xl) var(--padding-3xs) var(--padding-xl);
  flex-shrink: 0;
  text-align: left;
  font-size: var(--font-size-lg);
  color: var(--color-slategray-200);
  font-family: var(--font-libre-baskerville);
}
.heading-22 {
  position: relative;
  letter-spacing: -0.4px;
  line-height: 36px;
}
.improvements-in-technology,
.only-driven-by,
.practitioners-increasingly,
.the-demand-and {
  margin: 0;
}
.the-demand-and-container {
  flex: 1;
  position: relative;
  line-height: 24px;
  display: flex;
  align-items: center;
}
.item-link-facebook,
.item-link-instagram,
.item-link-linkedin,
.item-link-twitter {
  height: 35px;
  width: 35px;
  position: relative;
  border-radius: var(--br-mid-5);
  min-height: 35px;
}
.capital-markets-frame {
  flex-direction: row;
  padding: 0 var(--padding-12xs) 0 0;
  gap: var(--gap-sm);
}
.capital-markets-frame,
.capital-markets-practice-area,
.instagram-link,
.twitter-link {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.instagram-link {
  flex-direction: row;
  padding: 0 var(--padding-8xs);
}
.capital-markets-practice-area,
.twitter-link {
  flex-direction: column;
}
.twitter-link {
  flex: 1;
  gap: var(--gap-29xl);
  font-size: var(--font-size-base);
  color: var(--color-lightsteelblue-100);
  font-family: var(--font-rubik);
}
.capital-markets-practice-area {
  height: 232px;
  gap: var(--gap-3xl);
  font-size: var(--font-size-10xl-9);
}
.practice-area1 {
  position: relative;
  letter-spacing: -0.4px;
  line-height: 30px;
  text-transform: capitalize;
}
.list-item,
.symbol11 {
  position: relative;
  line-height: 10.89px;
}
.list-item {
  line-height: 19px;
}
.attorneys-list1,
.q-a-link {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.attorneys-list1 {
  flex-direction: row;
  padding: 0 0 0 var(--padding-xl);
  margin-top: -5px;
  font-size: var(--font-size-base);
  color: var(--color-gray-700);
  font-family: var(--font-rubik);
}
.q-a-link {
  flex-direction: column;
}
.list-item1,
.symbol12 {
  position: relative;
  line-height: 10.89px;
}
.list-item1 {
  line-height: 19px;
}
.list-item-link-bankruptc-wrapper,
.q-a-link1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.list-item-link-bankruptc-wrapper {
  flex-direction: row;
  padding: 0 0 0 var(--padding-xl);
  margin-top: -5px;
  font-size: var(--font-size-base);
  color: var(--color-gray-700);
  font-family: var(--font-rubik);
}
.q-a-link1 {
  flex-direction: column;
}
.list-item2,
.symbol13 {
  position: relative;
  line-height: 10.89px;
}
.list-item2 {
  line-height: 19px;
}
.list-item-link-banking-wrapper,
.q-a-link2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.list-item-link-banking-wrapper {
  flex-direction: row;
  padding: 0 0 0 var(--padding-xl);
  margin-top: -5px;
  font-size: var(--font-size-base);
  color: var(--color-gray-700);
  font-family: var(--font-rubik);
}
.q-a-link2 {
  flex-direction: column;
}
.list-item3,
.symbol14 {
  position: relative;
  line-height: 10.89px;
}

.list-item3 {
  line-height: 19px;
}
.list-item-link-tax-consu-wrapper,
.q-a-link3 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.list-item-link-tax-consu-wrapper {
  flex-direction: row;
  padding: 0 0 0 var(--padding-xl);
  margin-top: -5px;
  font-size: var(--font-size-base);
  color: var(--color-gray-700);
  font-family: var(--font-rubik);
}
.q-a-link3 {
  flex-direction: column;
}
.list-item4,
.symbol15 {
  position: relative;
  line-height: 10.89px;
}
.list-item4 {
  line-height: 19px;
}
.list-item-link-medical-n-wrapper,
.q-a-link4 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.list-item-link-medical-n-wrapper {
  flex-direction: row;
  padding: 0 0 0 var(--padding-xl);
  margin-top: -5px;
  font-size: var(--font-size-base);
  color: var(--color-gray-700);
  font-family: var(--font-rubik);
}
.q-a-link4 {
  flex-direction: column;
}
.list-item5,
.symbol16 {
  position: relative;
  line-height: 10.89px;
}
.list-item5 {
  line-height: 19px;
}
.list-item-link-energy-wrapper,
.q-a-link5 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.list-item-link-energy-wrapper {
  flex-direction: row;
  padding: 0 0 0 var(--padding-xl);
  margin-top: -5px;
  font-size: var(--font-size-base);
  color: var(--color-gray-700);
  font-family: var(--font-rubik);
}
.q-a-link5 {
  flex-direction: column;
}
.list-item6,
.symbol17 {
  position: relative;
  line-height: 10.89px;
}
.list-item6 {
  line-height: 19px;
}
.list-item-link-intellect-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 var(--padding-xl);
  margin-top: -5px;
  font-size: var(--font-size-base);
  color: var(--color-gray-700);
  font-family: var(--font-rubik);
}
.honors-awards-link,
.practice-areas-link,
.q-a-link6 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.honors-awards-link {
  gap: var(--gap-sm);
  font-size: var(--font-size-6xs);
  color: var(--color-darkkhaki);
  font-family: var(--font-font-awesome-5-free);
}
.practice-areas-link {
  height: 301px;
  padding: 0 var(--padding-9xs) 0 0;
  box-sizing: border-box;
  gap: var(--gap-3xl);
}
.heading-4 {
  position: relative;
  letter-spacing: -0.4px;
  line-height: 30px;
  text-transform: capitalize;
}
.symbol18,
.symbol19,
.symbol20,
.symbol21,
.symbol22,
.symbol23 {
  position: relative;
  line-height: 6px;
}
.symbol-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xl);
}
.list-item7,
.list-item8,
.list-item9 {
  position: relative;
  line-height: 34px;
}
.list-item8,
.list-item9 {
  z-index: 1;
}
.list-item9 {
  z-index: 2;
}
.list-item10,
.list-item11,
.list-item12 {
  position: relative;
  line-height: 34px;
  z-index: 3;
}
.list-item11,
.list-item12 {
  z-index: 4;
}
.list-item12 {
  z-index: 5;
}
.frame-group,
.useful-links-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--font-size-lg);
  color: var(--color-lightsteelblue-100);
  font-family: var(--font-roboto);
}
.frame-group {
  flex-direction: row;
  align-items: center;
  padding: 0 var(--padding-12xs) 0 0;
  gap: var(--gap-smi);
  font-size: var(--font-size-7xs);
  color: var(--color-sandybrown);
  font-family: var(--font-font-awesome-5-free);
}
.heading-4-useful-links-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-5xl) 0 0;
  gap: var(--gap-xl);
}
.heading-41 {
  position: relative;
  letter-spacing: -0.4px;
  line-height: 30px;
  text-transform: capitalize;
}
.symbol24,
.symbol25,
.symbol26 {
  position: relative;
  line-height: 18px;
}
.list-practice-areas,
.logo-and-info-frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-lgi);
}
.logo-and-info-frame {
  gap: var(--gap-11xl);
}
.little-william-st,
.vic-3000-australia {
  margin: 0;
}
.list-item-container1,
.list-item13,
.list-item14 {
  position: relative;
  letter-spacing: -0.2px;
  line-height: 22px;
}
.list-item14 {
  white-space: nowrap;
}
.frame-form,
.frame-location {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  font-size: var(--font-size-base);
  color: var(--color-white);
  font-family: var(--font-roboto);
}
.frame-form {
  flex-direction: row;
  align-items: flex-end;
  padding: 0 var(--padding-12xs) var(--padding-9xs) 0;
  gap: var(--gap-mid);
  font-size: var(--font-size-lg);
  color: var(--color-sandybrown);
  font-family: var(--font-font-awesome-5-free);
}
.heading-42 {
  font-size: var(--font-size-2xl-8);
  letter-spacing: -0.4px;
  line-height: 30px;
  text-transform: capitalize;
}
.email1,
.heading-42 {
  position: relative;
}
.input {
  position: absolute;
  top: -0.4px;
  left: 0;
  background-color: var(--color-gray-100);
  border: 1px solid var(--color-whitesmoke-400);
  box-sizing: border-box;
  width: 226px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-base) var(--padding-base) var(--padding-mini);
  white-space: nowrap;
}
.symbol27 {
  position: relative;
  line-height: 15px;
}
.button2 {
  position: absolute;
  top: 0.4px;
  left: 190px;
  background-color: var(--color-sandybrown);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-mini) var(--padding-lgi) var(--padding-mid)
    var(--padding-xl);
  z-index: 1;
  text-align: center;
  font-size: var(--font-size-mini);
  color: var(--color-white);
  font-family: var(--font-font-awesome-5-free);
}
.email-button-input {
  align-self: stretch;
  height: 47px;
  position: relative;
  font-size: var(--font-size-sm);
  color: var(--color-lightsteelblue-300);
  font-family: var(--font-roboto);
}
.heading-useful-links,
.section4 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.heading-useful-links {
  width: 239px;
  flex-direction: column;
  gap: var(--gap-2xl);
  font-size: var(--font-size-3xl);
}
.section4 {
  overflow: hidden;
  flex-direction: row;
  padding: var(--padding-56xl) var(--padding-163xl) var(--padding-83xl)
    var(--padding-146xl);
  gap: var(--gap-57xl);
  background-image: url(/public/section16@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.link-footer-logopng {
  height: 52px;
  width: 170px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.list-item15,
.symbol28 {
  position: relative;
  letter-spacing: -0.2px;
  line-height: 6px;
}
.list-item15 {
  font-size: var(--font-size-base);
  line-height: 19px;
  font-family: var(--font-roboto);
  color: var(--color-white);
  text-align: center;
}
.symbol-list-privacy {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-12xs) 0 0;
  gap: var(--gap-smi);
}
.list-item16,
.symbol29 {
  position: relative;
  letter-spacing: -0.2px;
  line-height: 6px;
}
.list-item16 {
  font-size: var(--font-size-base);
  line-height: 19px;
  font-family: var(--font-roboto);
  color: var(--color-white);
  text-align: center;
}
.symbol-list-privacy1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-12xs) 0 0;
  gap: var(--gap-smi);
}
.list-item17,
.symbol30 {
  position: relative;
  letter-spacing: -0.2px;
  line-height: 6px;
}
.list-item17 {
  font-size: var(--font-size-base);
  line-height: 19px;
  font-family: var(--font-roboto);
  color: var(--color-white);
  text-align: center;
}
.symbol-list-privacy2,
.terms-of-conditions-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-smi);
}
.terms-of-conditions-link {
  flex: 1;
  align-items: flex-start;
  padding: 0 var(--padding-12xs) 0 0;
  box-sizing: border-box;
  gap: var(--gap-15xl);
  max-width: 100%;
}
.privsa {
  color: var(--color-sandybrown);
}
.privsa-all-rights-container {
  position: relative;
  font-size: var(--font-size-base);
  letter-spacing: -0.2px;
  line-height: 19px;
  font-family: var(--font-rubik);
  text-align: right;
  flex-shrink: 0;
  color: var(--color-white);
}
.legal-info-link1,
.section5 {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  max-width: 100%;
}
.legal-info-link1 {
  width: 802px;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-12xs) 0 0;
  gap: var(--gap-87xl);
  min-height: 20px;
}
.section5 {
  align-self: stretch;
  background-color: var(--color-darkslateblue-400);
  overflow: hidden;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-xl) var(--padding-148xl) var(--padding-xl)
    var(--padding-141xl);
  gap: var(--gap-xl);
  z-index: 6;
  font-size: var(--font-size-7xs);
  color: var(--color-sandybrown);
  font-family: var(--font-font-awesome-5-free);
}
.honors-awards-list,
.our-services-detail-page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.honors-awards-list {
  align-self: stretch;
  align-items: flex-start;
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-2xl-8);
  color: var(--color-white);
  font-family: var(--font-libre-baskerville);
}
.our-services-detail-page {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  align-items: center;
  padding: 0;
  box-sizing: border-box;
  gap: var(--gap-81xl);
  letter-spacing: normal;
}
@media screen and (max-width: 1350px) {
  .section4,
  .section5 {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1125px) {
  .when-can-expect-hear-frame {
    flex: 1;
  }
  .team-card {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 800px) {
  .symbol2 {
    gap: var(--gap-12xl);
  }
  .attorneys-list {
    flex: 1;
    padding-right: 0;
    box-sizing: border-box;
  }
  .frame-teampng {
    flex-wrap: wrap;
    gap: var(--gap-21xl);
  }
  .section2 {
    grid-row-gap: 20px;
    justify-content: center;
    grid-template-columns: repeat(2, minmax(185px, 321px));
  }
  .associate-senior {
    gap: var(--gap-22xl);
  }
  .link {
    flex-wrap: wrap;
  }
  .divelementskit-card {
    gap: var(--gap-13xl);
  }
  .button {
    gap: var(--gap-28xl);
  }
  .heading1 {
    min-width: 100%;
  }
  .section4 {
    gap: var(--gap-57xl);
    padding: var(--padding-30xl) var(--padding-72xl) var(--padding-47xl)
      var(--padding-63xl);
    box-sizing: border-box;
  }
  .terms-of-conditions-link {
    flex-wrap: wrap;
  }
  .legal-info-link1 {
    flex-wrap: wrap;
    gap: var(--gap-87xl);
  }
  .section5 {
    padding-left: var(--padding-61xl);
    padding-right: var(--padding-64xl);
    box-sizing: border-box;
  }
  .our-services-detail-page {
    gap: var(--gap-81xl);
  }
}
@media screen and (max-width: 450px) {
  .anybody-who-suspects-container {
    font-size: var(--font-size-base);
    line-height: 26px;
  }
  .heading-2-container {
    font-size: var(--font-size-lgi);
    line-height: 27px;
  }
  .attorneys-list {
    min-width: 100%;
  }
  .heading-21 {
    font-size: var(--font-size-lgi);
    line-height: 27px;
  }
  .section2 {
    grid-template-columns: minmax(185px, 1fr);
  }
  .how-many-applications,
  .link-what,
  .link-when {
    font-size: var(--font-size-base);
    line-height: 20px;
  }
  .practice-area {
    font-size: var(--font-size-2xl);
    line-height: 26px;
  }
  .heading-2-container1 {
    font-size: var(--font-size-lgi);
    line-height: 29px;
  }
  .symbol8,
  .symbol9 {
    font-size: var(--font-size-base);
    line-height: 16px;
  }
  .section3 {
    padding: var(--padding-13xl) var(--padding-xl);
    box-sizing: border-box;
  }
  .when-can-expect-hear-frame {
    gap: var(--gap-52xl);
    min-width: 100%;
  }
  .heading-22 {
    font-size: var(--font-size-5xl);
    line-height: 29px;
  }
  .twitter-link {
    gap: var(--gap-29xl);
  }
  .heading-4,
  .practice-area1 {
    font-size: var(--font-size-mid);
    line-height: 24px;
  }
  .heading-41,
  .heading-42 {
    font-size: var(--font-size-lg);
    line-height: 24px;
  }
  .heading-42 {
    font-size: var(--font-size-mid);
  }
  .section4 {
    gap: var(--gap-57xl);
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
  .terms-of-conditions-link {
    gap: var(--gap-15xl);
  }
  .legal-info-link1 {
    gap: var(--gap-87xl);
  }
  .section5 {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
  .our-services-detail-page {
    gap: var(--gap-81xl);
  }
}
