.symbol160 {
  position: relative;
  line-height: 24px;
}
.divelementskit-info-box-icon {
  border-radius: var(--br-31xl);
  background-color: var(--color-darkslateblue-200);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-4xl) var(--padding-3xl) var(--padding-9xl)
    var(--padding-4xl);
}
.heading-324 {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.2px;
  line-height: 28px;
  font-weight: 700;
  font-family: inherit;
}
.bills-so-we,
.nobody-likes-surprises {
  margin: 0;
}
.nobody-likes-surprises-container {
  height: 45px;
  position: relative;
  font-size: var(--font-size-base);
  letter-spacing: -0.2px;
  line-height: 26px;
  font-family: var(--font-rubik);
  color: var(--color-lightsteelblue-200);
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.divelementskit-infobox7,
.text-color {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.text-color {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-xs);
  min-width: 144px;
  text-align: left;
  font-size: var(--font-size-4xl);
  font-family: var(--font-libre-baskerville);
}
.divelementskit-infobox7 {
  align-self: stretch;
  border-radius: var(--br-8xs);
  overflow: hidden;
  flex-direction: row;
  padding: 0 var(--padding-7xl) var(--padding-sm) 0;
  gap: var(--gap-11xl);
}
.divelementskit-box-header-icon {
  height: 75px;
  width: 105px;
  position: relative;
}
.heading-325 {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.2px;
  line-height: 28px;
  font-weight: 700;
  font-family: inherit;
}
.we-take-the,
.you-exactly-what {
  margin: 0;
}
.we-take-the-container {
  flex: 1;
  position: relative;
  font-size: var(--font-size-base);
  letter-spacing: -0.2px;
  line-height: 26px;
  font-family: var(--font-rubik);
  color: var(--color-lightsteelblue-200);
  display: flex;
  align-items: center;
}
.column-layout,
.divelementskit-infobox8,
.menu-separator {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.menu-separator {
  align-self: stretch;
  flex-direction: column;
  gap: var(--gap-xs);
}
.column-layout,
.divelementskit-infobox8 {
  max-width: 100%;
}
.divelementskit-infobox8 {
  height: 99px;
  border-radius: var(--br-8xs);
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: row;
  padding: 0 0 var(--padding-sm);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--font-size-4xl);
  font-family: var(--font-libre-baskerville);
}
.column-layout {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-xl);
  min-width: 265px;
  text-align: center;
  font-size: var(--font-size-5xl);
  color: var(--color-white);
  font-family: var(--font-font-awesome-5-free);
}
@media screen and (max-width: 450px) {
  .symbol160 {
    font-size: var(--font-size-lgi);
    line-height: 41px;
  }
  .heading-324 {
    font-size: var(--font-size-lg);
    line-height: 22px;
  }
  .divelementskit-infobox7 {
    flex-wrap: wrap;
    gap: var(--gap-11xl);
  }
  .heading-325 {
    font-size: var(--font-size-lg);
    line-height: 22px;
  }
}

@media screen and (max-width:600px) {
  .column-layout h3{
    font-size: 19px !important;
  }
  .nobody-likes-surprises-container h3 {
    margin-top: 100px !important;
  }
  .nobody-likes-surprises-container p {
    padding-top: 10px; 
  }

  .column-layout p {
    font-size: 15px !important;
    margin-top: 10px !important;
  }
}
