.heading-210,
.heading-326 {
  position: relative;
  letter-spacing: -0.4px;
  line-height: 22px;
  font-weight: 500;
  color: #e86500;
}
.heading-210 {
  margin: 0;
  font-size: var(--font-size-26xl);
  letter-spacing: -0.2px;
  line-height: 56px;
  font-weight: 700;
  font-family: var(--font-libre-baskerville);
  color: var(--color-white);
}
.icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xl);
  max-width: 100%;
}
.divelementskit-box-header-icon1 {
  height: 75px;
  width: 105px;
  position: relative;
}
.heading-327 {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.2px;
  line-height: 28px;
  font-weight: 700;
  font-family: inherit;
}
.we-take-the1,
.you-exactly-what1 {
  margin: 0;
}
.we-take-the-container1 {
  height: 45px;
  position: relative;
  font-size: var(--font-size-base);
  letter-spacing: -0.2px;
  line-height: 26px;
  font-family: var(--font-rubik);
  color: var(--color-lightsteelblue-200);
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.divelementskit-infobox9,
.dropdown-link {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.dropdown-link {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-xs);
  min-width: 155px;
}
.divelementskit-infobox9 {
  align-self: stretch;
  border-radius: var(--br-8xs);
  overflow: hidden;
  flex-direction: row;
  padding: 0 0 var(--padding-sm);
  row-gap: 20px;
}
.symbol161 {
  position: relative;
  line-height: 24px;
}
.divelementskit-info-box-icon1 {
  width: 75px;
  border-radius: var(--br-31xl);
  background-color: var(--color-darkslateblue-200);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-4xl) var(--padding-4xl) var(--padding-9xl);
  box-sizing: border-box;
}
.heading-328 {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.2px;
  line-height: 28px;
  font-weight: 700;
  font-family: inherit;
}
.likes-surprises-on,
.we-take-the2 {
  margin: 0;
}
.likes-surprises-on-container {
  height: 45px;
  position: relative;
  font-size: var(--font-size-base);
  letter-spacing: -0.2px;
  line-height: 26px;
  font-family: var(--font-rubik);
  color: var(--color-lightsteelblue-200);
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.breadcrumbs,
.divelementskit-infobox10 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.breadcrumbs {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-xs);
  min-width: 157px;
  text-align: left;
  font-size: var(--font-size-4xl);
  font-family: var(--font-libre-baskerville);
}
.divelementskit-infobox10 {
  align-self: stretch;
  border-radius: var(--br-8xs);
  overflow: hidden;
  flex-direction: row;
  padding: 0 var(--padding-12xs) var(--padding-sm) 0;
  gap: var(--gap-11xl);
  text-align: center;
  font-size: var(--font-size-5xl);
  font-family: var(--font-font-awesome-5-free);
}
.burger-menu {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-xl);
  min-width: 261px;
  text-align: left;
  font-size: var(--font-size-4xl);
  font-family: var(--font-libre-baskerville);
}
.burger-menu,
.image-gallery,
.input-field,
.main-section3 {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.image-gallery {
  width: 1095px;
  flex-direction: row;
  align-items: flex-start;
  gap: var(--gap-2xl);
  font-size: var(--font-size-5xl);
  color: var(--color-white);
  font-family: var(--font-font-awesome-5-free);
}
.input-field,
.main-section3 {
  box-sizing: border-box;
}
.main-section3 {
  /* padding: 10px 0px; */
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: var(--padding-81xl) var(--padding-6xl) var(--padding-91xl)
    var(--padding-xl);
  gap: var(--gap-31xl);
  background-image: url(/public/main--section@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.input-field {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px 0;
  flex-shrink: 0;
  text-align: center;
  font-size: var(--font-size-lg);
  color: var(--color-sandybrown);
  font-family: var(--font-rubik);
}
@media screen and (max-width: 1125px) {
  .image-gallery {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 800px) {
  .heading-210 {
    font-size: var(--font-size-17xl);
    line-height: 45px;
  }
  .main-section3 {
    gap: var(--gap-31xl);
    padding-top: var(--padding-46xl);
    padding-bottom: var(--padding-52xl);
    box-sizing: border-box;
    
  }
  .input-field {
    padding-bottom: var(--padding-46xl);
    box-sizing: border-box;
  }
}

@media screen and (max-width: 450px) {
  .dropdown-link h3{
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .breadcrumbs h3 {
    margin-top: 10px;
    
  }
  .heading-210 {
    font-size: var(--font-size-8xl);
    line-height: 34px;
  }
  .heading-327 {
    font-size: var(--font-size-lg);
    line-height: 22px;
  }
  .divelementskit-infobox9 {
    flex-wrap: wrap;
  }
  .symbol161 {
    font-size: var(--font-size-lgi);
    line-height: 41px;
  }
  .heading-328 {
    font-size: var(--font-size-lg);
    line-height: 22px;
  }
  .divelementskit-infobox10 {
    flex-wrap: wrap;
  }
}
