.team-6png-icon1 {
  width: 216.6px;
  height: 216.6px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  max-width: 88%;
}
.heading-335,
.litigation-associate2 {
  position: relative;
  letter-spacing: -0.2px;
  line-height: 28px;
}
.litigation-associate2 {
  font-size: var(--font-size-sm);
  line-height: 26px;
  font-family: var(--font-rubik);
  color: var(--color-darkslategray);
}
.divelementor-widget-wrap7,
.frame-d-stewart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.frame-d-stewart {
  gap: var(--gap-9xs);
  max-width: 53%;
}
.divelementor-widget-wrap7 {
  padding: 0;
  gap: var(--gap-2xl);
  text-align: center;
  font-size: var(--font-size-lg);
  color: var(--color-darkslateblue-400);
  font-family: var(--font-libre-baskerville);
}
