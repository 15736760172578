.heading-313 {
  position: relative;
  letter-spacing: -0.4px;
  line-height: 22px;
  font-weight: 500;
  color: #e86500;
}
.privsa-attorneys,
.why-should-you {
  margin: 0;
}
.heading-2-container2 {
  margin: 0;
  position: relative;
  font-size: var(--font-size-26xl);
  letter-spacing: -0.2px;
  line-height: 56px;
  font-weight: 700;
  font-family: var(--font-libre-baskerville);
  color: var(--color-darkslateblue-500);
}
.heading-3-why-us-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.link-you {
  position: relative;
  letter-spacing: -0.2px;
  line-height: 24px;
}
.link-icon6 {
  height: 20px;
  width: 19.8px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.collapse {
  display: none;
}

.collapsed + .collapse {
  display: none;
}

.collapsed {
  background-color: #f0f0f0;
}
.link-you-deserve-time-to-rec-parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.down-it-might,
.even-then-you,
.getting-into-an {
  margin: 0;
}
.getting-into-an-container {
  height: 71px;
  position: relative;
  font-size: var(--font-size-base);
  letter-spacing: -0.2px;
  line-height: 26px;
  font-family: var(--font-rubik);
  color: var(--color-slategray-200);
  display: flex;
  align-items: center;
  flex-shrink: 0;
  max-width: 100%;
}
.divelementskit-card3 {
  width: 520px;
  border-bottom: 1px solid var(--color-lightgray-300);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-base) var(--padding-12xs) var(--padding-11xl) 0;
  gap: var(--gap-lgi);
}
.link-dont {
  position: relative;
  letter-spacing: -0.2px;
  line-height: 24px;
}
.link-icon7 {
  height: 16px;
  width: 15.8px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.divelementskit-card4 {
  width: 521px;
  border-bottom: 1px solid var(--color-lightgray-300);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-base) 0 var(--padding-mid) var(--padding-12xs);
  gap: var(--gap-xl);
}
.link-damages {
  position: relative;
  letter-spacing: -0.2px;
  line-height: 24px;
}
.link-icon8 {
  height: 16px;
  width: 15.8px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.divelementskit-card5 {
  width: 521px;
  border-bottom: 1px solid var(--color-lightgray-300);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-base) 0 var(--padding-mid) var(--padding-12xs);
  gap: var(--gap-38xl);
}
.link-experience {
  position: relative;
  letter-spacing: -0.2px;
  line-height: 24px;
}
.link-icon9 {
  height: 16px;
  width: 15.8px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.divelementskit-card6 {
  width: 521px;
  border-bottom: 1px solid var(--color-lightgray-300);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-base) 0 var(--padding-mid) var(--padding-12xs);
  gap: var(--gap-xl);
}
.divelementor-widget-container4,
.section22 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}
.divelementor-widget-container4 {
  width: 521px;
  border-top: 1px solid var(--color-lightgray-300);
  overflow-x: auto;
  padding: var(--padding-12xs) 0 0;
  gap: var(--gap-3xs);
  font-size: var(--font-size-xl);
  color: var(--color-darkslateblue-500);
  font-family: var(--font-libre-baskerville);
}
.section22 {
  padding: 0 var(--padding-12xs) 0 0;
  gap: var(--gap-21xl);
  min-width: 521px;
}
.section-icon {
  align-self: stretch;
  height: 375px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
}
.divelementor-container {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  padding: var(--padding-3xs) 0;
  gap: var(--gap-12xs);
}
.heading3,
.section21 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}
.heading3 {
  flex: 1;
  flex-direction: column;
  padding: 0 var(--padding-11xs) 0 0;
  gap: var(--gap-xl);
  min-width: 359px;
  text-align: center;
  font-size: var(--font-size-4xl);
  color: var(--color-darkslateblue-500);
  font-family: var(--font-libre-baskerville);
}
.section21 {
  align-self: stretch;
  flex-direction: row;
  padding: var(--padding-76xl) var(--padding-138xl) var(--padding-71xl)
    var(--padding-141xl);
  gap: var(--gap-31xl);
  background-image: url(/public/section6@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  flex-shrink: 0;
  text-align: left;
  font-size: var(--font-size-lg);
  color: var(--color-sandybrown);
  font-family: var(--font-rubik);
}
@media screen and (max-width: 1325px) {
  .section22 {
    flex: 1;
    padding-right: 0;
    box-sizing: border-box;
  }
  .section21 {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1125px) {
  .section22 {
    min-width: 100%;
  }
}
@media screen and (max-width: 800px) {
  .heading-2-container2 {
    font-size: var(--font-size-17xl);
    line-height: 45px;
  }
  .section22 {
    gap: var(--gap-21xl);
  }
  .divelementor-container {
    flex-wrap: wrap;
  }
  .heading3 {
    min-width: 100%;
  }
  .section21 {
    gap: var(--gap-31xl);
    padding: var(--padding-43xl) var(--padding-59xl) var(--padding-39xl)
      var(--padding-61xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .heading-2-container2 {
    font-size: var(--font-size-8xl);
    line-height: 34px;
  }
  .link-damages,
  .link-dont,
  .link-experience,
  .link-you {
    font-size: var(--font-size-base);
    line-height: 19px;
  }
  .section21 {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
}
