.heading-26 {
  position: relative;
  letter-spacing: -0.4px;
  line-height: 36px;
}
.improvements-in-technology4,
.only-driven-by4,
.practitioners-increasingly4,
.the-demand-and4 {
  margin: 0;
}
.the-demand-and-container4 {
  flex: 1;
  position: relative;
  line-height: 24px;
  display: flex;
  align-items: center;
}
.item-link-facebook4,
.item-link-instagram4,
.item-link-linkedin4,
.item-link-twitter4 {
  height: 35px;
  width: 35px;
  position: relative;
  border-radius: var(--br-mid-5);
  min-height: 35px;
}
.symbol-list-legal-info,
.symbol-list-privacy-policy {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.symbol-list-legal-info {
  padding: 0 var(--padding-12xs) 0 0;
  gap: var(--gap-sm);
}
.symbol-list-privacy-policy {
  padding: 0 var(--padding-8xs);
}
.full-name-container,
.list-items-capital-markets {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.full-name-container {
  flex: 1;
  gap: var(--gap-29xl);
  font-size: var(--font-size-base);
  color: var(--color-lightsteelblue-100);
  font-family: var(--font-rubik);
}
.list-items-capital-markets {
  height: 232px;
  gap: var(--gap-3xl);
  font-size: var(--font-size-10xl-9);
}
.practice-area5 {
  position: relative;
  letter-spacing: -0.4px;
  line-height: 30px;
  text-transform: capitalize;
}
.list-item69,
.symbol88 {
  position: relative;
  line-height: 10.89px;
}
.list-item69 {
  line-height: 19px;
}
.facebook-link,
.linked-in-link {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.linked-in-link {
  flex-direction: row;
  padding: 0 0 0 var(--padding-xl);
  margin-top: -5px;
  font-size: var(--font-size-base);
  color: var(--color-gray-700);
  font-family: var(--font-rubik);
}
.facebook-link {
  flex-direction: column;
}
.list-item70,
.symbol89 {
  position: relative;
  line-height: 10.89px;
}
.list-item70 {
  line-height: 19px;
}
.facebook-link1,
.list-item-link-bankruptc-wrapper1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.list-item-link-bankruptc-wrapper1 {
  flex-direction: row;
  padding: 0 0 0 var(--padding-xl);
  margin-top: -5px;
  font-size: var(--font-size-base);
  color: var(--color-gray-700);
  font-family: var(--font-rubik);
}
.facebook-link1 {
  flex-direction: column;
}
.list-item71,
.symbol90 {
  position: relative;
  line-height: 10.89px;
}
.list-item71 {
  line-height: 19px;
}
.facebook-link2,
.list-item-link-banking-wrapper2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.list-item-link-banking-wrapper2 {
  flex-direction: row;
  padding: 0 0 0 var(--padding-xl);
  margin-top: -5px;
  font-size: var(--font-size-base);
  color: var(--color-gray-700);
  font-family: var(--font-rubik);
}
.facebook-link2 {
  flex-direction: column;
}
.list-item72,
.symbol91 {
  position: relative;
  line-height: 10.89px;
}
.list-item72 {
  line-height: 19px;
}
.facebook-link3,
.list-item-link-tax-consu-wrapper2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.list-item-link-tax-consu-wrapper2 {
  flex-direction: row;
  padding: 0 0 0 var(--padding-xl);
  margin-top: -5px;
  font-size: var(--font-size-base);
  color: var(--color-gray-700);
  font-family: var(--font-rubik);
}
.facebook-link3 {
  flex-direction: column;
}
.list-item73,
.symbol92 {
  position: relative;
  line-height: 10.89px;
}
.list-item73 {
  line-height: 19px;
}
.facebook-link4,
.list-item-link-medical-n-wrapper2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.list-item-link-medical-n-wrapper2 {
  flex-direction: row;
  padding: 0 0 0 var(--padding-xl);
  margin-top: -5px;
  font-size: var(--font-size-base);
  color: var(--color-gray-700);
  font-family: var(--font-rubik);
}
.facebook-link4 {
  flex-direction: column;
}
.list-item74,
.symbol93 {
  position: relative;
  line-height: 10.89px;
}
.list-item74 {
  line-height: 19px;
}
.facebook-link5,
.list-item-link-energy-wrapper2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.list-item-link-energy-wrapper2 {
  flex-direction: row;
  padding: 0 0 0 var(--padding-xl);
  margin-top: -5px;
  font-size: var(--font-size-base);
  color: var(--color-gray-700);
  font-family: var(--font-rubik);
}
.facebook-link5 {
  flex-direction: column;
}
.list-item75,
.symbol94 {
  position: relative;
  line-height: 10.89px;
}
.list-item75 {
  line-height: 19px;
}
.list-item-link-intellect-wrapper2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 var(--padding-xl);
  margin-top: -5px;
  font-size: var(--font-size-base);
  color: var(--color-gray-700);
  font-family: var(--font-rubik);
}
.capital-markets-list1,
.facebook-link6,
.privsa-all-rights-reserved {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.capital-markets-list1 {
  gap: var(--gap-sm);
  font-size: var(--font-size-6xs);
  color: var(--color-darkkhaki);
  font-family: var(--font-font-awesome-5-free);
}
.privsa-all-rights-reserved {
  height: 301px;
  padding: 0 var(--padding-9xs) 0 0;
  box-sizing: border-box;
  gap: var(--gap-3xl);
}
.heading-412 {
  position: relative;
  letter-spacing: -0.4px;
  line-height: 30px;
  text-transform: capitalize;
}
.symbol100,
.symbol95,
.symbol96,
.symbol97,
.symbol98,
.symbol99 {
  position: relative;
  line-height: 6px;
}
.list-symbols-frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xl);
}
.list-item76,
.list-item77,
.list-item78 {
  position: relative;
  line-height: 34px;
}
.list-item77,
.list-item78 {
  z-index: 1;
}
.list-item78 {
  z-index: 2;
}
.list-item79,
.list-item80,
.list-item81 {
  position: relative;
  line-height: 34px;
  z-index: 3;
}
.list-item80,
.list-item81 {
  z-index: 4;
}
.list-item81 {
  z-index: 5;
}
.capital-markets-frame1,
.twitter-symbol {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--font-size-lg);
  color: var(--color-lightsteelblue-100);
  font-family: var(--font-roboto);
}
.capital-markets-frame1 {
  flex-direction: row;
  align-items: center;
  padding: 0 var(--padding-12xs) 0 0;
  gap: var(--gap-smi);
  font-size: var(--font-size-7xs);
  color: var(--color-sandybrown);
  font-family: var(--font-font-awesome-5-free);
}
.practice-area6 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-5xl) 0 0;
  gap: var(--gap-xl);
}
.heading-413 {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.4px;
  line-height: 30px;
  text-transform: capitalize;
  font-weight: 700;
  font-family: inherit;
}
.symbol101,
.symbol102,
.symbol103 {
  position: relative;
  line-height: 18px;
}
.address-frame,
.symbol-parent12 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-lgi);
}
.address-frame {
  gap: var(--gap-11xl);
}
.little-william-st4,
.vic-3000-australia4 {
  margin: 0;
}
.list-item-container5,
.list-item82,
.list-item83 {
  position: relative;
  letter-spacing: -0.2px;
  line-height: 22px;
}
.list-item83 {
  white-space: nowrap;
}
.career-list,
.subhead-contact-us {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  font-size: var(--font-size-base);
  color: var(--color-white);
  font-family: var(--font-roboto);
}
.subhead-contact-us {
  flex-direction: row;
  align-items: flex-end;
  padding: 0 var(--padding-12xs) var(--padding-9xs) 0;
  gap: var(--gap-mid);
  font-size: var(--font-size-lg);
  color: var(--color-sandybrown);
  font-family: var(--font-font-awesome-5-free);
}
.heading-414 {
  font-size: var(--font-size-2xl-8);
  letter-spacing: -0.4px;
  line-height: 30px;
  text-transform: capitalize;
}
.email5,
.heading-414 {
  position: relative;
}
.input4 {
  flex: 1;
  background-color: var(--color-gray-100);
  border: 1px solid var(--color-whitesmoke-400);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-base) var(--padding-base) var(--padding-mini);
  white-space: nowrap;
}
.symbol104 {
  position: relative;
  line-height: 15px;
}
.button6 {
  background-color: var(--color-sandybrown);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-mini) var(--padding-lgi) var(--padding-mid)
    var(--padding-xl);
  z-index: 1;
  margin-left: -36px;
  text-align: center;
  font-size: var(--font-size-mini);
  color: var(--color-white);
  font-family: var(--font-font-awesome-5-free);
}
.input-email-button {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-12xs) 0 0;
  font-size: var(--font-size-sm);
  color: var(--color-lightsteelblue-300);
  font-family: var(--font-roboto);
}
.input-email-button,
.instagram-symbol,
.section11 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.instagram-symbol {
  width: 239px;
  flex-direction: column;
  gap: var(--gap-2xl);
  font-size: var(--font-size-3xl);
}
.section11 {
  overflow: hidden;
  flex-direction: row;
  padding: var(--padding-56xl) var(--padding-163xl) var(--padding-83xl)
    var(--padding-146xl);
  gap: var(--gap-57xl);
  background-image: url(/public/section14@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.link-footer-logopng3 {
  height: 52px;
  width: 170px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.list-item84,
.symbol105 {
  position: relative;
  letter-spacing: -0.2px;
  line-height: 6px;
}
.list-item84 {
  font-size: var(--font-size-base);
  line-height: 19px;
  font-family: var(--font-roboto);
  color: var(--color-white);
  text-align: center;
}
.list-item-link1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-12xs) 0 0;
  gap: var(--gap-smi);
}
.list-item85,
.symbol106 {
  position: relative;
  letter-spacing: -0.2px;
  line-height: 6px;
}
.list-item85 {
  font-size: var(--font-size-base);
  line-height: 19px;
  font-family: var(--font-roboto);
  color: var(--color-white);
  text-align: center;
}
.list-item-link2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-12xs) 0 0;
  gap: var(--gap-smi);
}
.list-item86,
.symbol107 {
  position: relative;
  letter-spacing: -0.2px;
  line-height: 6px;
}
.list-item86 {
  font-size: var(--font-size-base);
  line-height: 19px;
  font-family: var(--font-roboto);
  color: var(--color-white);
  text-align: center;
}
.frame-legal-info,
.list-item-link3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-smi);
}
.frame-legal-info {
  flex: 1;
  align-items: flex-start;
  padding: 0 var(--padding-12xs) 0 0;
  box-sizing: border-box;
  gap: var(--gap-15xl);
  max-width: 100%;
}
.privsa3 {
  color: var(--color-sandybrown);
}
.privsa-all-rights-container3 {
  position: relative;
  font-size: var(--font-size-base);
  letter-spacing: -0.2px;
  line-height: 19px;
  font-family: var(--font-rubik);
  text-align: right;
  flex-shrink: 0;
  color: var(--color-white);
}
.frame-privacy-policy,
.section12 {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  max-width: 100%;
}
.frame-privacy-policy {
  width: 802px;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-12xs) 0 0;
  gap: var(--gap-87xl);
  min-height: 20px;
}
.section12 {
  align-self: stretch;
  background-color: var(--color-darkslateblue-400);
  overflow: hidden;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-xl) var(--padding-148xl) var(--padding-xl)
    var(--padding-141xl);
  gap: var(--gap-xl);
  font-size: var(--font-size-7xs);
  color: var(--color-sandybrown);
  font-family: var(--font-font-awesome-5-free);
}
.contact-us1,
.symbol-list-medical-negligence {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.symbol-list-medical-negligence {
  align-self: stretch;
  align-items: flex-start;
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-2xl-8);
  color: var(--color-white);
  font-family: var(--font-libre-baskerville);
}
.contact-us1 {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  align-items: center;
  /* gap: var(--gap-81xl); */
  letter-spacing: normal;
  padding-bottom: 100px;
}
.select1{
  padding-top: 10px;
}
@media screen and (max-width: 1350px) {
  .section11,
  .section12 {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 800px) {
  .section11 {
    gap: var(--gap-57xl);
    padding: var(--padding-30xl) var(--padding-72xl) var(--padding-47xl)
      var(--padding-63xl);
    box-sizing: border-box;
  }
  .frame-legal-info {
    flex-wrap: wrap;
  }
  .frame-privacy-policy {
    flex-wrap: wrap;
    gap: var(--gap-87xl);
  }
  .section12 {
    padding-left: var(--padding-61xl);
    padding-right: var(--padding-64xl);
    box-sizing: border-box;
  }
  /* .contact-us1 {
    gap: var(--gap-81xl);
  } */
}
@media screen and (max-width: 450px) {
  .heading-26 {
    font-size: var(--font-size-5xl);
    line-height: 29px;
  }
  .full-name-container {
    gap: var(--gap-29xl);
  }
  .heading-412,
  .practice-area5 {
    font-size: var(--font-size-mid);
    line-height: 24px;
  }
  .heading-413,
  .heading-414 {
    font-size: var(--font-size-lg);
    line-height: 24px;
  }
  .heading-414 {
    font-size: var(--font-size-mid);
  }
  .section11 {
    gap: var(--gap-57xl);
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
  .frame-legal-info {
    gap: var(--gap-15xl);
  }
  .frame-privacy-policy {
    gap: var(--gap-87xl);
  }
  .section12 {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
 
  /* .contact-us1 {
    gap: var(--gap-81xl);
  } */
}
