.heading-211 {
  position: relative;
  letter-spacing: -0.4px;
  line-height: 36px;
}
.improvements-in-technology6,
.only-driven-by6,
.practitioners-increasingly6,
.the-demand-and7 {
  margin: 0;
}
.the-demand-and-container7 {
  flex: 1;
  position: relative;
  line-height: 24px;
  display: flex;
  align-items: center;
}
.item-link-facebook6,
.item-link-instagram6,
.item-link-linkedin6,
.item-link-twitter7 {
  height: 35px;
  width: 35px;
  position: relative;
  border-radius: var(--br-mid-5);
  min-height: 35px;
}
.symbol162 {
  flex-direction: row;
  gap: var(--gap-mini);
}
.item2,
.list9,
.symbol-wrapper,
.symbol162 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.symbol-wrapper {
  flex-direction: row;
  padding: 0 var(--padding-8xs);
}
.item2,
.list9 {
  flex-direction: column;
}
.item2 {
  flex: 1;
  gap: var(--gap-29xl);
  font-size: var(--font-size-base);
  color: var(--color-lightsteelblue-100);
  font-family: var(--font-rubik);
}
.list9 {
  height: 232px;
  gap: var(--gap-3xl);
  font-size: var(--font-size-10xl-9);
}
.practice-area9 {
  position: relative;
  letter-spacing: -0.4px;
  line-height: 30px;
  text-transform: capitalize;
}
.list-item133,
.symbol166 {
  position: relative;
  line-height: 10.89px;
}
.list-item133 {
  line-height: 19px;
}
.list10,
.symbol165 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.list10 {
  flex-direction: row;
  padding: 0 0 0 var(--padding-xl);
  margin-top: -5px;
  font-size: var(--font-size-base);
  color: var(--color-gray-700);
  font-family: var(--font-rubik);
}
.symbol165 {
  flex-direction: column;
}
.list-item134,
.symbol168 {
  position: relative;
  line-height: 10.89px;
}
.list-item134 {
  line-height: 19px;
}
.list-item-link-bankruptc-wrapper3,
.symbol167 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.list-item-link-bankruptc-wrapper3 {
  flex-direction: row;
  padding: 0 0 0 var(--padding-xl);
  margin-top: -5px;
  font-size: var(--font-size-base);
  color: var(--color-gray-700);
  font-family: var(--font-rubik);
}
.symbol167 {
  flex-direction: column;
}
.list-item135,
.symbol170 {
  position: relative;
  line-height: 10.89px;
}
.list-item135 {
  line-height: 19px;
}
.list-item-link-banking-wrapper4,
.symbol169 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.list-item-link-banking-wrapper4 {
  flex-direction: row;
  padding: 0 0 0 var(--padding-xl);
  margin-top: -5px;
  font-size: var(--font-size-base);
  color: var(--color-gray-700);
  font-family: var(--font-rubik);
}
.symbol169 {
  flex-direction: column;
}
.list-item136,
.symbol172 {
  position: relative;
  line-height: 10.89px;
}
.list-item136 {
  line-height: 19px;
}
.list-item-link-tax-consu-wrapper4,
.symbol171 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.list-item-link-tax-consu-wrapper4 {
  flex-direction: row;
  padding: 0 0 0 var(--padding-xl);
  margin-top: -5px;
  font-size: var(--font-size-base);
  color: var(--color-gray-700);
  font-family: var(--font-rubik);
}
.symbol171 {
  flex-direction: column;
}
.list-item137,
.symbol174 {
  position: relative;
  line-height: 10.89px;
}
.list-item137 {
  line-height: 19px;
}
.list-item-link-medical-n-wrapper4,
.symbol173 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.list-item-link-medical-n-wrapper4 {
  flex-direction: row;
  padding: 0 0 0 var(--padding-xl);
  margin-top: -5px;
  font-size: var(--font-size-base);
  color: var(--color-gray-700);
  font-family: var(--font-rubik);
}
.symbol173 {
  flex-direction: column;
}
.list-item138,
.symbol176 {
  position: relative;
  line-height: 10.89px;
}
.list-item138 {
  line-height: 19px;
}
.list-item-link-energy-wrapper4,
.symbol175 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.list-item-link-energy-wrapper4 {
  flex-direction: row;
  padding: 0 0 0 var(--padding-xl);
  margin-top: -5px;
  font-size: var(--font-size-base);
  color: var(--color-gray-700);
  font-family: var(--font-rubik);
}
.symbol175 {
  flex-direction: column;
}
.list-item139,
.symbol178 {
  position: relative;
  line-height: 10.89px;
}
.list-item139 {
  line-height: 19px;
}
.list-item-link-intellect-wrapper4 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 var(--padding-xl);
  margin-top: -5px;
  font-size: var(--font-size-base);
  color: var(--color-gray-700);
  font-family: var(--font-rubik);
}
.symbol163,
.symbol164,
.symbol177 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.symbol164 {
  gap: var(--gap-sm);
  font-size: var(--font-size-6xs);
  color: var(--color-darkkhaki);
  font-family: var(--font-font-awesome-5-free);
}
.symbol163 {
  height: 301px;
  padding: 0 var(--padding-9xs) 0 0;
  box-sizing: border-box;
  gap: var(--gap-3xl);
}
.heading-418 {
  position: relative;
  letter-spacing: -0.4px;
  line-height: 30px;
  text-transform: capitalize;
}
.symbol179,
.symbol180,
.symbol181,
.symbol182,
.symbol183,
.symbol184 {
  position: relative;
  line-height: 6px;
}
.symbol-parent23 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xl);
}
.list-item140,
.list-item141,
.list-item142 {
  position: relative;
  line-height: 34px;
}
.list-item141,
.list-item142 {
  z-index: 1;
}
.list-item142 {
  z-index: 2;
}
.list-item143,
.list-item144,
.list-item145 {
  position: relative;
  line-height: 34px;
  z-index: 3;
}
.list-item144,
.list-item145 {
  z-index: 4;
}
.list-item145 {
  z-index: 5;
}
.attorneys-list-frame1 {
  flex-direction: column;
  align-items: flex-start;
  font-size: var(--font-size-lg);
  color: var(--color-lightsteelblue-100);
  font-family: var(--font-roboto);
}
.attorneys-list-frame1,
.heading-frame,
.useful-links-box {
  display: flex;
  justify-content: flex-start;
}
.heading-frame {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-sm);
  font-size: var(--font-size-7xs);
  color: var(--color-sandybrown);
  font-family: var(--font-font-awesome-5-free);
}
.useful-links-box {
  flex-direction: column;
  align-items: flex-start;
  padding: 0 var(--padding-6xl) 0 0;
  gap: var(--gap-xl);
}
.heading-419 {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.4px;
  line-height: 30px;
  text-transform: capitalize;
  font-weight: 700;
  font-family: inherit;
}
.symbol185,
.symbol186,
.symbol187 {
  position: relative;
  line-height: 18px;
}
.inner-frame,
.nested-frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-lgi);
}
.inner-frame {
  gap: var(--gap-11xl);
}
.little-william-st6,
.vic-3000-australia6 {
  margin: 0;
}
.list-item-container7,
.list-item146,
.list-item147 {
  position: relative;
  letter-spacing: -0.2px;
  line-height: 22px;
}
.list-item147 {
  white-space: nowrap;
}
.head-office-frame,
.sub-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  font-size: var(--font-size-base);
  color: var(--color-white);
  font-family: var(--font-roboto);
}
.head-office-frame {
  flex-direction: row;
  align-items: flex-end;
  padding: 0 0 var(--padding-9xs);
  gap: var(--gap-lg);
  font-size: var(--font-size-lg);
  color: var(--color-sandybrown);
  font-family: var(--font-font-awesome-5-free);
}
.heading-420 {
  font-size: var(--font-size-2xl-8);
  letter-spacing: -0.4px;
  line-height: 30px;
  text-transform: capitalize;
}
.email8,
.heading-420 {
  position: relative;
}
.input9 {
  flex: 1;
  background-color: var(--color-gray-100);
  border: 1px solid var(--color-whitesmoke-400);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-base) var(--padding-base) var(--padding-mini);
  white-space: nowrap;
}
.symbol188 {
  position: relative;
  line-height: 15px;
}
.button10 {
  background-color: var(--color-sandybrown);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-mini) var(--padding-lgi) var(--padding-mid)
    var(--padding-xl);
  z-index: 1;
  margin-left: -35px;
  text-align: center;
  font-size: var(--font-size-mini);
  color: var(--color-white);
  font-family: var(--font-font-awesome-5-free);
}
.input-email-button-symbol1 {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-12xs) 0 0;
  font-size: var(--font-size-sm);
  color: var(--color-lightsteelblue-300);
  font-family: var(--font-roboto);
}
.heading9,
.input-email-button-symbol1,
.section34 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.heading9 {
  width: 240px;
  flex-direction: column;
  gap: var(--gap-2xl);
  font-size: var(--font-size-3xl);
}
.section34 {
  overflow: hidden;
  flex-direction: row;
  padding: var(--padding-56xl) var(--padding-161xl) var(--padding-83xl)
    var(--padding-146xl);
  gap: var(--gap-57xl);
  background-image: url(/public/section12@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: left;
  font-size: var(--font-size-2xl-8);
  color: var(--color-white);
  font-family: var(--font-libre-baskerville);
}
@media screen and (max-width: 1350px) {
  .section34 {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 800px) {
  .section34 {
    gap: var(--gap-57xl);
    padding: var(--padding-30xl) var(--padding-71xl) var(--padding-47xl)
      var(--padding-63xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .heading-211 {
    font-size: var(--font-size-5xl);
    line-height: 29px;
  }
  .item2 {
    gap: var(--gap-29xl);
  }
  .heading-418,
  .practice-area9 {
    font-size: var(--font-size-mid);
    line-height: 24px;
  }
  .heading-419,
  .heading-420 {
    font-size: var(--font-size-lg);
    line-height: 24px;
  }
  .heading-420 {
    font-size: var(--font-size-mid);
  }
  .section34 {
    gap: var(--gap-57xl);
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
}
