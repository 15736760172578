.contact-link {
  text-decoration: none; 
}

.contact-button {
  background-color: #e86500;;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contact-button:hover {
  background-color: #f16a03;
}
.heading-416 {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.4px;
  line-height: 30px;
  text-transform: capitalize;
  font-weight: 700;
  font-family: inherit;
}
.symbol150,
.symbol151,
.symbol152 {
  position: relative;
  line-height: 18px;
}

.frame-within-frame,
.symbol-symbol-frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-lgi);
}
.symbol-symbol-frame {
  gap: var(--gap-11xl);
}
.little-william-st5,
.vic-3000-australia5 {
  margin: 0;
}
.list-item-container6,
.list-item118,
.list-item119 {
  position: relative;
  letter-spacing: -0.2px;
  line-height: 22px;
}
.list-item119 {
  white-space: nowrap;
}
.infoprivsacom-link-list,
.privacy-policy-list1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  font-size: var(--font-size-base);
  color: var(--color-white);
  font-family: var(--font-roboto);
}
.privacy-policy-list1 {
  flex-direction: row;
  align-items: flex-end;
  padding: 0 0 var(--padding-9xs);
  gap: var(--gap-lg);
  font-size: var(--font-size-lg);
  color: var(--color-sandybrown);
  font-family: var(--font-font-awesome-5-free);
}
.heading-417 {
  font-size: var(--font-size-2xl-8);
  letter-spacing: -0.4px;
  line-height: 30px;
  text-transform: capitalize;
}
.email7,
.heading-417 {
  position: relative;
}
.input8 {
  flex: 1;
  background-color: var(--color-gray-100);
  border: 1px solid var(--color-whitesmoke-400);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-base) var(--padding-base) var(--padding-mini);
  white-space: nowrap;
}
.symbol153 {
  position: relative;
  line-height: 15px;
}
.button9 {
  background-color: var(--color-sandybrown);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-mini) var(--padding-lgi) var(--padding-mid)
    var(--padding-xl);
  z-index: 1;
  margin-left: -35px;
  text-align: center;
  font-size: var(--font-size-mini);
  color: var(--color-white);
  font-family: var(--font-font-awesome-5-free);
  height: 50px;
}
.input-email-button-symbol-fram,
.subscribe-input-button {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.input-email-button-symbol-fram {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-12xs) 0 0;
  font-size: var(--font-size-sm);
  color: var(--color-lightsteelblue-300);
  font-family: var(--font-roboto);
}
.subscribe-input-button {
  width: 240px;
  flex-direction: column;
  gap: var(--gap-2xl);
  text-align: left;
  font-size: var(--font-size-3xl);
  color: var(--color-white);
  font-family: var(--font-libre-baskerville);
}
@media screen and (max-width: 450px) {
  .heading-416,
  .heading-417 {
    font-size: var(--font-size-lg);
    line-height: 24px;
  }
  .heading-417 {
    font-size: var(--font-size-mid);
  }
}

@media screen and (min-width: 1176px) and (max-width: 1330px) {
  .subscribe-input-button {
    padding-left:0px
  }
  .input-email-button-symbol-fram{
    width: 200px;
  }
}
