.heading-321 {
  position: relative;
  letter-spacing: -0.4px;
  line-height: 22px;
  font-weight: 500;
}
.get-in-touch,
.our-experts {
  margin: 0;
}
.heading-2-container3 {
  margin: 0;
  position: relative;
  font-size: var(--font-size-26xl);
  letter-spacing: -0.2px;
  line-height: 56px;
  font-weight: 700;
  font-family: var(--font-libre-baskerville);
  color: var(--color-white);
}
.social-button {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
  max-width: 100%;
}
.heading-322 {
  position: relative;
  letter-spacing: -0.14px;
  line-height: 16.8px;
  font-weight: 500;
}
.our-services1 {
  position: relative;
  font-size: var(--font-size-3xl);
  letter-spacing: -0.22px;
  line-height: 34.22px;
  font-family: var(--font-merriweather);
  color: var(--color-sandybrown);
  font-size: 19px;
}
.divelementskit-infobox5 {
  flex: 1;
  border-radius: var(--br-8xs);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-3xs);
  box-sizing: border-box;
  gap: var(--gap-2xs);
  min-width: 142px;
}
.heading-323 {
  position: relative;
  letter-spacing: -0.14px;
  line-height: 16.8px;
  font-weight: 500;
}
.divelementskit-infobox6 {
  align-self: stretch;
  flex: 1;
  border-radius: var(--br-8xs);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-3xs);
}
.item-link-twitter5 {
  height: 18px;
  width: 18px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 18px;
}
.symbol129 {
  position: relative;
  line-height: 18px;
}
.item-link-whatsapp {
  height: 18px;
  width: 18px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 18px;
}
.symbol130 {
  position: relative;
  line-height: 18px;
}
.link-collection {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xl);
  text-align: center;
  font-size: var(--font-size-lg);
  color: var(--color-sandybrown);
  font-family: var(--font-font-awesome-5-brands);
}
.service-symbol {
  height: 50px;
  width: 188px;
  flex-direction: column;
  gap: var(--gap-8xs);
}
.capital-markets-hub,
.display-frame,
.graphic-container,
.service-symbol {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.capital-markets-hub {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-11xl);
  font-size: var(--font-size-sm);
  color: var(--color-white);
}
.display-frame,
.graphic-container {
  flex-direction: column;
  max-width: 100%;
}
.display-frame {
  align-self: stretch;
  gap: var(--gap-81xl);
}
.graphic-container {
  width: 436px;
  padding: var(--padding-3xs) 0 0;
  box-sizing: border-box;
  min-width: 436px;
}
.svg-icon1 {
  position: absolute;
  top: 14.4px;
  left: 15px;
  width: 20px;
  height: 20px;
  overflow: hidden;
}
.divelementor-widget-container5 {
  height: 51px;
  width: 50px;
  position: relative;
  background-color: var(--color-lightslategray);
  z-index: 1;
}
.full-name input[type="text"],.full-name input[type="text"]::placeholder ,input:focus  {
  position: relative;
  letter-spacing: -0.15px;
  text-transform: uppercase;
  font-weight: 500;
  border: none;
  outline: none;
}

input.full-name1{
  border: none;
}
textarea::placeholder {
  padding-left: 10px;
 padding-top: 10px; 
}

textarea.full-name1 {
  margin-left: -49px;
    color: black;
    outline: none;
    padding-left: 55px;
}
textarea.full-name1,textarea:focus {
  margin-left: -40px;
  border: none;
  width:100%;
  height:150px;
  padding-left: 10px;
 padding-top: 10px; 
}


textarea.full-name1:focus::placeholder {
  font-weight: 500;
}

.info-items-container,
.input5 {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

}
.input5 {
  background-color: var(--color-white);
  align-items: center;
  /*padding: var(--padding-mid) var(--padding-52xl) var(--padding-base);*/
  padding-left: 50px;
  white-space: nowrap;
  margin-left: -40px;
  height: 51px;
}
.info-items-container {
  align-items: flex-start;
  min-width: 213px;
  max-width: 100%;
}
.svg-icon2 {
  position: absolute;
  top: 14.4px;
  left: 15px;
  width: 20px;
  height: 20px;
  overflow: hidden;
}
.divelementor-widget-container6 {
  height: 51px;
  width: 50px;
  position: relative;
  background-color: var(--color-lightslategray);
  z-index: 1;
}
.email6 {
  position: relative;
  letter-spacing: -0.15px;
  text-transform: uppercase;
  font-weight: 500;
}
.heading5,
.input6,
.list-of-links {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.input6 {
  flex: 1;
  background-color: var(--color-white);
  align-items: center;
  padding: var(--padding-mid) var(--padding-52xl) var(--padding-base);
  white-space: nowrap;
  margin-left: -40px;
}
.heading5,
.list-of-links {
  align-items: flex-start;
}
.heading5 {
  flex: 1;
  min-width: 206px;
}
.list-of-links {
  align-self: stretch;
  row-gap: 20px;
  max-width: 100%;
  margin-bottom: 20px;
}
.svg-icon3 {
  position: absolute;
  top: 14.4px;
  left: 15px;
  width: 20px;
  height: 20px;
  overflow: hidden;
}
.divelementor-widget-container7 {
  height: 51px;
  width: 50px;
  position: relative;
  background-color: var(--color-lightslategray);
  z-index: 1;
}
.phone-number {
  position: relative;
  letter-spacing: -0.15px;
  text-transform: uppercase;
  font-weight: 500;
}
.divelementor-widget-container-parent,
.input7 {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  
}
.input7 {
  background-color: var(--color-white);
  align-items: center;
  white-space: nowrap;
  margin-left: -42px;
}
.divelementor-widget-container-parent {
  align-items: flex-start;
  min-width: 213px;
  max-width: 100%;
}
.svg-icon4 {
  position: absolute;
  top: 14.4px;
  left: 15px;
  width: 20px;
  height: 20px;
  overflow: hidden;
}
.divelementor-widget-container8 {
  height: 51px;
  width: 50px;
  position: relative;
  background-color: var(--color-lightslategray);
  z-index: 1;
}
.select {
  position: relative;
  line-height: 21px;
  text-transform: uppercase;
  font-weight: 500;
}
.divmf-select-indicator {
  height: 5px;
  width: 8px;
  position: relative;
  border-top: 5px solid var(--color-lightgray-400);
  border-right: 4px solid var(--color-lightgray-400);
  border-left: 4px solid var(--color-lightgray-400);
  box-sizing: border-box;
}
.divmf-input {
  flex: 1;
  background-color: var(--color-white);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-mini) var(--padding-10xs) var(--padding-mini)
    var(--padding-54xl);
  gap: var(--gap-xl);
  margin-left: -40px;
  height: 51px;
}
.divelementor-widget-container-group,
.list-of-links1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.divelementor-widget-container-group {
  flex: 1;
  min-width: 206px;
  font-size: var(--font-size-base);
  font-family: var(--font-roboto);
}
.list-of-links1 {
  align-self: stretch;
  row-gap: 20px;
  max-width: 100%;
  margin-bottom: 20px;
}
.svg-icon5 {
  position: absolute;
  top: 14.4px;
  left: 15px;
  width: 20px;
  height: 20px;
  overflow: hidden;
}
.divelementor-widget-container9 {
  height: 51px;
  width: 50px;
  position: relative;
  background-color: var(--color-lightslategray);
  z-index: 1;
}
.textarea {
  height: 125px;
  flex: 1;
  position: relative;
  background-color: var(--color-white);
  overflow: hidden;
  min-width: 412px;
  max-width: 100%;
  margin-left: -40px;
}
.form-section-section2 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  /* padding: 0 var(--padding-3xs) var(--padding-3xs) 0; */
  box-sizing: border-box;
  row-gap: 20px;
  max-width: 100%;
}
.get-free-quote,
.symbol131 {
  position: relative;
  letter-spacing: -0.2px;
  text-transform: uppercase;
}
.get-free-quote {
  line-height: 18px;
  font-weight: 500;
}
.symbol131 {
  font-size: var(--font-size-smi);
  line-height: 13px;
  font-family: var(--font-font-awesome-5-free);
  color: var(--color-skyblue);
}
.button8,
.heading6 {
  display: flex;
  align-items: center;
  max-width: 100%;
}
.button8 {
  width: 635px;
  border-radius: var(--br-11xs);
  background-color: var(--color-darkslateblue-400);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.16);
  flex-direction: row;
  justify-content: center;
  padding: var(--padding-lg) var(--padding-xl) var(--padding-lg)
    var(--padding-2xl);
  box-sizing: border-box;
  z-index: 2;
}
.heading6 {
  align-self: stretch;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  color: var(--color-white);
}
.privacy-policy-header,
.section26,
.welcome-header {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.privacy-policy-header {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-3xs);
  min-width: 425px;
  font-size: var(--font-size-mini);
  color: var(--color-slategray-200);
  display: flex;
    align-items: center;
    justify-content: center;
}
.section26,
.welcome-header {
  flex-direction: row;
  box-sizing: border-box;
}
.section26 {
  flex: 1;
  padding: var(--padding-81xl) var(--padding-131xl) var(--padding-61xl)
    var(--padding-141xl);
  gap: var(--gap-21xl);
  background-image: url(/public/section14@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  z-index: 1;
}
.welcome-header {
  align-self: stretch;
  padding: 0 0 var(--padding-71xl);
  flex-shrink: 0;
  text-align: left;
  font-size: var(--font-size-lg);
  color: var(--color-sandybrown);
  font-family: var(--font-rubik);
}
@media screen and (max-width: 1325px) {
  .graphic-container {
    flex: 1;
  }
  .section26 {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 800px) {
  form{
    scale: 0.8;
  }
  .heading-2-container3 {
    font-size: var(--font-size-17xl);
    line-height: 45px;
  }
  .graphic-container {
    min-width: 100%;
  }
  .list-of-links,
  .list-of-links1 {
    flex-wrap: wrap;
  }
  .textarea {
    min-width: 100%;
  }
  .form-section-section2 {
    /* flex-wrap: wrap; */
  }
  .privacy-policy-header {
    min-width: 100%;
  }
  .section26 {
    gap: var(--gap-21xl);
    padding: var(--padding-46xl) var(--padding-56xl) var(--padding-33xl)
      var(--padding-61xl);
    box-sizing: border-box;
  }
  .welcome-header {
    padding-bottom: var(--padding-39xl);
    box-sizing: border-box;
  }
}
@media (min-width: 1250px) and (max-width: 1380px) {
  .heading-2-container3{
    font-size: 35px;
  }

}
@media screen and (max-width: 450px) {
  .heading-2-container3 {
    font-size: var(--font-size-8xl);
    line-height: 34px;
  }
  .our-services1 {
    font-size: var(--font-size-lg);
    line-height: 27px;
  }
  .capital-markets-hub {
    flex-wrap: wrap;
  }
  .display-frame {
    gap: var(--gap-81xl);
  }
  .input7 {
    padding-right: var(--padding-xl);
  }
  .divmf-input,
  .input7 {
    padding-left: var(--padding-xl);
    box-sizing: border-box;
  }
  .textarea {
    margin-left: 0;
  }
  .section26 {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
}
