.before1 {
  align-self: stretch;
  height: 6px;
  position: relative;
  background-color: var(--color-darkslateblue-600);
}
.heading-38 {
  position: relative;
  letter-spacing: -0.1px;
  line-height: 32px;
}
.in-the-nation,
.premier-trial-lawyers {
  margin: 0;
}
.premier-trial-lawyers{
  font-size: 14px;
}
.premier-trial-lawyers-container {
  flex: 1;
  position: relative;
  font-size: var(--font-size-base);
  line-height: 26px;
  font-family: var(--font-rubik);
  display: flex;
  align-items: center;
}
.attorneys-list2 {
  height: 90px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-smi);
  padding:0 15px;
}
.link-icon2 {
 
  height: 31px;
  position: absolute;
  margin: 0 !important;
  bottom: 0;
  left: 10.35px;
  overflow: hidden;
  flex-shrink: 0;
  padding-left: 5px;
}
.practice-areas-list1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}
.divservice-content,
.section17 {
  display: flex;
  align-items: center;
  max-width: 100%;
}
.divservice-content {
  align-self: stretch;
  flex: 1;
  background-color: var(--color-white);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 0 var(--padding-17xl);
  box-sizing: border-box;
  gap: var(--gap-22xl);
  transition: all 0.5s ease-out;
  background:linear-gradient(to bottom, #04367dda 50%,rgb(255, 255, 255) 50%);
  background-size: 100% 200%;
  background-position: bottom;
  margin: -3px -6px;
  
}
.divservice-content:hover{
background-position: top;  
}
.divservice-content:hover *{
color: white;
}
.divservice-content:hover .heading-38{
color: #e86500;
}
.divservice-content:hover .link-icon2{
  filter: brightness(100);

}
.section17 {
  height: 236px;
  flex-direction: row;
  justify-content: center;
  background-image: url(/public/section2@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--color-darkslategray);
  font-family: var(--font-libre-baskerville);
}
@media screen and (max-width: 450px) {
  .heading-38 {
    font-size: var(--font-size-base);
    line-height: 26px;
  }
  .divservice-content {
    gap: var(--gap-22xl);
  }
}
