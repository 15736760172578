.section-heading6 {
  position: relative;
  letter-spacing: -0.4px;
  line-height: 22px;
  font-weight: 500;
  color: #e86500;
}
.section-heading7 {
  margin: 0;
  position: relative;
  font-size: var(--font-size-26xl);
  letter-spacing: -0.2px;
  line-height: 78px;
  font-weight: 700;
  font-family: var(--font-libre-baskerville);
  color: var(--color-darkslateblue-500);
}
.blogs-link{
  text-decoration: none;
  color: white;
}
.link9,
.text2 {
  display: flex;
  max-width: 100%;
}
.text2 {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-4xs);
}
.link9 {
  width: 387px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.september-15-20201 {
  position: relative;
  letter-spacing: -0.2px;
  line-height: 17px;
  text-transform: uppercase;
  font-weight: 500;
}
.section29 {
  border-right: 1px solid var(--color-lightgray-100);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-11xs) var(--padding-mid) 0 0;
}
.link-litigation1 {
  position: relative;
  letter-spacing: -0.2px;
  line-height: 17px;
  text-transform: uppercase;
  font-weight: 500;
}
.link-litigation-container {
  height: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
  box-sizing: border-box;
}
.section30,
.symbol134 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.section30 {
  align-self: stretch;
  border-right: 1px solid var(--color-lightgray-100);
  align-items: center;
  padding: var(--padding-11xs) var(--padding-xl) 0 0;
}
.symbol134 {
  height: 19px;
  align-items: flex-start;
  padding: 0 var(--padding-12xs) 0 0;
  box-sizing: border-box;
  gap: var(--gap-5xl);
}
.aenean-sollicitudin,
.proin-gravida-nibh {
  margin: 0;
}
.section-heading-container1 {
  margin: 0;
  position: relative;
  font-size: var(--font-size-3xl);
  letter-spacing: -0.2px;
  line-height: 30px;
  font-weight: 700;
  font-family: var(--font-libre-baskerville);
  color: var(--color-darkslateblue-500);
}
.section-link3 {
  position: relative;
  letter-spacing: -0.2px;
  line-height: 17px;
  text-transform: uppercase;
  font-weight: 500;
}
.section-link-read-more-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
}
.section-link4 {
  height: 12px;
  width: 11.8px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.link-group,
.symbol133,
.symbol135 {
  display: flex;
  justify-content: flex-start;
}
.symbol135 {
  flex-direction: row;
  align-items: center;
  text-align: center;
  color: var(--color-sandybrown);
}
.link-group,
.symbol133 {
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-xl);
  min-width: 307px;
}
.link-group {
  width: 400px;
  display: flex;
  flex-direction: row;
  gap: var(--gap-20xl);
  /* min-width: 691px; */
  max-width: 100%;
  flex-wrap: wrap;
}
.heading7,
.link-parent {
  align-self: stretch;
  display: flex;
  max-width: 100%;
}
.heading7 {
  flex-direction: row;
  align-items: flex-start; 
  justify-content: space-between;
  gap: var(--gap-xl);
  text-align: left;
  font-size: var(--font-size-sm);
  color: var(--color-slategray-200);
  margin-left: 40px;
}
.link-parent {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-16xl);
}
.symbol136,
.view-all-news {
  position: relative;
  letter-spacing: -0.2px;
  text-transform: uppercase;
}
.view-all-news {
  line-height: 19px;
  font-weight: 500;
  
}
.symbol136 {
  font-size: var(--font-size-xs);
  line-height: 12px;
  font-family: var(--font-font-awesome-5-free);
}
.section-container,
.section31 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.section31 {
  gap: var(--gap-3xs);
}
.section-container {
  padding: 0 var(--padding-xl) 0 var(--padding-7xl);
}
.after2 {
  align-self: stretch;
  height: 2.4px;
  position: relative;
  background-color: var(--color-sandybrown);
}
.section-link5 {
  width: 315px;
  background-color: var(--color-darkslateblue-400);
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xl) 0 0;
  box-sizing: border-box;
  gap: var(--gap-lg);
  transition: all 0.5s ease-out;
  background:linear-gradient(to top, #e86500 50%,var(--color-darkslateblue-400) 50%);
  background-size: 100% 200%;
  background-position: top;  transition: all 0.5s ease-out;
}
.section-link5:hover{
  background-position: bottom;
}
.legalupdates-link{
  text-decoration: none;
  color: white;
}
.section-link-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-base);
  color: var(--color-white);
}
.link8,
.text1 {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.text1 {
  width: 1075px;
  flex-direction: column;
  /* align-items: center; */
  gap: var(--gap-45xl);
}
.link8 {
  /* width: 1160px; */
  flex-direction: row;
  align-items: flex-start;
  padding: 0 var(--padding-xl) var(--padding-81xl);
  box-sizing: border-box;
  flex-shrink: 0;
  text-align: center;
  font-size: var(--font-size-lg);
  color: var(--color-sandybrown);
  font-family: var(--font-rubik);
}
@media screen and (min-width: 400px) {
  .link-group {
    flex: 1;
  }
  .heading7 {
    flex-wrap: wrap;
  }
  .text1 {
    gap: var(--gap-45xl);
  }
}
@media screen and (max-width: 800px) {
  .section-heading7 {
    font-size: var(--font-size-17xl);
    line-height: 62px;
  }
  .symbol133 {
    flex: 1;
  }
  .link-group {
    flex-wrap: wrap;
    gap: var(--gap-20xl);
    /* min-width: 100%; */
  }
  .link-parent {
    gap: var(--gap-16xl);
  }
  .text1 {
    gap: var(--gap-45xl);
  }
  .link8 {
    padding-bottom: var(--padding-46xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .section-heading7 {
    font-size: var(--font-size-8xl);
    line-height: 47px;
  }
  .section-heading-container1 {
    font-size: var(--font-size-lg);
    line-height: 24px;
  }
}
