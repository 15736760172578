input[type="text"],input[type="email"],input[type="tel"]{
    border: none;
}
textarea {
  padding-left: 50px !important;
  margin-left: -40px !important;
}

.privacy-policy-header {
    margin: 20px;
  }
  @media screen and (max-width:450px) {
    .privacy-policy-header{
      padding-right: 40px !important;
    }
    .privacy-policy-header textarea {
      margin-left: -50px !important;
      padding-left: 60px !important;
    }
    
  }
  
  .input5 input {
    width: 100%;
    padding: 10px;
    /* border: 1px solid #ccc; */
    border-radius: 5px;
    margin-top: 5px;
  }
  
  .button8 {
    /* background-color: #007bff; */
    border: none;
    color: white;
    padding: 15px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-top: 10px;
    cursor: pointer;
    border-radius: 5px;
  }
  .submit-icon{
    padding-left: 10px;
  }
 
  .button8:hover{
    color: #000000 !important;
    background-color:#e86500;
  }
  
  .error-message {
    color: red;
    margin-bottom: 10px;
  }
  .success-message {
    color: rgb(52, 240, 0);
    margin-bottom: 10px;
  }
  