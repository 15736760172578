.link-footer-logopng4 {
  height: 52px;
  width: 170px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  visibility: hidden;
}
.list-item120,
.symbol154 {
  position: relative;
  letter-spacing: -0.2px;
  line-height: 6px;
}
.list-item120 {
  font-size: var(--font-size-base);
  line-height: 19px;
  font-family: var(--font-roboto);
  color: var(--color-white);
  text-align: center;
}
.legal-infos {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-sm);
}
.list-item121,
.symbol155 {
  position: relative;
  letter-spacing: -0.2px;
  line-height: 6px;
}
.list-item121 {
  font-size: var(--font-size-base);
  line-height: 19px;
  font-family: var(--font-roboto);
  color: var(--color-white);
  text-align: center;
}
.legal-infos1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-sm);
}
.list-item122,
.symbol156 {
  position: relative;
  letter-spacing: -0.2px;
  line-height: 6px;
}
.list-item122 {
  font-size: var(--font-size-base);
  line-height: 19px;
  font-family: var(--font-roboto);
  color: var(--color-white);
  text-align: center;
}
.legal-infos2,
.privacy-policy {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-sm);
}
.privacy-policy {
  flex: 1;
  align-items: flex-start;
  gap: var(--gap-16xl);
  max-width: 100%;
}
.privsa4 {
  color: #e86500;
}
.privsa-all-rights-container4 {
  position: relative;
  font-size: var(--font-size-base);
  letter-spacing: -0.2px;
  line-height: 19px;
  font-family: var(--font-rubik);
  text-align: right;
  flex-shrink: 0;
  color: var(--color-white);
}
.footer-links,
.section32 {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  text-align: center;
}
.footer-links {
  width: 804px;
  align-items: center;
  justify-content: center;
  gap: var(--gap-88xl);
  min-height: 20px;
}
.section32 {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  background-color: var(--color-darkslateblue-400);
  overflow: hidden;
  align-items: center;
  /* justify-content: space-between; */
  padding: var(--padding-xl) var(--padding-145xl) var(--padding-xl)
    var(--padding-141xl);
  box-sizing: border-box;
  gap: var(--gap-xl);
  flex-shrink: 0;
  z-index: 6;
  text-align: left;
  font-size: var(--font-size-7xs);
  color: var(--color-sandybrown);
  font-family: var(--font-font-awesome-5-free);
}
@media screen and (max-width: 1325px) {
  .section32 {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (max-width: 1125px) {
  .footer-links {
    flex-wrap: wrap;
    gap: var(--gap-88xl);
  }
}
@media screen and (max-width: 800px) {
  .privacy-policy {
    flex-wrap: wrap;
  }
  .section32 {
    padding-left: var(--padding-61xl);
    padding-right: var(--padding-63xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .privacy-policy {
    gap: var(--gap-16xl);
  }
  .footer-links {
    gap: var(--gap-88xl);
  }
  .section32 {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
}
