.main-section1,
.main-section2 {
  position: relative;
  letter-spacing: -0.4px;
  line-height: 22px;
  font-weight: 500;
}
.main-section1{
  color: #e86500;
}
.main-section2 {
  margin: 0;
  font-size: var(--font-size-26xl);
  letter-spacing: -0.2px;
  line-height: 56px;
  font-weight: 700;
  font-family: var(--font-libre-baskerville);
  color: var(--color-darkslateblue-500);
}
.main-section-heading-3-p-parent {
  gap: var(--gap-xl);
  max-width: 100%;
}
.frame-parent,
.main-section-heading-3-p-parent,
.our-services {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.frame-parent {
  width: 1140px;
  padding: 0 var(--padding-xl) 60px;
  box-sizing: border-box;
  gap: var(--gap-31xl);
  max-width: 100%;
  text-align: center;
  font-size: var(--font-size-lg);
  color: var(--color-sandybrown);
  font-family: var(--font-rubik);
}
.our-services {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  letter-spacing: normal;
}
@media screen and (max-width: 1350px) {
  .frame-parent {
    padding-bottom: var(--padding-20xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 800px) {
  .main-section2 {
    font-size: var(--font-size-17xl);
    line-height: 45px;
  }
  .frame-parent {
    gap: var(--gap-31xl);
    padding-bottom: var(--padding-6xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .main-section2 {
    font-size: var(--font-size-8xl);
    line-height: 34px;
  }
}
