section.practice-area-capital-markets{
  padding-top: 10px;
}
.practice-area-capital-markets {
  width: 1150px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-xl) var(--padding-21xl);
  box-sizing: border-box;
  gap: var(--gap-11xl);
  max-width: 100%;
  flex-shrink: 0;
}
.heading-24 {
  position: relative;
  letter-spacing: -0.4px;
  line-height: 36px;
}
.improvements-in-technology2,
.only-driven-by2,
.practitioners-increasingly2,
.the-demand-and2 {
  margin: 0;
}
.the-demand-and-container2 {
  flex: 1;
  position: relative;
  line-height: 24px;
  display: flex;
  align-items: center;
}
.item-link-facebook2,
.item-link-instagram2,
.item-link-linkedin2,
.item-link-twitter2 {
  height: 35px;
  width: 35px;
  position: relative;
  border-radius: var(--br-mid-5);
  min-height: 35px;
}
.practice-area-frame-nesting1,
.practice-area-frame-nesting2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.practice-area-frame-nesting2 {
  padding: 0 var(--padding-12xs) 0 0;
  gap: var(--gap-sm);
}
.practice-area-frame-nesting1 {
  padding: 0 var(--padding-8xs);
}
.attorneys-practice-area-list,
.practice-area-frame-nesting {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.practice-area-frame-nesting {
  flex: 1;
  gap: var(--gap-29xl);
  font-size: var(--font-size-base);
  color: var(--color-lightsteelblue-100);
  font-family: var(--font-rubik);
}
.attorneys-practice-area-list {
  height: 232px;
  gap: var(--gap-3xl);
  font-size: var(--font-size-10xl-9);
}
.practice-area3 {
  position: relative;
  letter-spacing: -0.4px;
  line-height: 30px;
  text-transform: capitalize;
}
.list-item33,
.symbol48 {
  position: relative;
  line-height: 10.89px;
}
.list-item33 {
  line-height: 19px;
}
.facebook-linked-in-instagram-t {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 var(--padding-xl);
  margin-top: -5px;
  font-size: var(--font-size-base);
  color: var(--color-gray-700);
  font-family: var(--font-rubik);
}
.input-search-main-complementar2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.list-item34,
.symbol49 {
  position: relative;
  line-height: 10.89px;
}
.list-item34 {
  line-height: 19px;
}
.list-item-link-bankruptc-frame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 var(--padding-xl);
  margin-top: -5px;
  font-size: var(--font-size-base);
  color: var(--color-gray-700);
  font-family: var(--font-rubik);
}
.input-search-main-complementar3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.list-item35,
.symbol50 {
  position: relative;
  line-height: 10.89px;
}
.list-item35 {
  line-height: 19px;
}
.list-item-link-banking-frame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 var(--padding-xl);
  margin-top: -5px;
  font-size: var(--font-size-base);
  color: var(--color-gray-700);
  font-family: var(--font-rubik);
}
.input-search-main-complementar4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.list-item36,
.symbol51 {
  position: relative;
  line-height: 10.89px;
}
.list-item36 {
  line-height: 19px;
}
.list-item-link-tax-consu-frame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 var(--padding-xl);
  margin-top: -5px;
  font-size: var(--font-size-base);
  color: var(--color-gray-700);
  font-family: var(--font-rubik);
}
.input-search-main-complementar5 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.list-item37,
.symbol52 {
  position: relative;
  line-height: 10.89px;
}
.list-item37 {
  line-height: 19px;
}
.list-item-link-medical-n-frame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 var(--padding-xl);
  margin-top: -5px;
  font-size: var(--font-size-base);
  color: var(--color-gray-700);
  font-family: var(--font-rubik);
}
.input-search-main-complementar6 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.list-item38,
.symbol53 {
  position: relative;
  line-height: 10.89px;
}
.list-item38 {
  line-height: 19px;
}
.list-item-link-energy-frame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 var(--padding-xl);
  margin-top: -5px;
  font-size: var(--font-size-base);
  color: var(--color-gray-700);
  font-family: var(--font-rubik);
}
.input-search-main-complementar7 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.list-item39,
.symbol54 {
  position: relative;
  line-height: 10.89px;
}
.list-item39 {
  line-height: 19px;
}
.list-item-link-intellect-frame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 var(--padding-xl);
  margin-top: -5px;
  font-size: var(--font-size-base);
  color: var(--color-gray-700);
  font-family: var(--font-rubik);
}
.input-search-main-complementar,
.input-search-main-complementar1,
.input-search-main-complementar8 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.input-search-main-complementar1 {
  gap: var(--gap-sm);
  font-size: var(--font-size-6xs);
  color: var(--color-darkkhaki);
  font-family: var(--font-font-awesome-5-free);
}
.input-search-main-complementar {
  height: 301px;
  padding: 0 var(--padding-9xs) 0 0;
  box-sizing: border-box;
  gap: var(--gap-3xl);
}
.heading-46 {
  position: relative;
  letter-spacing: -0.4px;
  line-height: 30px;
  text-transform: capitalize;
}
.symbol55,
.symbol56,
.symbol57,
.symbol58,
.symbol59,
.symbol60 {
  position: relative;
  line-height: 6px;
}
.recent-links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xl);
}
.list-item40,
.list-item41,
.list-item42 {
  position: relative;
  line-height: 34px;
}
.list-item41,
.list-item42 {
  z-index: 1;
}
.list-item42 {
  z-index: 2;
}
.list-item43,
.list-item44,
.list-item45 {
  position: relative;
  line-height: 34px;
  z-index: 3;
}
.list-item44,
.list-item45 {
  z-index: 4;
}
.list-item45 {
  z-index: 5;
}
.list-item-tax-consultancy-link,
.recent-posts {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--font-size-lg);
  color: var(--color-lightsteelblue-100);
  font-family: var(--font-roboto);
}
.list-item-tax-consultancy-link {
  flex-direction: row;
  align-items: center;
  padding: 0 var(--padding-12xs) 0 0;
  gap: var(--gap-smi);
  font-size: var(--font-size-7xs);
  color: var(--color-sandybrown);
  font-family: var(--font-font-awesome-5-free);
}
.list-item-bankruptcy-link {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-5xl) 0 0;
  gap: var(--gap-xl);
}
.left-column {
  flex: 0 0 70%; 
}

.right-column {
  flex: 0 0 30%; 
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
}

.heading-47 {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.4px;
  line-height: 30px;
  text-transform: capitalize;
  font-weight: 700;
  font-family: inherit;
}
.symbol61,
.symbol62,
.symbol63 {
  position: relative;
  line-height: 18px;
}
.frame-child,
.symbol-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-lgi);
}
.symbol-list {
  gap: var(--gap-11xl);
}
.little-william-st2,
.vic-3000-australia2 {
  margin: 0;
}
.list-item-container3,
.list-item46,
.list-item47 {
  position: relative;
  letter-spacing: -0.2px;
  line-height: 22px;
}
.list-item47 {
  white-space: nowrap;
}
.head-office-location1,
.infoprivsacom-link1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  font-size: var(--font-size-base);
  color: var(--color-white);
  font-family: var(--font-roboto);
}
.head-office-location1 {
  flex-direction: row;
  align-items: flex-end;
  padding: 0 var(--padding-12xs) var(--padding-9xs) 0;
  gap: var(--gap-mid);
  font-size: var(--font-size-lg);
  color: var(--color-sandybrown);
  font-family: var(--font-font-awesome-5-free);
}
.heading-48 {
  position: relative;
  font-size: var(--font-size-2xl-8);
  letter-spacing: -0.4px;
  line-height: 30px;
  text-transform: capitalize;
}
.email3 {
  position: relative;
  font-weight: 600;
}
.input2 {
  position: absolute;
  top: -0.2px;
  left: 0;
  background-color: var(--color-gray-100);
  border: 1px solid var(--color-whitesmoke-400);
  box-sizing: border-box;
  width: 226px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-base) var(--padding-base) var(--padding-mini);
  white-space: nowrap;
}
.symbol64 {
  position: relative;
  line-height: 15px;
}
.button4 {
  position: absolute;
  top: 0.2px;
  left: 190px;
  background-color: var(--color-sandybrown);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-mini) var(--padding-lgi) var(--padding-mid)
    var(--padding-xl);
  z-index: 1;
  text-align: center;
  font-size: var(--font-size-mini);
  color: var(--color-white);
  font-family: var(--font-font-awesome-5-free);
}
.input-email-button-symbol {
  align-self: stretch;
  height: 47px;
  position: relative;
  font-size: var(--font-size-sm);
  color: var(--color-lightsteelblue-300);
  font-family: var(--font-roboto);
}
.section7,
.useful-links {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.useful-links {
  width: 239px;
  flex-direction: column;
  gap: var(--gap-2xl);
  font-size: var(--font-size-3xl);
}
.section7 {
  overflow: hidden;
  flex-direction: row;
  padding: var(--padding-56xl) var(--padding-163xl) var(--padding-83xl)
    var(--padding-146xl);
  gap: var(--gap-57xl);
  background-image: url(/public/section18@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.link-footer-logopng1 {
  height: 52px;
  width: 170px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.list-item48,
.symbol65 {
  position: relative;
  letter-spacing: -0.2px;
  line-height: 6px;
}
.list-item48 {
  font-size: var(--font-size-base);
  line-height: 19px;
  font-family: var(--font-roboto);
  color: var(--color-white);
  text-align: center;
}
.privacy-policy-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-12xs) 0 0;
  gap: var(--gap-smi);
}
.list-item49,
.symbol66 {
  position: relative;
  letter-spacing: -0.2px;
  line-height: 6px;
}
.list-item49 {
  font-size: var(--font-size-base);
  line-height: 19px;
  font-family: var(--font-roboto);
  color: var(--color-white);
  text-align: center;
}
.privacy-policy-group1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-12xs) 0 0;
  gap: var(--gap-smi);
}
.list-item50,
.symbol67 {
  position: relative;
  letter-spacing: -0.2px;
  line-height: 6px;
}
.list-item50 {
  font-size: var(--font-size-base);
  line-height: 19px;
  font-family: var(--font-roboto);
  color: var(--color-white);
  text-align: center;
}
.footer-logo-frame,
.privacy-policy-group2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-smi);
}
.footer-logo-frame {
  flex: 1;
  align-items: flex-start;
  padding: 0 var(--padding-12xs) 0 0;
  box-sizing: border-box;
  gap: var(--gap-15xl);
  max-width: 100%;
}
.privsa1 {
  color: var(--color-sandybrown);
}
.privsa-all-rights-container1 {
  position: relative;
  font-size: var(--font-size-base);
  letter-spacing: -0.2px;
  line-height: 19px;
  font-family: var(--font-rubik);
  text-align: right;
  flex-shrink: 0;
  color: var(--color-white);
}
.all-rights-reserved,
.section8 {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  max-width: 100%;
}
.all-rights-reserved {
  width: 802px;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-12xs) 0 0;
  gap: var(--gap-87xl);
  min-height: 20px;
}
.section8 {
  align-self: stretch;
  background-color: var(--color-darkslateblue-400);
  overflow: hidden;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-xl) var(--padding-148xl) var(--padding-xl)
    var(--padding-141xl);
  gap: var(--gap-xl);
  z-index: 6;
  margin-top: -1px;
  font-size: var(--font-size-7xs);
  color: var(--color-sandybrown);
  font-family: var(--font-font-awesome-5-free);
}
.blogs,
.useful-links-heading {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.useful-links-heading {
  align-self: stretch;
  align-items: flex-start;
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-2xl-8);
  color: var(--color-white);
  font-family: var(--font-libre-baskerville);
}
.blogs {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  align-items: center;
  /* gap: var(--gap-81xl); */
  letter-spacing: normal;
}
@media screen and (max-width: 1350px) {
  .section7,
  .section8 {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1125px) {
  .practice-area-capital-markets {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 800px) {
  .section7 {
    gap: var(--gap-57xl);
    padding: var(--padding-30xl) var(--padding-72xl) var(--padding-47xl)
      var(--padding-63xl);
    box-sizing: border-box;
  }
  .footer-logo-frame {
    flex-wrap: wrap;
  }
  .all-rights-reserved {
    flex-wrap: wrap;
    gap: var(--gap-87xl);
  }
  .section8 {
    padding-left: var(--padding-61xl);
    padding-right: var(--padding-64xl);
    box-sizing: border-box;
  }
  .blogs {
    /* gap: var(--gap-81xl); */
  }
}
@media screen and (max-width: 450px) {
  .heading-24 {
    font-size: var(--font-size-5xl);
    line-height: 29px;
  }
  .practice-area-frame-nesting {
    gap: var(--gap-29xl);
  }
  .heading-46,
  .practice-area3 {
    font-size: var(--font-size-mid);
    line-height: 24px;
  }
  .heading-47,
  .heading-48 {
    font-size: var(--font-size-lg);
    line-height: 24px;
  }
  .heading-48 {
    font-size: var(--font-size-mid);
  }
  .section7 {
    gap: var(--gap-57xl);
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
  .footer-logo-frame {
    gap: var(--gap-15xl);
  }
  .all-rights-reserved {
    gap: var(--gap-87xl);
  }
  .section8 {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
  /* .blogs {
    gap: var(--gap-81xl);
  } */
}
