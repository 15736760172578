.practice-area-capital-markets{
  width: 100%;
}
.article-link-blog-image3-7 {
  align-self: stretch;
  height: 452.6px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}

.symbol-twitter-item-link {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-21xl);
  box-sizing: border-box;
  max-width: 100%;
}
.article-link-blog-image2-7 {
  align-self: stretch;
  height: 452.6px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.link-privsa1,
.symbol201 {
  position: relative;
  line-height: 16px;
}
.link-privsa1 {
  font-size: var(--font-size-sm);
  line-height: 17px;
  font-family: var(--font-rubik);
}
.symbol-parent25 {
  height: 18px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-4xs);
}
.symbol202 {
  position: relative;
  line-height: 16px;
}
.september-15-20203 {
  position: relative;
  font-size: var(--font-size-sm);
  line-height: 17px;
  font-family: var(--font-rubik);
}
.symbol-parent26 {
  height: 18px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-8xs);
}
.symbol203 {
  position: relative;
  line-height: 16px;
}
.link-litigation3 {
  position: relative;
  font-size: var(--font-size-sm);
  line-height: 17px;
  font-family: var(--font-rubik);
}
.symbol-parent27 {
  height: 18px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-7xs) 0 0;
  box-sizing: border-box;
  gap: var(--gap-4xs);
}
.link-01,
.symbol204 {
  position: relative;
  line-height: 16px;
}
.link-01 {
  font-size: var(--font-size-sm);
  line-height: 17px;
  font-family: var(--font-rubik);
}
.frame-parent17,
.symbol-parent28 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.symbol-parent28 {
  height: 18px;
  gap: var(--gap-7xs);
}
.frame-parent17 {
  padding: 0 var(--padding-12xs) 0 0;
  box-sizing: border-box;
  gap: var(--gap-8xl);
  max-width: 100%;
}
.depositions-reveal-no,
.military-of-earplug {
  margin: 0;
}
.heading-2-container5 {
  margin: 0;
  position: relative;
  font-size: var(--font-size-11xl);
  letter-spacing: -0.4px;
  line-height: 39px;
  font-weight: 700;
  font-family: var(--font-libre-baskerville);
  color: var(--color-darkslateblue-500);
  display: inline-block;
  max-width: 100%;
}
.collection-lopez-has1,
.plus-acre-estate1,
.stop-actress-and1,
.struggling-to-sell1 {
  margin: 0;
}
.struggling-to-sell-container1 {
  height: 106px;
  position: relative;
  font-size: var(--font-size-lg);
  line-height: 28px;
  font-family: var(--font-rubik);
  color: var(--color-dimgray-300);
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.frame-parent16 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-4xl);
  max-width: 100%;
}
.read-more1,
.symbol205 {
  position: relative;
  line-height: 22px;
}

.symbol205 {
  line-height: 18px;
  font-family: var(--font-font-awesome-5-free);
}
.link11 {
  border-radius: var(--br-8xs-5);
  background-color: var(--color-sandybrown);
  border: 1px solid var(--color-sandybrown);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-base) var(--padding-7xl);
  gap: var(--gap-8xs);
  text-align: center;
  font-size: var(--font-size-lg);
  color: var(--color-white);
  font-family: var(--font-rubik);
}
.article1,
.symbol-twitter-item-link1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}
.article1 {
  flex: 1;
  border: 1px solid var(--color-whitesmoke-300);
  flex-direction: column;
  padding: var(--padding-28xl) var(--padding-28xl) var(--padding-22xl)
    var(--padding-22xl);
  gap: var(--gap-16xl);
}
.symbol-twitter-item-link1 {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 var(--padding-21xl);
  font-size: var(--font-size-base);
  font-family: var(--font-font-awesome-5-free);
}
.article-link-blog-image1-7 {
  align-self: stretch;
  height: 452.6px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.symbol-facebook-item-link1 {
   flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
    text-align: left;
    font-size: var(--font-size-lg);
    color: var(--color-dimgray-100);
    font-family: var(--font-rubik);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.35);
    margin-bottom: 10px;
}
@media screen and (max-width: 800px) {
  .frame-parent17 {
    flex-wrap: wrap;
  }
  .heading-2-container5 {
    font-size: var(--font-size-5xl);
    line-height: 31px;
  }
  .article1 {
    gap: var(--gap-16xl);
    padding-right: var(--padding-4xl);
    box-sizing: border-box;
  }
  .symbol-facebook-item-link1 {
    min-width: 100%;
    font-size: 16px;
  }
  .article-link-blog-image3-7{
    height: 300px;
  }
}
@media (min-width:370px) and (max-width: 450px) {
  
  .blog-card{
    width: 100% !important;
    margin-left: -30px !important;
    margin-right: -35px;
  }
  .blog-card img {
    margin-bottom: 20px;
  }
  .article-link-blog-image3-7 {
    margin-left: 30px;
    width: 100%;
    height: 300px !important;
  }

  .article {
    padding-left: 10px;
  }

  button {
    width: 150px !important;
  }
  
  
}
@media screen and (max-width: 450px) {
  .heading-2-container5 {
    font-size: var(--font-size-lg);
    line-height: 23px;
  }
  .article1 {
    padding-top: var(--padding-12xl);
    padding-bottom: var(--padding-8xl);
    box-sizing: border-box;
  }
  .article-link-blog-image3-7{
    width: 100% !important;
  }
  .link-privsa,
  .link-litigation2 {
    display: flex;
    flex-direction: column;
  }
  .link-privsa img,
  .link-litigation2 img {
    width: 20px;
  }
}
