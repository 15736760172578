.team-6png-icon2 {
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.heading-336,
.litigation-associate3 {
  position: relative;
  letter-spacing: -0.2px;
}
.mail{
  height: 20px;
  padding-right: 5px;
}
.heading-336 {
  margin: 0;
  font-size: inherit;
  line-height: 28px;
  font-weight: 700;
  font-family: inherit;
}
.litigation-associate3 {
  font-size: var(--font-size-sm);
  line-height: 26px;
  font-family: var(--font-rubik);
  color: var(--color-darkslategray);
}
.divelementor-widget-wrap8,
.infoprivsacom-link4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.description {
  font-size: 16px !important;
}
.divelementor-widget-wrap8 {
  width: 373px;
  padding: var(--padding-3xs) var(--padding-4xs) var(--padding-4xs);
  box-sizing: border-box;
  gap: var(--gap-2xl);
  max-width: 100%;
  text-align: center;
  font-size: var(--font-size-4xl);
  color: var(--color-darkslateblue-400);
  font-family: var(--font-libre-baskerville);
  border: 1px solid ;
  padding-top: 20px;
  padding-bottom: 20px;
  margin:5px;
  border-radius: 5px;
  transition: background-color 200ms linear;
}
.divelementor-widget-wrap8:hover{
  color: rgb(255, 255, 255);
  background-color:var(--color-darkslateblue-400);
}
.divelementor-widget-wrap8:hover .litigation-associate3{
  color: rgb(245, 185, 34);
}

@media screen and (max-width: 450px) {
  .heading-336 {
    font-size: var(--font-size-lg);
    line-height: 22px;
  }
}
