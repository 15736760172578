.before8 {
  align-self: stretch;
  height: 6px;
  position: relative;
  background-color: var(--color-darkslateblue-600);
}
.heading-329,
.symbol192 {
  margin: 0;
  position: relative;
  font-size: inherit;
  font-family: inherit;
}
.symbol192 {
  height: 50px;
  width: 50.2px;
  line-height: 50px;
  font-weight: 400;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.heading-329 {
  letter-spacing: -0.1px;
  line-height: 32px;
  font-weight: 700;
  margin-left: 34px;
  margin-bottom: 20px !important;
}
.technology-is-only1,
.the-demand-and8 {
  margin-top: 80px;
}
.the-demand-and-container8 {
  height: 45px;
  position: relative;
  font-size: var(--font-size-base);
  line-height: 26px;
  font-family: var(--font-rubik);
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-left: 34px;
}
.heading-3-link-capital-mar-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-smi);
}
.link-icon10 {
  width: 34px;
  height: 34px;
  position: absolute;
  margin: 0 !important;
  bottom: 0;
  left: 4.8px;
  overflow: hidden;
  flex-shrink: 0;
}
.frame-parent9 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  min-width: 209px;
  min-height: 153px;
  max-width: 100%;
  font-size: var(--font-size-7xl);
  color: var(--color-darkslategray);
  font-family: var(--font-libre-baskerville);
}
.divservice-content-inner1,
.divservice-content4,
.symbol-parent24 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.symbol-parent24 {
  flex: 1;
  flex-direction: row;
  gap: var(--gap-21xl);
}
.divservice-content-inner1,
.divservice-content4 {
  box-sizing: border-box;
}
.divservice-content-inner1 {
  width: 492px;
  flex-direction: row;
  /* padding: 0 var(--padding-21xl); */
  padding-right: 40px;
}
.divservice-content4 {
  flex: 1;
  background-color: var(--color-white);
  box-shadow: 0 10px 20px rgba(4, 54, 125, 0.05);
  flex-direction: column;
  padding: 0 0 var(--padding-17xl);
  gap: var(--gap-20xl);
}
.main-section-section {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-image: url(/public/main--section--section@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  min-width: 348px;
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-31xl);
  color: var(--color-sandybrown);
  font-family: var(--font-inter);
}
@media screen and (max-width: 869px) {
  .heading-329{
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 800px) {
  .symbol192 {
    font-size: var(--font-size-21xl);
    line-height: 40px;
  }
  .divservice-content4 {
    gap: var(--gap-20xl);
  }
}
@media screen and (max-width: 450px) {
  .symbol192 {
    font-size: var(--font-size-11xl);
    line-height: 30px;
  }
  .heading-329 {
    font-size: var(--font-size-2xl);
    line-height: 26px;
  }
  .symbol-parent24 {
    flex-wrap: wrap;
    gap: var(--gap-21xl);
  }
  .main-section-section {
    min-width: 100%;
  }
}
