.main-section4,
.main-section5 {
  position: relative;
  letter-spacing: -0.4px;
  line-height: 22px;
  font-weight: 500;
}

.main-section4{
  color: #e86500;
}
.main-section5 {
  margin: 0;
  font-size: var(--font-size-26xl);
  letter-spacing: -0.2px;
  line-height: 56px;
  font-weight: 700;
  font-family: var(--font-libre-baskerville);
  color: var(--color-darkslateblue-500);
}
.useful-links-container {
  flex-direction: column;
  gap: var(--gap-xl);
}
.terms-of-conditions-link1,
.useful-links-container,
.useful-links-container-parent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.terms-of-conditions-link1 {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  /* min-height: 933px; */
  text-align: center;
  font-size: var(--font-size-4xl);
  color: var(--color-darkslateblue-400);
  font-family: var(--font-libre-baskerville);
}
.useful-links-container-parent {
  width: 100%;
  flex-direction: column;
  padding: 0 var(--padding-xl) var(--padding-71xl);
  box-sizing: border-box;
  gap: var(--gap-21xl);
  flex-shrink: 0;
  text-align: left;
  font-size: var(--font-size-lg);
  color: var(--color-sandybrown);
  font-family: var(--font-rubik);
}
@media screen and (max-width: 1350px) {
  .useful-links-container-parent {
    padding-bottom: var(--padding-39xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 800px) {
  .main-section5 {
    font-size: var(--font-size-17xl);
    line-height: 45px;
  }
  .useful-links-container-parent {
    gap: var(--gap-21xl);
    padding-bottom: var(--padding-19xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .main-section5 {
    font-size: var(--font-size-8xl);
    line-height: 34px;
  }
}
