.main-section {
  position: relative;
  letter-spacing: -0.4px;
  line-height: 22px;
  font-weight: 500;
  color: #e86500;
}
.add-margin{
  margin-top: 15px;
  margin-bottom: 25px;
  letter-spacing: 1px;
  line-height: 25px ;
}
.vision-add-margin {
  margin-top: 15px;
  margin-bottom: 25px;
  letter-spacing: 1px;
  line-height: 25px ;
}
.mission-content{
  letter-spacing: 1px;
}
.mission-add-margin {
  margin-top: 15px;
  margin-bottom: 25px;
  letter-spacing: px;
  line-height: 25px ;
}
.at-privsa-lawyers,
.no-one-should {
  margin: 0;
}
.main-section-container {
  margin: 0;
  position: relative;
  font-size: var(--font-size-26xl);
  letter-spacing: -0.2px;
  line-height: 58px;
  font-weight: 700;
  font-family: var(--font-libre-baskerville);
  color: var(--color-darkslateblue-500);
}
.legal-info-link,
.privsa-law-offices {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  max-width: 100%;
}
.legal-info-link {
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.privsa-law-offices {
  margin: 0;
  margin-bottom: 20px;
  height: 29px;
  position: relative;
  font-size: var(--font-size-5xl);
  letter-spacing: -0.4px;
  line-height: 35px;
  font-weight: 700;
  font-family: var(--font-libre-baskerville);
  color: var(--color-darkslateblue-500);
}
.hile-its-true,
.of-combined-legal {
  margin-top: 0;
}
.hile-its-true{
  margin-top:110px;
}
.hile-its-true-container {
  height: 48px;
  position: relative;
  line-height: 26px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  max-width: 100%;
}
.history-as-a {
  position: relative;
  line-height: 22px;
}
.dropdown-arrow,
.subtext-content {
  display: flex;
  justify-content: flex-start;
}
.dropdown-arrow {
  flex-direction: row;
  align-items: flex-start;
  padding: 0 var(--padding-sm) 0 0;
}
.subtext-content {
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-9xs);
  max-width: 100%;
}
.could-happen-to,
.for-most-of,
.than-an-attorney,
.them-with-respect {
  margin: 0;
}

.for-most-of-container1{
  height: 100px;
  letter-spacing: -0.36px;
  line-height: 26px;
  
}
.for-most-of-container2{
  height: 100px;
  letter-spacing: -0.36px;
  line-height: 26px;
}

.for-most-of-container {
  height: 100px;
  letter-spacing: -0.36px;
  line-height: 26px;
}

.logo-symbol {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-lgi);
  max-width: 100%;
}
.talk-to-an {
  letter-spacing: -0.4px;
  line-height: 14px;
}
.banner,
.symbol,
.talk-to-an {
  position: relative;
}
.symbol {
  margin: 0;
  font-size: inherit;
  line-height: 24px;
  font-weight: 400;
  font-family: inherit;
}
.banner {
  font-size: var(--font-size-13xl);
  letter-spacing: -0.2px;
  line-height: 38px;
  font-weight: 500;
  font-family: var(--font-rubik);
  color: var(--color-darkslateblue-400);
}
.main-content {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-2xl);
  font-size: var(--font-size-5xl);
  color: var(--color-sandybrown);
  font-family: var(--font-font-awesome-5-free);
}
.hamburger-icon,
.search-bar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.search-bar {
  align-self: stretch;
  gap: var(--gap-9xs);
  margin-top:20px;
  width:350px;
}
.hamburger-icon {
  width: 325px;
  padding: 0 0 var(--padding-8xs);
  box-sizing: border-box;
  max-width: 100%;
}
.divelementor-widget-wrap-icon {
  height: 80px;
  width: 113.6px;
  position: relative;
  visibility: hidden;
}
.main-nav {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: var(--gap-xl);
  max-width: 100%;
}
.header-wrapper,
.logo-container,
.navigation-menu {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.logo-container {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 0 var(--padding-3xs);
  box-sizing: border-box;
  font-size: var(--font-size-sm);
}
.header-wrapper,
.navigation-menu {
  flex-direction: column;
}
.navigation-menu {
  align-self: stretch;
  gap: var(--gap-18xl);
}
.header-wrapper {
  flex: 1;
  padding: var(--padding-10xs) 0 0;
  box-sizing: border-box;
  min-width: 370px;
}
.feature-icon-3png {
  width: 55px;
  height: 53px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.heading-3 {
  position: relative;
  letter-spacing: -0.4px;
  line-height: 38.4px;
}
.qualified-lawyers {
  position: relative;
  font-size: var(--font-size-mini);
  letter-spacing: -0.2px;
  line-height: 18px;
  font-family: var(--font-roboto);
  color: var(--color-darkgray-300);
}
.divelementor-widget-wrap,
.features {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.features {
  align-self: stretch;
  gap: var(--gap-4xs);
}
.divelementor-widget-wrap {
  flex: 0.6924;
  border-right: 1px solid var(--color-slategray-300);
  border-bottom: 1px solid var(--color-slategray-300);
  box-sizing: border-box;
  padding: var(--padding-xl) 45px var(--padding-21xl);
  gap: var(--gap-5xl);
  min-width: 134px;
}
.feature-icon-4png {
  width: 52px;
  height: 52px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.heading-31,
.ranked-in-super {
  position: relative;
  letter-spacing: -0.4px;
  line-height: 38.4px;
}
.ranked-in-super {
  font-size: var(--font-size-mini);
  letter-spacing: -0.2px;
  line-height: 18px;
  font-family: var(--font-roboto);
  color: var(--color-darkgray-300);
}
.divelementor-widget-wrap1,
.heading-3-275-parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.heading-3-275-parent {
  align-self: stretch;
  padding: 0 var(--padding-mini);
  gap: var(--gap-4xs);
}
.divelementor-widget-wrap1 {
  flex: 1;
  border-bottom: 1px solid var(--color-slategray-300);
  box-sizing: border-box;
  padding: var(--padding-xl) var(--padding-xl) var(--padding-22xl);
  gap: var(--gap-5xl);
  min-width: 134px;
  z-index: 1;
}
.service-card {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 20px;
}
.feature-icon-2png {
  width: 46px;
  height: 52px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.heading-32 {
  position: relative;
  letter-spacing: -0.4px;
  line-height: 38.4px;
}
.cases-fully-dismiss {
  position: relative;
  font-size: var(--font-size-mini);
  letter-spacing: -0.2px;
  line-height: 18px;
  font-family: var(--font-roboto);
  color: var(--color-darkgray-300);
}
.divelementor-widget-wrap2,
.links-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-xl);
  gap: var(--gap-5xs);
}
.divelementor-widget-wrap2 {
  flex: 1;
  border-right: 1px solid var(--color-slategray-300);
  box-sizing: border-box;
  padding: var(--padding-31xl) var(--padding-3xs) var(--padding-3xs);
  gap: var(--gap-5xl);
  min-width: 134px;
}
.feature-icon-1png {
  width: 55.7px;
  height: 49.9px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.heading-33 {
  position: relative;
  letter-spacing: -0.4px;
  line-height: 38.4px;
}
.qualified-lawyers1 {
  position: relative;
  font-size: var(--font-size-mini);
  letter-spacing: -0.2px;
  line-height: 18px;
  font-family: var(--font-roboto);
  color: var(--color-darkgray-300);
}
.navigation-links {
  align-items: center;
  gap: var(--gap-5xs);
}
.navigation-links,
.search-box,
.sub-nav-links {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.search-box {
  align-items: center;
  gap: var(--gap-6xl);
}
.sub-nav-links {
  align-items: flex-start;
  padding: 0 0 var(--padding-xs);
  box-sizing: border-box;
  min-width: 115px;
}
.form-input {
  width: 367px;
  flex-direction: row;
  align-items: flex-end;
  gap: var(--gap-27xl);
}
.form-input,
.main-content-frame,
.privacy-policy-link,
.services {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.services {
  width: 412px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-12xs);
  min-width: 412px;
  text-align: center;
  font-size: var(--font-size-13xl);
  color: var(--color-gray-300);
  font-family: var(--font-libre-baskerville);
}
.main-content-frame,
.privacy-policy-link {
  flex-shrink: 0;
}
.main-content-frame {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  gap: var(--gap-101xl);
  text-align: left;
  color: var(--color-slategray-200);
}
.privacy-policy-link {
  width: 1140px;
  flex-direction: column;
  align-items: center;
  padding: 0 var(--padding-xl) var(--padding-91xl);
  box-sizing: border-box;
  gap: var(--gap-12xl);
  text-align: center;
  font-size: var(--font-size-lg);
  color: var(--color-sandybrown);
  font-family: var(--font-rubik);
}
.heading-2,
.heading-34 {
  position: relative;
  letter-spacing: -0.4px;
  line-height: 22px;
  font-weight: 500;
}
.heading-2 {
  margin: 0;
  font-size: var(--font-size-26xl);
  letter-spacing: -0.2px;
  line-height: 78px;
  font-weight: 700;
  font-family: var(--font-libre-baskerville);
  color: var(--color-darkslateblue-500);
}
.services-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-4xs);
  max-width: 100%;
}
.award-logo1png-icon {
  width: 90px;
  height: 90px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.heading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-mini) 0 0;
}
.award-logo2png-icon,
.award-logo3png-icon {
  position: relative;
  overflow: hidden;
  object-fit: cover;
}
.award-logo2png-icon {
  height: 90px;
  width: 175px;
  flex-shrink: 0;
  min-height: 90px;
}
.award-logo3png-icon {
  width: 118px;
  flex: 1;
  max-height: 100%;
}
.award-logo3png-wrapper {
  height: 91px;
  width: 161px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.award-logo4png-icon {
  height: 90px;
  width: 90px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  min-height: 90px;
}
.header-text {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.about-us,
.image-with-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
}
.image-with-text {
  width: 981px;
  padding: 0 var(--padding-xl) 101px;
  gap: var(--gap-21xl);
  max-width: 100%;
  flex-shrink: 0;
  text-align: center;
  font-size: var(--font-size-lg);
  color: var(--color-sandybrown);
  font-family: var(--font-rubik);
}
.about-us {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  padding: 0;
  letter-spacing: normal;
}
@media screen and (max-width: 1125px) {
  .services {
    flex: 1;
  }
  .main-content-frame {
    flex-wrap: wrap;
    gap: var(--gap-101xl);
  }
}

@media screen and (max-width:515px ) and (min-width:415px)  {
  .hamburger-icon{
    margin-top:125px !important;
  }
  .legal-info-link {
    margin-top: 75px !important;
  }
}
@media screen and (max-width:415px ) and (min-width:300px)  {
  .hamburger-icon{
    margin-top:150px;
  }
}

@media screen and (max-width:420px ) and (min-width:300px)  {
  .hile-its-true{
    margin-top: 170px;
  }
}
.hile-its-true{
  padding-top: 50px;
}
@media screen and (max-width:450px ) and (min-width:600px){
  .main-section-container{
    font-size: var(--font-size-8xl);
    line-height: 46px;
  }
}
@media screen and (max-width: 800px) {
  .main-section-container {
    font-size: var(--font-size-8xl);
    line-height: 46px;
  }
  .banner {
    font-size: var(--font-size-7xl);
    line-height: 30px;
  }
  .main-nav {
    flex-wrap: wrap;
  }
  .navigation-menu {
    gap: var(--gap-18xl);
  }
  .heading-3,
  .heading-31,
  .heading-32,
  .heading-33 {
    font-size: var(--font-size-7xl);
    line-height: 31px;
  }
  .services {
    min-width: 100%;
  }
  .main-content-frame {
    gap: var(--gap-101xl);
  }
  .privacy-policy-link {
    gap: var(--gap-12xl);
    padding-bottom: var(--padding-52xl);
    box-sizing: border-box;
  }
  .heading-2 {
    font-size: var(--font-size-17xl);
    line-height: 62px;
  }
  .header-text {
    flex-wrap: wrap;
    justify-content: center;
  }
  .image-with-text {
    gap: var(--gap-21xl);
  }
}

@media (max-width: 767px) {
  .vision-add-margin,
  .mission-add-margin {
    margin-top: 10px;
    margin-bottom: 25px;
  }
  .for-most-of-container1 {
    margin-top: 25px;
    text-align: start;
    margin-bottom: 20px;
  }
  .logo-symbol,
  .for-most-of-container {
    text-align: start;
    flex-direction: column;
    margin-bottom: 40px;
    letter-spacing: 0px;
    line-height: 26px;
  }

  .vision-add-margin {
    margin-top: 15px;
    margin-bottom: 25px;
  }
  .mission-add-margin {
    margin-top: 15px;
    margin-bottom: 25px;
    letter-spacing: 1px;
    line-height: 30px ;
  }
  

  .privsa-law-offices {
    text-align: start;
    justify-content: center;
    margin-bottom: 100px !important;
  }

  .hile-its-true-container,
  .dropdown-arrow {
    width: 100%; /* Make sure these elements take full width on small screens */
  }
}
@media screen and (max-width: 450px) {
  .main-section-container {
    font-size: var(--font-size-8xl);
    line-height: 35px;
  }
  .banner,
  .privsa-law-offices,
  .symbol {
    font-size: var(--font-size-lgi);
    line-height: 28px;
  }
  .banner,
  .symbol {
    line-height: 19px;
  }
  .banner {
    line-height: 23px;
  }
  .header-wrapper {
    min-width: 100%;
  }
  .heading-3 {
    font-size: var(--font-size-lgi);
    line-height: 23px;
  }
  .divelementor-widget-wrap {
    flex: 1;
  }
  .heading-31 {
    font-size: var(--font-size-lgi);
    line-height: 23px;
  }
  .service-card {
    flex-wrap: wrap;
  }
  .heading-32,
  .heading-33 {
    font-size: var(--font-size-lgi);
    line-height: 23px;
  }
  .sub-nav-links {
    flex: 1;
  }
  .form-input {
    flex-wrap: wrap;
    gap: var(--gap-27xl);
  }
  .heading-2 {
    font-size: var(--font-size-8xl);
    line-height: 47px;
  }
  .privsa-law-offices {
    text-align: start;
    justify-content: center;
    margin-bottom: 180px !important;
  }
  .add-margin {
    margin-top: 20px;
    margin-bottom: 25px;
    font-size: 17px;
    letter-spacing: 1px;
    line-height: 26px;
  }
  .margin-top{
    margin-top: 50px;
  }
  .vision-add-margin,
  .mission-add-margin {
    margin-top: 15px;
    margin-bottom: 25px;
    font-size: 17px;
    letter-spacing: 1px;
    line-height: 26px;
  }
  
  .privacy-policy-link {
    margin-top: -50px !important;
  }
}

@media screen and (max-width: 400px){
  .privsa-law-offices {
    text-align: start;
    justify-content: center;
    margin-bottom: 200px !important;
  }
}
