/* Edited */
.menu-icon {
  display: none;
}
.list-item127:hover,
.list-item128:hover,
.list-item130:hover,
.list-item129:hover,
.list-item131:hover,
.list-item132:hover {
  color: #e86500 !important;
}

svg.svg-inline--fa.fa-bars.menu-icon{
  color: var(--color-darkslateblue-400);
  width: 20px;
  scale: 2;
}
.help-handler.display-block a{
padding-bottom: 5px;
}
.help-handler a{
  font-family: 'Open Sans',sans-serif;
}
/* .help-handler a:hover{
  color: #eb934f !important;
} */

.phone{
  height: 14px 
}
.dropdown-content {
  position: absolute;
  top: calc(100% + 10px);
  left: 0; 
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 20px;
}
.list-item131:hover{
  cursor: pointer;
}
.list1{
  color:black;
  padding: 10px;
}
.list1:hover{
  background-color: rgb(245, 245, 245);
  color: var(--color-darkslateblue-400);
}
.list2:hover{
  background-color: rgb(245, 245, 245);
  color: var(--color-darkslateblue-400);
}
.list2{
  color:black;
  padding: 10px;
}

@media screen and (max-width: 830px) {
  .menu-icon {
    display: block;
  }
  .list-item127,
.list-item128,
.list-item130,
.list-item129,
.list-item131,
.list-item132 {
  padding-bottom: 5px;
}
  .contact-button1, .contact-button2{
    position: relative!important;
    margin-bottom: 20px;
  }

  .rectangle-group {
    display: none !;
    height: auto;
    width: auto;
    position: relative;
    z-index: 1;
  }

}

.flex-container {
  display: flex;
  justify-content: center;
}

.flex-item {
  flex: 1;
}

.flex-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 13px;
  width: 18%;
}

.bg-white {
  background-color: #ffffff;
}

.bg-blue {
  background-color: #007bff;
}

.bg-indigo {
  background-color: #6610f2;
}

.logo-image {
  height: 82px;
  width: 71%;
  object-fit: cover;
}

.info-section {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.info-icon {
  height: 17px;
  margin-right: 5px;
}

.info-text {
  font-size: 15px;
  color: #ffffff;
}

.menu-list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-list li {
  margin-right: 20px;
}

.menu-list li:last-child {
  margin-right: 0;
}

.menu-list a {
  text-decoration: none;
  font-size: 15px;
  color: var(--color-darkslateblue-400);
}

.service-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #ff6347;
  font-size: 15px;
  font-weight: bold;
  margin-top: 10px;
}


@media screen and (min-width: 940px) and (max-width: 1200px) {
  header.section-section3 {
    font-size: 13px;
  }
  .list-item123,
  .list-item125,
  .list-item126 {
    font-size: 12px !important;
  }
}
@media screen and (min-width: 680px) and (max-width: 1200px) {
  header{
    font-size: 13px;
  }
  .list-item123,
  .list-item125,
  .list-item126 {
    font-size: 12px !important;
  }
}
@media screen and (min-width: 480px) and (max-width: 940px) {
  header.section-section3 {
    font-size: 13px;
    height: 70px;
  }
  .rectangle-group {
    display: none;
  }
  .list-item123,
  .list-item125,
  .list-item126 {
    font-size: 12px !important;
  }
}
/* Edited */

.frame-inner {
  position: relative;
  top: 0;
  left: 0;
  background-color: var(--color-darkslateblue-400);
  width: 100%;
  height: 100%;
  display: none;
}
.logo-new-1{
  height: 175!important;
  display: none;
}
.logo-new-1-11 {
  animation: popUp 6s infinite; 
  position: absolute;
  height: auto;
  width: 195px;
    margin-left: 9px;
  bottom: 14px;
  overflow: hidden;
  /* object-fit: cover; */
  z-index:999;
  /* background-color: var(--color-darkslateblue-400); */
  /* box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2); */
  left: 20px;
}
@keyframes popUp {
  0% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1.2);
  }
}
.rectangle-group {
  height: 90px;
  width: 259px;
  position: relative;
  z-index: 1;
}
.list-item123,
.symbol157 {
  position: relative;
  line-height: 14px;
}
.list-item123 {
  font-size: var(--font-size-mini);
  letter-spacing: -0.3px;
  line-height: 18px;
  font-family: var(--font-roboto);
  color: var(--color-darkslateblue-400);

}
.star-performer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-sm) 0 0;
  gap: var(--gap-4xs);
  color: var(--color-sandybrown);
}
.list-item124 {
  width: 1px;
  height: 17px;
  position: relative;
  border-left: 1px solid var(--color-gray-400);
  box-sizing: border-box;
}
.vivid-visuals {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-base) 0 0;
}
.symbol158 {
  position: relative;
  margin-right: 5px;
  line-height: 14px;
  color: var(--color-sandybrown);
}
.position-fix {
  display: flex;
  align-items: start;
  margin-right: 20px;
}
.position-fix-2 {
  display: flex;
  align-items: center;
  margin-right: 3px;
      right: 20px;
    position: absolute;
}
.list-item125 {
  position: relative;
  letter-spacing: -0.3px;
  line-height: 18px;
  white-space: nowrap;
}
.link-ladder {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 5px;
  max-width: 100%;
  font-size: var(--font-size-mini);
  font-family: var(--font-roboto);
}
.symbol159 {
  margin-right: 5px;
  position: relative;
  line-height: 14px;
}
.data-protection-promise {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-7xs) 0 0;
  text-align: right;
}
.list-item126 {
  position: relative;
  margin-top: 3px;
  font-size: var(--font-size-mini);
  letter-spacing: -0.3px;
  line-height: 18px;
  font-family: var(--font-roboto);
  text-align: start;
}
.list-item123,.list-item125,.list-item126{
  color: var(--color-darkslateblue-400);
  font-weight: 500;
}
.section-section2 {
  align-self: stretch;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-10xl) var(--padding-3xs)
    var(--padding-11xl);
  box-sizing: border-box;
  gap: var(--gap-4xs);
  max-width: 100%;
}
.list-item-link6 {
  height: 11px;
  width: 11px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.list-item127 {
  position: relative;
  line-height: 24px;
  font-weight: 500;
}
.list-item128 {
  color: var(--color-sandybrown);
}
.list-item128,
.list-item129 {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  white-space: nowrap;
}
.list-item-link7 {
  height: 12px;
  width: 12px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.customer-connect {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.list-item130 {
  white-space: nowrap;
}
.list-item130,
.list-item131,
.list-item132 {
  position: relative;
  line-height: 24px;
  font-weight: 500;
}
.list-item132 {
  white-space: nowrap;
  z-index: 1;
}
.help-handler {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-21xl);
  width: 100%;
  max-width: 100%;
}
.contact-button1, 
  .contact-button2 {
  border: 1px solid var(--color-darkslateblue-400);
  height: 40px;
  width: 40px;
  /* position: relative; */
  /* position: absolute; */
    right: 10px;
    width: 140px;
    background-color: var(--color-darkslateblue-300) !important;
    color: white!important;
    border-radius: 2px !important;
    font-weight: 500;
    /* top: 55px; */
}
.contact-button1:hover{
  background-color: #ffffff !important;
  color:var(--color-darkslateblue-400)!important ;
  background-position: bottom;
}
.contact-button2:hover{
  background-color: #ffffff !important;
  color:var(--color-darkslateblue-400)!important ;
  background-position: bottom;
}
@media  (min-width:850px) and (max-width:1070px) {
  .section-section3{
    font-size: 12px;
    align-items: center;
    justify-content: space-between;
  }
  .help-handler{
    gap: 16px;
  }
  .contact-button2{
display: none;
  }
}
@media  (min-width:830px) and (max-width:850px) {
  .section-section3{
    font-size: 12px;
    align-items: center;
    justify-content: space-between;
  }
  .help-handler{
    gap: 16px;
  }
  .contact-button2{
    display: none;
  }
}
@media screen and (min-width:1300px){
  .contact-button2{
    display: none;
  }
  .section33{
    margin-top: 50px;
}
}
@media  (min-width:1150px) and (max-width:1300px) {
 
  .help-handler{
    gap: 20px;
  }
  .contact-button2{
    display: none;
  }
  .section33{
      margin-top: 50px;
  }
}
.help-handler *{
  color: var(--color-darkslateblue-400);
  font-weight: 600;
}
.contact-button1,.contact-button2{
  color: white !important;
  border: 1px solid var(--color-darkslateblue-400) !important;
  transition: all 0.5s ease-out;
  background:linear-gradient(to top, rgb(255, 255, 255) 50%,var(--color-darkslateblue-400) 50%);
  background-size: 100% 200%;
  background-position: top;
}

.section-section3 {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  height: 90px;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.5);
  align-self: stretch;
  background-color: #c2c2c2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-mini) var(--padding-lg) var(--padding-mini)
    var(--padding-10xl);
  box-sizing: border-box;
  gap: var(--gap-xl);
  max-width: 100%;
  z-index: 1;
  text-align: left;
  font-size: var(--font-size-mini);
  color: var(--color-white);
  font-family: var(--font-rubik);
}
.box-of-goodies,
.central-scene {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.central-scene {
  flex: 1;
  flex-direction: column;
  padding: 0 var(--padding-12xs) 0 0;
  box-sizing: border-box;
}
.box-of-goodies {
  margin-right: -2px;
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-12xs);
  top: 0;
  z-index: 99;
  position: fixed;
  width: 100%;
  text-align: left;
  font-size: var(--font-size-sm);
  color: var(--color-darkslateblue-400);
  font-family: var(--font-font-awesome-5-free);
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.5);
  background-color: white;
}
.custom-container {
  height: 0;
  position: fixed;
}

@media screen and (max-width: 830px) {
  .help-handler {
    display: none ;
    gap: var(--gap-21xl);
  }
  .contact-button1{
    display: none;
  }
  .display-block {
    display: block !important;
    margin-top: 13px;
    position: absolute;
    top:74px;
    background-color: white;
    width: 90%;
    padding:10px 0 50px 50px;
    border-radius: 5px;
  }

}
@media screen  and (min-width: 1070px) and (max-width: 1150px){
.contact-button2{
  display:none;
}
}
@media screen and (max-width: 1150px) {
  .box-of-goodies {
    top: 100px;
  }
  .section33{
    margin-top: 150px;
  }
  
  .rectangle-group{
    display: none;
  }
  .central-scene {
    position: relative !important;
  }
  .custom-container {
   
    top: 0;
    height: 100px;
    background-color: #021a3b;;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    width: 100%;
  }
 
  .logo-new-1 {
    display: block;
    position: sticky;
    height: 75px;
    top: 0;
    padding: 0 5px;
    width: auto;
    animation: popUp 6s infinite;
  }
  @keyframes popUp {
    0% {
      transform: scale(1.2);
    }
    50% {
      transform: scale(1.3);
    }
    100% {
      transform: scale(1.2);
    }
  }
}
@media screen and (min-width: 480px) and (max-width: 700px) {
  .help-handler {
    font-size: 13px;
    gap: 15px;
  }
}


.list-item127,
.list-item128,
.list-item130,
.list-item129,
.list-item131,
.list-item132 {
  color: var(--color-darkslateblue-400);
  text-decoration: none;
  font-weight: 600;
}

.rectangle-group {
  /* background-color: white; */
  background-color: #021a3b;
  border: 1px solid white;
}

.help-handler a {
  text-decoration: none;
}

@media screen and (max-width: 610px) {
  .display-block {
    /* top: 180px; */
    border: 1px solid navy;
    padding-top: 45px;
  }
  .position-fix-2{
    position: relative;
  }
  .logo-new-1-11 {
    display: none;
  }
  .section-section2 {
    
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px;
  }
  .star-performer {
    justify-content: start;
  }

  .star-performer,
  .vivid-visuals,
  .link-ladder,
  .data-protection-promise {
    width: 100%;
    text-align: center;
  }

  .list-item123,
  .list-item125,
  .list-item126 {
    text-align: start;
  }

  .list-item125{
    margin-left: 4px !important;
  }

  .list-item124 {
    display: none;
  }
  .position-fix {
    margin-left: 2px;
  }
  .position-fix-2{
    margin-left: 23px;
    margin-top: 9px;
  }
  .position-fixing {
    position: static;
  }
}
