.heading-11 {
  margin: 0;
  width: 328.8px;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.4px;
  line-height: 43.2px;
  font-weight: 700;
  font-family: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
}
.ordered-list3 {
  color: var(--color-white);
}
.ordered-list3,
.ordered-list4,
.ordered-list5,
.ordered-list6,
.ordered-list7 {
  position: relative;
  line-height: 28px;
}
.heading10,
.new-parent-name,
.section36 {
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
}
.heading10 {
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-11xs) 0 0;
  gap: var(--gap-4xs);
  font-size: var(--font-size-lg);
  color: var(--color-sandybrown);
  font-family: var(--font-rubik);
}
.new-parent-name,
.section36 {
  align-self: stretch;
  flex-direction: column;
  justify-content: flex-start;
}
.section36 {
  align-items: center;
  padding: var(--padding-184xl) var(--padding-2xl) var(--padding-185xl)
    var(--padding-xl);
  gap: var(--gap-4xs);
  background-image: url(/public/section13@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.new-parent-name {
  align-items: flex-start;
  padding: 0 var(--padding-12xs) 0 0;
  flex-shrink: 0;
  text-align: center;
  font-size: var(--font-size-17xl);
  color: var(--color-white);
  font-family: var(--font-libre-baskerville);
}
@media screen and (max-width: 800px) {
  .heading-11 {
    font-size: var(--font-size-10xl);
    line-height: 35px;
  }
  .section36 {
    padding-top: var(--padding-113xl);
    padding-bottom: var(--padding-114xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .heading-11 {
    font-size: var(--font-size-3xl);
    line-height: 26px;
  }
  .ordered-list4 {
    width: 100%;
    height: 13.4px;
  }
  .ordered-list6 {
    width: 100%;
    height: 9.1px;
  }
  .heading10 {
    flex-wrap: wrap;
  }
}
