.team-6png-icon {
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.heading-319,
.litigation-associate1 {
  position: relative;
  letter-spacing: -0.2px;
}
.heading-319 {
  margin: 0;
  font-size: inherit;
  line-height: 28px;
  font-weight: 700;
  font-family: inherit;
}
.litigation-associate1 {
  font-size: var(--font-size-sm);
  line-height: 26px;
  font-family: var(--font-rubik);
  color: var(--color-darkslategray);
}
.heading-3-link-kimball-jon-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-4xs);
}
.symbol124 {
  position: relative;
  letter-spacing: -0.2px;
  line-height: 13px;
}
.list-item99 {
  background-color: var(--color-darkgray-100);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-4xs) var(--padding-4xs) var(--padding-3xs)
    var(--padding-3xs);
}
.symbol125 {
  position: relative;
  letter-spacing: -0.2px;
  line-height: 13px;
}
.list-item100 {
  background-color: var(--color-darkgray-100);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-4xs) var(--padding-4xs) var(--padding-3xs)
    var(--padding-3xs);
}
.symbol126 {
  position: relative;
  letter-spacing: -0.2px;
  line-height: 13px;
}
.list-item101 {
  background-color: var(--color-darkgray-100);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-4xs) var(--padding-4xs) var(--padding-3xs)
    var(--padding-3xs);
}
.list-item-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-mini);
}
.list-item102,
.list-item103,
.list-item104 {
  flex: 1;
  position: relative;
  letter-spacing: -0.2px;
  line-height: 26px;
  display: flex;
  align-items: center;
}
.list-item104 {
  white-space: nowrap;
}
.list-item-melbourne-au-parent,
.symbol-list1 {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
}
.list-item-melbourne-au-parent {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
  gap: var(--gap-9xl);
}
.symbol-list1 {
  height: 116px;
  padding: 0 0 var(--padding-10xs);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--color-slategray-200);
  font-family: var(--font-rubik);
}
.list5,
.practice-area7,
.symbol-list1,
.text-block {
  display: flex;
  justify-content: flex-start;
}
.practice-area7 {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-3xs);
  text-align: center;
  font-size: var(--font-size-smi);
  color: var(--color-white);
  font-family: var(--font-font-awesome-5-free);
}
.list5,
.text-block {
  align-items: flex-start;
}
.list5 {
  width: 209px;
  flex-direction: column;
  gap: var(--gap-16xl);
}
.text-block {
  width: 273px;
  flex-direction: row;
}
.divelementor-widget-wrap6 {
  height: 681px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-3xs) var(--padding-31xl);
  box-sizing: border-box;
  gap: var(--gap-25xl);
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-4xl);
  color: var(--color-darkslateblue-400);
  font-family: var(--font-libre-baskerville);
}
@media screen and (max-width: 800px) {
  .divelementor-widget-wrap6 {
    padding-top: var(--padding-xl);
    padding-bottom: var(--padding-13xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .heading-319 {
    font-size: var(--font-size-lg);
    line-height: 22px;
  }
  .divelementor-widget-wrap6 {
    gap: var(--gap-25xl);
  }
}
