.user-1png-icon {
  width: auto;
  height: 55px;
  position: absolute;
  margin: 0 !important;
  right: 146.6px;
  bottom: -27.8px;
  border-radius: var(--br-16xl);
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.housewife,
.strong-lilly {
  position: absolute;
  margin: 0 !important;
  letter-spacing: -0.2px;
}
.strong-lilly {
  right: 123.7px;
  bottom: -69.8px;
  line-height: 22px;
  font-weight: 500;
  color: var(--color-darkslateblue-400);
}
.housewife {
  right: 147.7px;
  bottom: -93.8px;
  font-size: 16px !important;
  line-height: 17px;
}
.svg-icon {
  width: 48px;
  height: 34.3px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.combine-legal-expertise,
.former-top-eu,
.high-profile-eu,
.inter-on-the,
.leading-eu-politicians,
.levels-our-firm,
.we-represent-our {
  margin: 0;
}
.we-represent-our-container {
  flex: 1;
  position: relative;
  font-size: var(--font-size-xl);
  letter-spacing: -0.16px;
  line-height: 28px;
  display: flex;
  align-items: center;
}
.divelementskit-single-testimo {
  height: 388px;
  background-color: #fbfbfb;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-31xl) var(--padding-31xl) var(--padding-67xl)
    var(--padding-32xl);
  box-sizing: border-box;
  position: relative;
  gap: var(--gap-7xl);
  max-width: 100%;
  text-align: center;
  font-size: var(--font-size-lg);
  color: var(--color-slategray-200);
  font-family: var(--font-rubik);
}

@media screen and (max-width: 450px) {
  .we-represent-our-container {
    font-size: var(--font-size-base);
    line-height: 22px;
  }
  .divelementskit-single-testimo {
    padding: var(--padding-13xl) var(--padding-xl) var(--padding-37xl);
    box-sizing: border-box;
  }
}
