
.button-go-to-slide-1 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-6xs);
  border: 2px solid var(--color-darkkhaki);
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  z-index: 1;
}
.button-go-to-slide-2,
.button-go-to-slide-3 {
  position: absolute;
  top: 24px;
  left: 4px;
  border-radius: var(--br-10xs);
  background-color: var(--color-gray-500);
  width: 6px;
  height: 6px;
  z-index: 1;
}
.button-go-to-slide-3 {
  top: 40px;
}
.list {
  width: 14px;
  height: 46px;
  position: relative;
}
.symbol111 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-75xl) 0 0;
}
.divcol-md-4 {
  height: 1px;
  width: 380px;
  position: relative;
  display: none;
  max-width: 100%;
}
.before {
  width: 30px;
  height: 3px;
  position: relative;
  border-top: 3px solid var(--color-darkkhaki);
  box-sizing: border-box;
}
.dubais-top-rated {
  margin: 0;
  width: 600px;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.2px;
  line-height: 28px;
  text-transform: capitalize;
  font-weight: 500;
  font-family: inherit;
  display: flex;
  align-items: center;
}
.partner,
.reliable-legal {
  margin: 0;
}
h1.heading-1-container, .dubais-top-rated,.over-200-years{
  text-shadow: 2px 2px 4px rgb(0 0 0 / 27%);
}
.heading-1-container {
  margin: 0;
  align-self: stretch;
  /* height: 157px; */
  position: relative;
  font-size: var(--font-size-45xl);
  letter-spacing: -0.2px;
  line-height: 78px;
  font-weight: 700;
  font-family: var(--font-libre-baskerville);
  color: var(--color-white);
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.before-parent,
.button7 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-4xs);
}
.before-parent {
  gap: var(--gap-3xl);
}
.over-200-years {
  position: relative;
  font-size: var(--font-size-xl);
  letter-spacing: -0.2px;
  line-height: 24px;
  color: var(--color-white);
  text-align: center;
  display: inline-block;
  max-width: 100%;
  margin-left: -8em;
}
.frame-parent5 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-6xl);
  max-width: 100%;
}

.frame-parent5 {
  opacity: 0;
  transform: translateX(100%);
  animation: slideIn 1s forwards;
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(50%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}



.get-a-free,
.symbol112 {
  position: relative;
  letter-spacing: -0.2px;
  line-height: 16px;
  text-transform: uppercase;
}
.get-a-free {
  line-height: 19px;
  font-weight: 500;
  font-family: var(--font-rubik);
  
}
.link1,
.section14 {
  display: flex;
  justify-content: flex-start;
}
.link1 {
  background-color: var(--color-darkslateblue-400);
  flex-direction: row;
  align-items: flex-start;
  padding: var(--padding-lg) var(--padding-lgi) var(--padding-lgi)
    var(--padding-xl);
  gap: var(--gap-3xs);
  text-align: center;
  font-size: var(--font-size-base);
  color: var(--color-white);
  font-family: var(--font-font-awesome-5-free);
  margin-left: -35em;
  text-decoration: none;
  transition: all 0.5s ease-out;
  background:linear-gradient(to top, rgb(255, 255, 255) 50%,var(--color-darkslateblue-400) 50%);
  background-size: 100% 200%;
  background-position: top;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.7);
}
.link1:hover{
  background-position: bottom;
  color: var(--color-darkslateblue-400);

}
.link1:hover .get-a-free{
  color: var(--color-darkslateblue-400);
}
.link1:hover .symbol112{
  filter: brightness(0);
}
.section14 {
  display: none;
  width: 807px;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-26xl);
  max-width: calc(100% - 97px);
  flex-shrink: 0;
  margin-top: -100px;
}
.divbanner-item,
.section13 {
  display: flex;
  flex-direction: row;
  max-width: 100%;
}
.divbanner-item {
  height: 100vh;
  margin-top: 0px;
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-276xl) var(--padding-11xl) var(--padding-76xl);
  box-sizing: border-box;
  gap: var(--gap-64xl);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  /* animation: fadeOut 40s ease-in-out forwards infinite;  */
  background-image: url('/public/uae1.jpg');
}

@keyframes fadeOut {
  /* 0%, 100% {
    background-image: url('/public/bg01.jpg');
  } */
  /* 25%, 50% {
    background-image: url('/public/bg02.jpg');
  } 
  33%, 66% {
    background-image: url('/public/bg03.jpg');
  }
  66%, 100% {
    background-image: url('/public/bg01.jpg');
  } */
}

.divbanner-item1,.divbanner-item2,.divbanner-item3{
  height: 100vh;
  width: 100%;
  position: absolute;
  left: 0;
  top:0;

}
.slide-img{
  width:120%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  animation: zoom-out 3s linear infinite;
}
@keyframes zoom-out{
  100%{
    width:100%;
  }
}
.divbanner-item1{
  animation:slide1 12s linear infinite;
}
.divbanner-item2{
  animation:slide2 12s linear infinite;
}
.divbanner-item3{
  animation:slide3 12s linear infinite;
}
@keyframes slide1{
  0%{
    visibility:visible;
  }
  33%{
    visibility: hidden;
  }
  66%{
    visibility: hidden;
  }
  100%{
    visibility: hidden;
  }


}
@keyframes slide2{
  0%{
    visibility:hidden;
  }
  33%{
    visibility:hidden ;
  }
  66%{
    visibility: visible;
  }
  100%{
    visibility: hidden;
  }

}
@keyframes slide3{
  0%{
    visibility:hidden;
  }
  33%{
    visibility: hidden;
  }
  66%{
    visibility: hidden;
  }
  100%{
    visibility: visible;
  }

}


.section13 {
  align-self: stretch;
  background: linear-gradient(
    180deg,
    rgba(4, 54, 125, 0),
    rgba(4, 54, 125, 0.56)
  );
  overflow: hidden;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  text-align: left;
  font-size: var(--font-size-4xl);
  color: var(--color-sandybrown);
  font-family: var(--font-rubik);
}
@media screen and (max-width: 1125px) {
  .divbanner-item {
    gap: var(--gap-64xl);
  }
}
@media screen and (max-width: 860px) {
  .heading-1-container {
    font-size: var(--font-size-32xl);
    line-height: 62px;
  }
  .divbanner-item {
    gap: var(--gap-64xl);
    padding-top: var(--padding-173xl);
    padding-bottom: var(--padding-43xl);
    box-sizing: border-box;
  }
  .partner, .reliable-legal{
    margin-top: 130px;
    margin-left: 40px;
  }
  .over-200-years {
    margin-left: 20px;
  }
  .link1{
    margin-left: -48%;
  }
  
}
@media screen and (max-width: 700px) {
.link1{
  margin-left: -14px;
}

}
@media screen and (max-width: 480px) {
  .dubais-top-rated {
    font-size: var(--font-size-lg);
    line-height: 22px;
    margin-left: -30px;
  }
  .before {
    margin-left: -25px;
  }
  .heading-1-container {
    font-size: var(--font-size-19xl);
    line-height: 47px;
    margin-left: -30px;
    
  }
  .over-200-years {
    font-size: var(--font-size-base);
    line-height: 19px;
   padding-left: 28px;
  }
  .section14 {
    gap: var(--gap-26xl);
  }
  .link1 {
    margin-left: -14px !important;
  }
  .frame-parent5{
    padding-right: 50px !important;
  }
  .partner, .reliable-legal{
    margin-top: 130px;
    margin-left: 82px;
  }
  .section-section3{
    height: 70px;
  }
}
/* new css */
/* Section4.css */
.section13 {
  position: relative;
  overflow: hidden;
  height: 100vh; /* Adjust as necessary */
}

.divbanner-item {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 2s, visibility 2s;
}

.image-container.visible {
  opacity: 1;
  visibility: visible;
  animation: zoomOut 5s infinite;
}

.symbol111 {
  visibility: hidden; /* This keeps the dots hidden */
}

.section14 {
  position: absolute;
  z-index: 2;
  text-align: center;
  color: white;
}
@media screen and (min-width:1300px) {
  .section14 {
    margin-left: -400px;
}
}
.symbol112 img {
  width: 24px; /* Adjust size as necessary */
  height: 24px;
}

.get-a-free {
  color: white;
  text-transform: uppercase;
  padding-top: 3px;
}

.hidden {
  display: none;
}

@keyframes zoomOut {
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1) ;
  }
}
.partner{
  text-align: left;
}