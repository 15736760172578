.heading-27,
.heading-314 {
  position: relative;
  letter-spacing: -0.4px;
  line-height: 22px;
  font-weight: 500;
}
.heading-27 {
  margin: 0;
  font-size: var(--font-size-26xl);
  letter-spacing: -0.2px;
  line-height: 56px;
  font-weight: 700;
  font-family: var(--font-libre-baskerville);
  color: var(--color-white);
}
.list-wrapper,
.list3 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.list3 {
  flex-direction: column;
  gap: var(--gap-xl);
}
.list-wrapper {
  width: 1120px;
  flex-direction: row;
  flex-shrink: 0;
}
.consumer,
.protection {
  margin: 0;
}
.heading-3-container2 {
  line-break: anywhere;
}
.heading-3-container1 {
  margin: 0;
  height: 64px;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.4px;
  line-height: 32px;
  font-weight: 700;
  font-family: inherit;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.conspiracy-illegal-control,
.possession-of-marijuana {
  margin: 0;
}
.conspiracy-illegal-control-container {
  height: 45px;
  position: relative;
  font-size: var(--font-size-base);
  letter-spacing: -0.2px;
  line-height: 26px;
  font-family: var(--font-rubik);
  color: var(--color-slategray-200);
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.header-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-smi);
}
.input-email-button1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-17xl);
}
.before5 {
  width: 50px;
  height: 4px;
  position: absolute;
  margin: 0 !important;
  top: 184.9px;
  right: 151.6px;
  background-color: var(--color-sandybrown);
}
.heading-315 {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.4px;
  line-height: 28.8px;
  font-weight: 700;
  font-family: inherit;
}
.symbol120 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-23xl);
}
.dismissed-prior-to {
  position: relative;
  letter-spacing: -0.2px;
  line-height: 19px;
}
.item {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.symbol121 {
  position: relative;
  line-height: 14px;
  text-transform: uppercase;
}
.link2 {
  background-color: var(--color-sandybrown);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-mini) var(--padding-lgi) var(--padding-base)
    var(--padding-xl);
  font-size: var(--font-size-sm);
  color: var(--color-white);
  font-family: var(--font-font-awesome-5-free);
}
.item-parent {
  align-self: stretch;
  flex-direction: column;
  align-items: flex-end;
  gap: var(--gap-4xl);
  font-size: var(--font-size-base);
  color: var(--color-slategray-200);
  font-family: var(--font-rubik);
}
.divcases-wrap,
.item-parent,
.symbol-parent15,
.text {
  display: flex;
  justify-content: flex-start;
}
.symbol-parent15 {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-smi);
}
.divcases-wrap,
.text {
  box-sizing: border-box;
}
.text {
  width: 257px;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  font-size: var(--font-size-5xl);
}
.divcases-wrap {
  flex: 1;
  background-color: var(--color-white);
  flex-direction: column;
  align-items: flex-end;
  padding: var(--padding-16xl) 0 0;
  position: relative;
  gap: var(--gap-85xl);
  min-width: 265px;
  max-width: 100%;
}
.constitutional,
.litigation {
  margin: 0;
}
.heading-3-container3 {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.4px;
  line-height: 32px;
  font-weight: 700;
  font-family: inherit;
}
.domestic-violence-assault {
  height: 19px;
  position: relative;
  font-size: var(--font-size-base);
  letter-spacing: -0.2px;
  line-height: 26px;
  font-family: var(--font-rubik);
  color: var(--color-slategray-200);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.link3,
.link4 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.link4 {
  flex-direction: column;
  gap: var(--gap-smi);
}
.link3 {
  flex-direction: row;
  padding: 0 var(--padding-21xl) 0 0;
}
.before6 {
  width: 50px;
  height: 4px;
  position: absolute;
  margin: 0 !important;
  top: 183.9px;
  left: calc(50% - 24.65px);
  background-color: var(--color-sandybrown);
}
.heading-316 {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.4px;
  line-height: 28.8px;
  font-weight: 700;
  font-family: inherit;
}
.section-header-with-welcome-bo {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-43xl) 0 var(--padding-xl);
}
.domestic-violence-charges {
  position: relative;
  letter-spacing: -0.2px;
  line-height: 19px;
}
.section-header-with-welcome-bo1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-21xl) var(--padding-3xs) 0;
  font-size: var(--font-size-base);
  color: var(--color-slategray-200);
  font-family: var(--font-rubik);
}
.symbol122 {
  position: relative;
  line-height: 14px;
  text-transform: uppercase;
}
.link5 {
  background-color: var(--color-sandybrown);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-mini) var(--padding-lgi) var(--padding-base)
    var(--padding-xl);
  font-size: var(--font-size-sm);
  color: var(--color-white);
  font-family: var(--font-font-awesome-5-free);
}
.section-header-with-welcome-bo-parent {
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  gap: var(--gap-smi);
}
.divcases-wrap1,
.list4,
.section-header-with-welcome-bo-parent {
  display: flex;
  justify-content: flex-start;
}
.list4 {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  font-size: var(--font-size-5xl);
}
.divcases-wrap1 {
  flex: 0.884;
  background-color: var(--color-white);
  flex-direction: column;
  align-items: center;
  padding: var(--padding-16xl) 0 0 var(--padding-22xl);
  box-sizing: border-box;
  position: relative;
  gap: var(--gap-109xl);
  min-width: 265px;
  max-width: 100%;
}
.heading-317 {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.4px;
  line-height: 32px;
  font-weight: 700;
  font-family: inherit;
}
.conspiracy-illegal-control1,
.possession-of-marijuana1 {
  margin: 0;
}
.conspiracy-illegal-control-container1 {
  flex: 1;
  position: relative;
  font-size: var(--font-size-base);
  letter-spacing: -0.2px;
  line-height: 26px;
  font-family: var(--font-rubik);
  color: var(--color-slategray-200);
  display: flex;
  align-items: center;
}
.social-media-icons-set,
.social-media-icons-set1 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.social-media-icons-set1 {
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-smi);
}
.social-media-icons-set {
  height: 90px;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 var(--padding-17xl);
  box-sizing: border-box;
}
.before7 {
  width: 50px;
  height: 4px;
  position: absolute;
  margin: 0 !important;
  top: 168.9px;
  left: calc(50% - 25.35px);
  background-color: var(--color-sandybrown);
}
.heading-318 {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.4px;
  line-height: 28.8px;
  font-weight: 700;
  font-family: inherit;
}
.legal-headers-with-list-items1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-23xl);
}
.dismissed-prior-to1 {
  position: relative;
  letter-spacing: -0.2px;
  line-height: 19px;
}
.legal-headers-with-list-items3 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.symbol123 {
  position: relative;
  line-height: 14px;
  text-transform: uppercase;
}
.link6 {
  background-color: var(--color-sandybrown);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-mini) var(--padding-lgi) var(--padding-base)
    var(--padding-xl);
  font-size: var(--font-size-sm);
  color: var(--color-white);
  font-family: var(--font-font-awesome-5-free);
  padding-bottom: 18px;
  margin-top: 45px;
}
.legal-headers-with-list-items2 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-4xl);
  font-size: var(--font-size-base);
  color: var(--color-slategray-200);
  font-family: var(--font-rubik);
}
.legal-headers-with-list-items {
  width: 257px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-smi);
  font-size: var(--font-size-5xl);
}
.divcases-wrap2 {
  flex: 1;
  background-color: var(--color-white);
  flex-direction: column;
  align-items: flex-end;
  padding: var(--padding-16xl) 0 0;
  box-sizing: border-box;
  position: relative;
  gap: var(--gap-85xl);
  min-width: 265px;
  height: 15em;
  
}
.divcases-wrap-parent,
.divcases-wrap2,
.section-wrapper,
.section23 {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.divcases-wrap-parent {
  width: 1122px;
  flex-direction: row;
  align-items: flex-start;
  gap: var(--gap-12xl);
  text-align: center;
  font-size: var(--font-size-7xl);
  color: var(--color-darkslateblue-500);
  font-family: var(--font-libre-baskerville);
}
.section-wrapper,
.section23 {
  box-sizing: border-box;
}
.section23 {
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: var(--padding-81xl) var(--padding-xl);
  gap: var(--gap-21xl);
  background-image: url(/public/section7@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  z-index: 1;
}
.section-wrapper {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 0 var(--padding-76xl);
  flex-shrink: 0;
  text-align: left;
  font-size: var(--font-size-lg);
  color: var(--color-sandybrown);
  font-family: var(--font-rubik);
}
@media screen and (max-width: 1125px) {
  .divcases-wrap-parent {
    flex-wrap: wrap;
  }
  .section-wrapper {
    padding-bottom: var(--padding-43xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 800px) {
  .heading-27 {
    font-size: var(--font-size-17xl);
    line-height: 45px;
  }
  .divcases-wrap-parent {
    gap: var(--gap-12xl);
  }
  .section23 {
    gap: var(--gap-21xl);
    padding-top: var(--padding-46xl);
    padding-bottom: var(--padding-46xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .heading-27 {
    font-size: var(--font-size-8xl);
    line-height: 34px;
  }
  .heading-3-container1 {
    font-size: var(--font-size-2xl);
    line-height: 26px;
  }
  .heading-315 {
    font-size: var(--font-size-lgi);
    line-height: 23px;
  }
  .divcases-wrap {
    gap: var(--gap-85xl);
  }
  .heading-3-container3 {
    font-size: var(--font-size-2xl);
    line-height: 26px;
  }
  .heading-316 {
    font-size: var(--font-size-lgi);
    line-height: 23px;
  }
  .divcases-wrap1 {
    gap: var(--gap-109xl);
    flex: 1;
  }
  .heading-317 {
    font-size: var(--font-size-2xl);
    line-height: 26px;
  }
  .heading-318 {
    font-size: var(--font-size-lgi);
    line-height: 23px;
  }
  .divcases-wrap2 {
    gap: var(--gap-85xl);
  }
  .section-wrapper {
    padding-bottom: var(--padding-21xl);
    box-sizing: border-box;
  }
}
