

  .careers-page{
    background-color: var(--color-ghostwhite);
  }
  .tagline{
    position: relative;
    letter-spacing: -0.4px;
    line-height: 22px;
    font-weight: 500;
    color: var(--color-sandybrown);
    font-family: var(--font-rubik);
    
  }
  .description{
    font-family: var(--font-rubik);
    font-size: 26px;
    letter-spacing: -0.36px;
    line-height: 16px;
  }
  .section-description{
    margin: 0;
    position: relative;
    font-size: var(--font-size-26xl);
    letter-spacing: -0.2px;
    line-height: 58px;
    font-weight: 700;
    font-family: var(--font-libre-baskerville);
    color: var(--color-darkslateblue-500);
  }
  .careers-content {
    max-width: 800px;
    margin: auto;
    padding: 0 20px;
  }
  .section-title{
    flex-direction: column;
  }
  
  .section-title__title {
    color: #00274c; 
  }
  .section-description {
    font-size: 40px;
  }
  .section-description a{
    color: #0b4494;
  }
  .card{
    text-align: left;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); 
    padding: 0;
    border-radius: 4px;
    width: 100%
  }
  
  .section-description {
    color: #444; 
  }
  .line{
    align-self: stretch;
    height: 6px;
    position: relative;
    background-color: var(--color-darkslateblue-600);
  }
  
  .benefits-list {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  .benefits-list li {
    margin-bottom: 10px;
    color: #001368; /* Dark gray text color */
    font-size: var(--font-size-base);
    line-height: 26px;
    font-family: var(--font-rubik);
  }
  .section-descrip{
    letter-spacing: -0.1px;
    line-height: 32px;
    font-weight: 700;
    margin-left: 34px;
    font-size: 32px;
    color: #01476A;
    padding: 20px 0;
  }
  .section-descri{
    font-size: 16px;
    padding: 20px;
    border: 1px solid rgb(0, 0, 85);
    border-radius: 2px;
  }
  @media (min-width:451px) and (max-width: 770px) {
    .description {
      line-height: 30px;
    }
  }
  @media screen and (max-width: 450px) {
    .benefits-list {
      padding-bottom: 10px;
      padding-right: 30px;
    }
    .description {
      line-height: 30px;
    }
  }