.before9 {
  align-self: stretch;
  height: 6px;
  position: relative;
  background-color: var(--color-darkslateblue-600);
}
.heading-330 {
  position: relative;
  letter-spacing: -0.1px;
  line-height: 32px;
  margin-left: 25px;
}
.premier-trial-lawyers1,
.state-in-the {
  margin-left:25px;
}

.premier-trial-lawyers-container1 {
  flex: 1;
  position: relative;
  font-size: var(--font-size-base);
  line-height: 26px;
  font-family: var(--font-rubik);
  display: flex;
  align-items: center;
}
.heading-3-link-banking-f-parent {
  height: 90px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-smi);
}
.link-icon11 {
  width: 34px;
  height: 34px;
  position: absolute;
  margin: 0 !important;
  bottom: 0;
  left: 10px;
  overflow: hidden;
  flex-shrink: 0;
}
.frame-parent10 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}
.divservice-content-inner2 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-17xl) 0 0;
}
.divservice-content5,
.main-section-section1 {
  display: flex;
  align-items: center;
  max-width: 100%;
  height: 300px!important;
  
}
.divservice-content4{
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);

}

.divservice-content5 {
  align-self: stretch;
  flex: 1;
  background-color: var(--color-white);
  /* box-shadow: 0 10px 20px rgba(4, 54, 125, 0.05); */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 0 var(--padding-17xl);
  box-sizing: border-box;
  gap: var(--gap-22xl);
}
.main-section-section1 {
  flex-direction: row;
  justify-content: center;
  background-image: url(/public/main--section--section2@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--color-darkslategray);
  font-family: var(--font-libre-baskerville);
}
@media screen and (max-width: 450px) {
  .heading-330 {
    font-size: var(--font-size-base);
    line-height: 26px;
  }
  .divservice-content5 {
    gap: var(--gap-22xl);
  }
}
