.decoration {
  text-decoration: none;
  color: white;
}
.heading-1 {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.4px;
  line-height: 43.2px;
  font-weight: 700;
  font-family: inherit;
  display: none;
}

.ordered-list {
  color: var(--color-white);
}
.ordered-list,
.ordered-list1,
.ordered-list2 {
  position: relative;
  line-height: 28px;
}
.text-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
  font-size: var(--font-size-lg);
  color: var(--color-sandybrown);
  font-family: var(--font-rubik);
}
.text-content {
  display: none;
}
.list-container,
.section33 {
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}
.section33 {
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: var(--padding-184xl) var(--padding-xl) var(--padding-185xl)
    var(--padding-2xl);
  gap: var(--gap-4xs);
  background-image: url(/public/section12@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  box-shadow: 0 2px 50px rgba(0, 0, 0, 0.5);
}
.section33::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 100px;
  /* backdrop-filter: brightness(0.6);  */
  z-index: 1;
}

.section33 > * {
  position: relative;
  z-index: 2;
}

.list-container {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 0 var(--padding-81xl);
  text-align: center;
  font-size: var(--font-size-17xl);
  color: var(--color-white);
  font-family: var(--font-libre-baskerville);
}
@media screen and (max-width: 800px) {
  .heading-1 {
    font-size: var(--font-size-10xl);
    line-height: 35px;
  }
  .section33 {
    padding-top: var(--padding-113xl);
    padding-bottom: var(--padding-114xl);
    box-sizing: border-box;
  }
  .list-container {
    padding-bottom: var(--padding-46xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .heading-1 {
    font-size: var(--font-size-3xl);
    line-height: 26px;
  }
}
