.innerDivStyle {
  width: 20%;
  margin: 10px;
  
  box-sizing: border-box;
}
.innerDivStyle2 {
  width: 20%;
  margin: 10px;
  margin-right: 40px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}
.container {
  gap: 20px;
  padding-top: 50px;
  padding-left: 20px;
  padding-right: 30px;
  color: white;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  
}

.section {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  /* padding: var(--padding-56xl) 179px var(--padding-56xl) var(--padding-146xl); */
  /* padding-right: 20px; */
  box-sizing: border-box;
  gap: var(--gap-83xl);
  background-image: url("/public/section10@3x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: top;
  min-height: 479px;
  width: 100%;
  flex-shrink: 0;
  padding: 5%;
}

@media screen and (min-width: 300px) and (max-width: 550px) {
  .heading-4-useful-links-container {
    width: 100%;
  }
  .container {
    flex-direction: column;
    width: 100%;
    padding: 15px;
    margin-right: 20px
    /* margin-left: -40px; */
  }
  .container .innerDivStyle {
    width: 100% !important;
    margin-bottom: 10px;
    box-sizing: border-box;
  }
  .innerDivStyle2 {
    margin-left: 50%;
  }
  .section {
    height: auto;
  }
  .the-demand-and-container6{
    margin: 15px;
  }
  .contact-btn {
    width: auto !important;
    height: auto !important;
  }
  .heading-29 {
    text-align: center !important;
  }

  .heading-415 {
    text-align: center !important;
  }

  .add-margin {
    margin-bottom: 0px !important;
  }

  .the-demand-and6 {
    text-align: center !important;
  }

  .useful-links-frame1 {
    justify-content: center !important;
    margin-left: 60px !important;
  }

  .useful-links-frame2 {
    justify-content: center !important;
  }

  .margin-right {
    margin-right: 10px;
  }

  .frame-wrapper1{
    margin-left: 12px !important;
  }
  .branches-add{
    text-align: center;
  }

}

.item-link-facebook-group {
  flex-direction: row;
  gap: var(--gap-mini);
  margin-top: -50px;
  margin-bottom: 10px;
  visibility: hidden !important;
}
.frame-wrapper1,
.heading-parent,
.item-link-facebook-group {
  display: flex;
  align-items: center;
  justify-content: center;
}

.frame-wrapper1 {
  flex-direction: row;
  padding: 0 var(--padding-8xs);
  margin-left: -40px;
}

.item-link-facebook5,
.item-link-instagram5,
.item-link-linkedin5,
.item-link-twitter6 {
  height: 35px;
  width: 35px;
  position: relative;
  border-radius: var(--br-mid-5);
  min-height: 35px;
}

.heading-parent {
  flex-direction: column;
  /* gap: var(--gap-29xl); */
  font-size: var(--font-size-10xl-9);
}

.heading8 {
  margin-bottom: 20px;
  font-size: 17px;
}

.practice-area8 {
  text-align: center;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 15px;
}
.heading-29 {
  margin-bottom: 10px;
  text-align: start;
  font-weight: bold;
  font-size: 30px;
  font-family: serif;
}

.the-demand-and6 {
  text-align: start;
  font-weight: 300;
  letter-spacing: 1px;
}

.the-demand-and-container6 {
  text-align: center;
}

.symbol-parent16 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.symbol-parent16 {
  flex: 1;
  flex-direction: row;
  gap: var(--gap-xs);
}

.list-item105,
.symbol137 {
  position: relative;
  line-height: 10.89px;
  margin-right: 10px;
  margin-top: 10px;
}
.list-item105 {
  flex: 1;
  line-height: 24.89px;
  display: flex;
  align-items: center;
}

.symbol-parent17 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.symbol-parent17 {
  flex: 1;
  flex-direction: row;
  gap: var(--gap-xs);
}

.list-item106,
.symbol138 {
  position: relative;
  line-height: 10.89px;
  margin-right: 10px;
  margin-top: 10px;
}

.list-item106 {
  flex: 1;
  line-height: 24.89px;
  display: flex;
  align-items: center;
}

.symbol-parent18 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.symbol-parent18 {
  flex: 1;
  flex-direction: row;
  gap: var(--gap-xs);
}

.list-item107,
.symbol139 {
  position: relative;
  line-height: 10.89px;
  margin-right: 10px;
  margin-top: 10px;
}

.list-item107 {
  flex: 1;
  line-height: 24.89px;
  display: flex;
  align-items: center;
}

.symbol-parent19 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.symbol-parent19 {
  flex: 1;
  flex-direction: row;
  gap: var(--gap-xs);
}

.list-item108,
.symbol140 {
  position: relative;
  line-height: 10.89px;
  margin-right: 10px;
  margin-top: 10px;
}

.list-item108 {
  flex: 1;
  line-height: 24.89px;
  display: flex;
  align-items: center;
}

.symbol-parent20 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.symbol-parent20 {
  flex: 1;
  flex-direction: row;
  gap: var(--gap-xs);
}

.list-item109,
.symbol141 {
  position: relative;
  line-height: 10.89px;
  margin-right: 10px;
  margin-top: 10px;
}

.list-item109 {
  flex: 1;
  line-height: 24.89px;
  display: flex;
  align-items: center;
}

.symbol-parent21 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.symbol-parent21 {
  flex: 1;
  flex-direction: row;
  gap: var(--gap-xs);
}

.list-item110,
.symbol142 {
  position: relative;
  line-height: 10.89px;
  margin-right: 10px;
  margin-top: 10px;
}

.list-item110 {
  flex: 1;
  line-height: 24.89px;
  display: flex;
  align-items: center;
}

.symbol-parent22 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.symbol-parent22 {
  flex: 1;
  flex-direction: row;
  gap: var(--gap-xs);
}

.list-item111,
.symbol143 {
  position: relative;
  line-height: 10.89px;
  margin-right: 10px;
  margin-top: 10px;
}

.list-item111 {
  flex: 1;
  line-height: 24.89px;
  display: flex;
  align-items: center;
}

.symbol-frame-list {
  margin-right: 10px !important;
}

.useful-links-frame1 {
  display: flex;
  justify-content: flex-start;
}

.frame-parent8 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.anchor-decor {
  text-decoration: none;
  color: white;
  text-align: start;
  font-weight: 300;
  letter-spacing: 1px;
}

.delete-font {
  font-size: 15px;
}

.add-margin {
  margin-bottom: 10px;
}

.heading-415 {
  margin-bottom: 10px;
  text-align: start;
  font-weight: bold;
  font-size: 30px;
  font-family: serif;
}

.heading-420 {
  margin-bottom: 10px;
  text-align: start;
  font-weight: bold;
  font-size: 10px;
  font-family: serif;
  
}


.heading-416 {
  margin-bottom: 20px;
  text-align: center;
  font-weight: bold;
}

.privacy-policy-list1 {
  display: flex;
  justify-content: center;
}

.symbol-symbol-frame {
  margin-right: 10px;
}

.input8 {
  flex: 1;
  background-color: var(--color-gray-100);
  border: 1px solid var(--color-whitesmoke-400);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-base) var(--padding-base) var(--padding-mini);
  white-space: nowrap;
}

.input-email-button-symbol-fram,
.subscribe-input-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.input-email-button-symbol-fram {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-12xs) 0 0;
  font-size: var(--font-size-sm);
  color: var(--color-lightsteelblue-300);
  font-family: var(--font-roboto);
}
.subscribe-input-button {
  width: 240px;
  flex-direction: column;
  gap: var(--gap-2xl);
  text-align: left;
  font-size: var(--font-size-3xl);
  color: var(--color-white);
  font-family: var(--font-libre-baskerville);
}

.button9 {
  background-color: var(--color-sandybrown);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-mini) var(--padding-lgi) var(--padding-mid)
    var(--padding-xl);
  z-index: 1;
  margin-left: -35px;
  text-align: center;
  font-size: var(--font-size-mini);
  color: var(--color-white);
  font-family: var(--font-font-awesome-5-free);
  height: 54px;
}

.useful-links-frame2 {
  display: flex;
  justify-content: start;
}

.font-color-fix {
  text-align: start;
  font-weight: 300 !important;
  letter-spacing: 1px;
}

.get-in-touch {
  margin-top: 20px;
}

.outer-button {
  background-color: rgb(224, 102, 46);
  padding: 10px;
}


.contact-btn {
  background-color: rgb(224, 102, 46);
  padding: -10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.7);
}